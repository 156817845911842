import React from 'react';
import { ProjectStatusEnum } from 'gql/graphql';
import StatusComponent, { StatusComponentProps } from 'components/status/StatusComponent';

interface ProjectStatusProps {
  status: ProjectStatusEnum;
  size?: 'large' | 'medium';
}

export const projectStatusMap: { [key in ProjectStatusEnum]: StatusComponentProps } = {
  ARCHIVED: {
    text: '아카이브',
    status: 'default',
  },
  HIDDEN: {
    text: '중단',
    status: 'warning',
  },
  OPEN: {
    text: '진행중',
    status: 'success',
  },
  BLOCKED: {
    text: '중단',
    status: 'warning',
  },
};

const ProjectStatus = ({ status, size }: ProjectStatusProps) => {
  return <StatusComponent size={size} {...projectStatusMap[status]} />;
};

export default React.memo(ProjectStatus);
