import React, { ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import SearchBarWithFilters from 'components/container/SearchBarWithFilters';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material';
import { CustomSelect } from 'components/select/CustomSelect';
import { ProposalsFilterEnum, proposalsFilterMap } from 'utils/commonMaps';

interface ProRequestSearchBarProps {
  searchQuery?: string;
  handleSearch?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleFilterChange?: (event: SelectChangeEvent<unknown>) => void;
}

const classes = {
  select: {
    background: 'transparent',
  },
};

const ProposalsSearchBar = ({ searchQuery, handleSearch, handleFilterChange }: ProRequestSearchBarProps) => {
  return (
    <Box sx={{ mt: 4 }}>
      <SearchBarWithFilters
        handleSearch={handleSearch}
        filters={
          <CustomSelect
            style={classes.select}
            displayEmpty
            color="secondary"
            onChange={handleFilterChange}
            renderValue={() => <b>상태</b>}
            IconComponent={KeyboardArrowDownIcon}
          >
            {Object.keys(ProposalsFilterEnum).map((item) => (
              <MenuItem value={ProposalsFilterEnum[item as keyof typeof ProposalsFilterEnum]} key={item}>
                {proposalsFilterMap[ProposalsFilterEnum[item as keyof typeof ProposalsFilterEnum]]}
              </MenuItem>
            ))}
          </CustomSelect>
        }
        searchQuery={searchQuery}
      />
    </Box>
  );
};

export default ProposalsSearchBar;
