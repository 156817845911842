import React, { ChangeEvent } from 'react';
import { SelectChangeEvent } from '@mui/material';
import Box from '@mui/material/Box';
import SearchBarWithFilters from 'components/container/SearchBarWithFilters';

interface FlaggedProjectsSearchBarProps {
  searchQuery?: string;
  handleSearch?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleFilterChange?: (event: SelectChangeEvent<unknown>) => void;
}

const FlaggedProjectsSearchBar = ({ searchQuery, handleSearch }: FlaggedProjectsSearchBarProps) => {
  return (
    <Box sx={{ mt: 4 }}>
      <SearchBarWithFilters searchQuery={searchQuery} handleSearch={handleSearch} />
    </Box>
  );
};

export default FlaggedProjectsSearchBar;
