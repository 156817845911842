import { graphql } from 'gql';

export const PROJECT_ADMIN_REPORTS_QUERY = graphql(/* GraphQL */ `
  query allProjectAdminReports(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: OrderBy
    $filter: AdminReportFilter
  ) {
    allAdminReports(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
      ... on ProjectAdminReport {
        id
        project {
          createdByUser {
            profile {
              address {
                jibunAddress
              }
            }
            name
          }
          reportTotalCount
          title
        }
        lastReportedDate
      }
    }
    total: _allAdminReportsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`);
