import React, { Dispatch, SetStateAction, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Zoom from '@mui/material/Zoom';
import { styled } from '@mui/material/styles';
import { CSSObject, Theme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { drawerIconPairs, mainDrawerEntries } from 'utils/miscellaneous';

interface MainDrawerProps {
  open: boolean;
  onClose: () => void;
  handleHover: (cond: boolean) => () => void;
  tabValue: string;
  handleTabChange: (route: string) => () => void;
  setTabValue: Dispatch<SetStateAction<string>>;
}

const drawerWidth = 240;
export const closedDrawerWidth = 65;

const classes = {
  itemText: {
    fontWeight: 700,
    fontSize: 12,
    color: '#7B8794',
    padding: (theme: Theme) => theme.spacing(0, 0.5),
  },
};

const openedMixin = (theme): CSSObject => ({
  ...(open && {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
});

const closedMixin = (theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: closedDrawerWidth,
  [theme.breakpoints.down('sm')]: {
    width: 0,
  },
});

const MyDrawer = styled(Drawer)(({ theme, open }) => ({
  width: drawerWidth,
  marginRight: 80,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiPaper-root': {
    width: drawerWidth,
    backgroundColor: theme.palette.background.paper,
    paddingTop: 88,
    overflowX: 'hidden',
    paddingBottom: 88,
    borderRight: '1px solid',
    borderRightColor: theme.palette.divider,
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
    },
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const MainDrawer = ({ open, handleHover, onClose, tabValue, setTabValue, handleTabChange }: MainDrawerProps) => {
  const location = useLocation();

  useEffect(() => {
    setTabValue(location.pathname);
  }, [location.pathname, setTabValue]);

  return (
    <MyDrawer
      onMouseLeave={handleHover(false)}
      onMouseOver={handleHover(true)}
      variant="permanent"
      open={open}
      onClose={onClose}
    >
      {mainDrawerEntries.map((item) => (
        <div key={item.parent}>
          <Zoom unmountOnExit in={open} timeout={200}>
            <ListItem>
              <ListItemText sx={classes.itemText} disableTypography primary={item.parent} />
            </ListItem>
          </Zoom>
          {item.children.map((children) => (
            <ListItem key={children.name} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                disabled={!!children?.disabled}
                selected={tabValue === children.route}
                onClick={handleTabChange(children.route)}
                sx={{
                  minHeight: 40,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  borderRadius: '0 8px 8px 0',
                  maxWidth: open ? 'calc(100% - 16px)' : '100%',
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {drawerIconPairs(tabValue)[children.route]}
                </ListItemIcon>
                <ListItemText disableTypography primary={children.name} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </div>
      ))}
    </MyDrawer>
  );
};

export default MainDrawer;
