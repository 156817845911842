import { graphql } from 'gql';
import { useMutation } from '@apollo/client';

const UPDATE_COMMENT_ADMIN_REPORT_MUTATION = graphql(/* GraphQL */ `
  mutation updateCommentAdminReport(
    $id: Int!
    $status: AdminReportStatusEnum!
    $reportReasons: [CommentReportReasonEnum!]
    $reportInput: [PositiveReportInput!]!
  ) {
    updateCommentAdminReport(id: $id, status: $status, reportReasons: $reportReasons, reportInput: $reportInput) {
      id
    }
  }
`);

const UPDATE_PROJECT_ADMIN_REPORT_MUTATION = graphql(/* GraphQL */ `
  mutation updateProjectAdminReport(
    $id: Int!
    $status: AdminReportStatusEnum!
    $projectReportReasons: [ProjectReportReasonEnum!]
    $reportInput: [PositiveReportInput!]!
  ) {
    updateProjectAdminReport(
      id: $id
      status: $status
      projectReportReasons: $projectReportReasons
      reportInput: $reportInput
    ) {
      id
    }
  }
`);

const UPDATE_COMPANY_STATUS_MUTATION = graphql(/* GraphQL */ `
  mutation updateCompanyStatus($companyId: Int!, $status: CompanyStatus!, $rejectReason: CompanyRejectReason) {
    updateCompanyStatus(companyId: $companyId, status: $status, rejectReason: $rejectReason) {
      id
    }
  }
`);

const UPDATE_ADMIN_REPORT_MUTATION = graphql(/* GraphQL */ `
  mutation updateRequestAdminReport(
    $id: Int!
    $status: AdminReportStatusEnum!
    $requestReportReasons: [RequestReportReasonEnum!]
    $reportInput: [PositiveReportInput!]!
  ) {
    updateRequestAdminReport(
      id: $id
      status: $status
      requestReportReasons: $requestReportReasons
      reportInput: $reportInput
    ) {
      id
    }
  }
`);

export const useUpdateDialogsMutations = () => {
  const [updateCommentAdminReport, { loading: updateCommentAdminReportLoading }] = useMutation(
    UPDATE_COMMENT_ADMIN_REPORT_MUTATION,
  );

  const [updateProjectAdminReport, { loading: updateProjectAdminReportLoading }] = useMutation(
    UPDATE_PROJECT_ADMIN_REPORT_MUTATION,
  );
  const [updateCompanyStatus, { loading: updateCompanyStatusLoading }] = useMutation(
    UPDATE_COMPANY_STATUS_MUTATION,
    {},
  );
  const [updateAdminReport, { loading: updateAdminReportLoading }] = useMutation(UPDATE_ADMIN_REPORT_MUTATION, {});

  return {
    updateCommentAdminReport,
    updateCommentAdminReportLoading,
    updateProjectAdminReport,
    updateProjectAdminReportLoading,
    updateCompanyStatus,
    updateCompanyStatusLoading,
    updateAdminReport,
    updateAdminReportLoading,
  };
};
