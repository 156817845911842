import { createTheme, responsiveFontSizes, Theme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
import { useMemo, useState } from 'react';
import { grey } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Palette {
    greyColor: Palette['primary'];
    tertiary: Palette['primary'];
  }
  interface PaletteOptions {
    greyColor: PaletteOptions['primary'];
    tertiary: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Switch' {
  interface SwitchPropsColorOverrides {
    success: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    tertiary: true;
    success: true;
  }
}

const getDesignTokens = (mode: PaletteMode) => ({
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          sizeSmall: {
            padding: '6px 22px',
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          height: 56,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '32px',
          borderWidth: '1px',
          fontWeight: 700,
          fontSize: 14,
          padding: '8px 16px',
          disabled: {
            backgroundColor: '#00000042',
            fontWeight: 700,
            color: '#FFFFFF',
            textColor: '#FFFFFF',
          },
        },
        contained: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.primary.main,
        }),
        containedSecondary: {
          backgroundColor: '#000',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#fff',
            color: '#000',
          },
        },
        outlinedSizeLarge: {
          fontWeight: 700,
          height: 56,
          fontSize: 16,
        },
        containedSizeLarge: {
          height: 56,
          fontWeight: 700,
          fontSize: 16,
        },
        outlinedSizeMedium: {
          fontWeight: 700,
          height: 40,
        },
        textSizeLarge: {
          height: 56,
          fontWeight: 700,
          fontSize: 16,
        },
        containedSizeSmall: {
          fontWeight: 700,
          height: 32,
          fontSize: 12,
          padding: '6px 24px',
        },
        containedSizeMedium: {
          fontWeight: 700,
          height: 40,
        },
        outlinedSizeSmall: {
          fontWeight: 700,
          height: 32,
          fontSize: 12,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        rounded: {
          borderRadius: 10,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '56px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '&&': {
            whiteSpace: 'nowrap',
          },
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: 220,
          padding: '8px 16px',
          fontWeight: 500,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: 64,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: '56px',
          display: 'flex',
          alignItems: 'center',
        },
        dense: {
          minHeight: '56px',
        },
        regular: {
          minHeight: '56px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '28px',
        },
        sizeSmall: {
          height: '16px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          border: 'none',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: 40,
          fontWeight: 500,
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.dark,
            fontWeight: 700,
            color: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
              color: theme.palette.primary.main,
            },
          },
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          ul: {
            paddingInlineStart: '1.5rem',
            marginBlockEnd: 0,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        outlined: ({ theme }) => ({
          borderColor: theme.palette.divider,
        }),
        rounded: {
          borderRadius: 16,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontWeight: 400,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&&': {
            borderRadius: 8,
          },
          '& .Mui-disabled': {
            borderRadius: 8,
            background: theme.palette.background.default,
            color: theme.palette.text.secondary,
          },
        }),
      },
    },
  },
  typography: {
    allVariants: {
      fontWeight: 500,
    },
    fontFamily: [
      'Noto Sans KR',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: {
            main: '#ffffff',
            dark: '#323F4B',
            light: '#ffffff',
          },
          secondary: {
            main: '#323F4B',
            dark: '#ffffff',
            light: '#F5F7FA',
          },
          tertiary: {
            main: '#323F4B',
            contrastText: '#FFFFFF',
          },
          success: {
            main: '#036351',
            light: '#E1F1EB',
          },
          info: {
            main: '#ffe812',
          },
          warning: {
            main: '#E31B0C',
            light: '#F44336',
          },
          greyColor: {
            main: grey[500],
          },
          text: {
            primary: '#323F4B',
            secondary: '#212121',
            disabled: '#0000008a',
          },
          background: {
            default: '#FAFAFA',
            paper: '#FFFFFF',
          },
          divider: '#0000001F',
        }
      : {
          primary: {
            main: '#ffffff',
            dark: '#000000',
            light: '#ffffff',
          },
          secondary: {
            main: '#AAAAAA',
            dark: '#ffffff',
            light: '#212121',
          },
          tertiary: {
            main: '#323F4B',
            contrastText: '#FFFFFF',
          },
          success: {
            main: '#036351',
            light: '#E1F1EB',
          },
          warning: {
            main: '#E31B0C',
            light: '#F44336',
          },
          info: {
            main: '#ffe812',
          },
          greyColor: {
            main: grey[500],
          },
          text: {
            primary: '#fff',
            secondary: 'rgba(255, 255, 255, 0.7)',
            disabled: 'rgba(255, 255, 255, 0.5)',
          },
          background: {
            default: '#121212',
            paper: '#212121',
          },
          divider: 'rgba(255, 255, 255, 0.12)',
        }),
  },
});

const useGlobalTheme = (): [colorMode: { toggleColorMode: () => void }, theme: Theme, mode: PaletteMode] => {
  const [mode, setMode] = useState<PaletteMode>('light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const previewTheme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  const theme = responsiveFontSizes(previewTheme);

  return [colorMode, theme, mode];
};

export default useGlobalTheme;
