import React from 'react';
import { DefaultPageProps } from 'components/interfaces';
import MainLayout from 'components/layouts/MainLayout';
import Container from 'components/container';
import { useQuery } from '@apollo/client';
import { Company, CompanyStatus, ListMetadata, OrderBy, QueryAllCompaniesArgs } from 'gql/graphql';
import ProRequestsTableRow from 'components/pro-requests/ProRequestsTableRow';
import { useSortParams } from 'hooks/useSortParams';
import { proRequestsTableHeadRows } from 'utils/miscellaneous';
import ProRequestsSearchBar from 'components/pro-requests/ProRequestsSearchBar';
import { QUERY_PRO_REQUESTS } from 'components/pro-requests/Queries';
import { CompanyStatusFilterEnum, companyStatusFilterValueMap } from 'utils/commonMaps';
import DefaultTable from 'components/table/DefaultTable';

const ProRequestsPage = ({ name }: DefaultPageProps) => {
  const { handleFieldChange, searchParams } = useSortParams();
  const { page, sortField, q, sortOrder, status } = searchParams;

  const result = useQuery<{ allCompanies: Company[]; total: ListMetadata }, QueryAllCompaniesArgs>(QUERY_PRO_REQUESTS, {
    variables: {
      page: Number(page ?? 0),
      perPage: 10,
      sortOrder: (sortOrder as OrderBy) ?? OrderBy.Desc,
      filter: {
        q: q || undefined,
        status: status
          ? companyStatusFilterValueMap[status as keyof typeof CompanyStatusFilterEnum]
          : [CompanyStatus.InReview, CompanyStatus.Rejected],
      },
      sortField: sortField ?? 'creationDate',
    },
    errorPolicy: 'all',
  });

  const { data, loading, error } = result;

  return (
    <MainLayout title={name}>
      <Container
        searchBar={
          <ProRequestsSearchBar
            handleFilterChange={handleFieldChange('status')}
            searchQuery={q}
            handleSearch={handleFieldChange('q')}
          />
        }
        title={name}
      >
        <DefaultTable result={result} rowDefinition={proRequestsTableHeadRows}>
          {data &&
            !error &&
            !loading &&
            data?.allCompanies.map((company) => {
              if (!company) return;
              return <ProRequestsTableRow key={company.id} company={company} />;
            })}
        </DefaultTable>
      </Container>
    </MainLayout>
  );
};

export default ProRequestsPage;
