import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { ApolloError, useMutation } from '@apollo/client';
import { graphql } from 'gql';
import { CompanyInterest, CompanyInterestGroup, MutationUpdateManyCompanyInterestOrderArgs, SortBy } from 'gql/graphql';
import { useSnackbar } from 'notistack';
import { useFieldArray, useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import DraggableGridChips from 'components/project-tag-settings/DraggableGridChips';
import { COMPANY_INTERESTS_QUERY } from 'components/request-tag-settings/Queries';
import { useRequestTagSettingsPopupContext } from 'components/request-tag-settings/dialogs/RequestTagSettingsContext';
import { closestCenter, DndContext, DragOverlay } from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { useSortableArray } from 'components/sortable/hooks/useSortableArray';
import Chip from '@mui/material/Chip';

const classes = {
  stack: (theme: Theme) => ({
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: 2,
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      rowGap: 2,
    },
  }),
};

const REORDER_COMPANY_INTERESTS_MUTATION = graphql(/* GraphQL */ `
  mutation updateCompanyInterestsOrder($input: [InterestOrderUpdateInput!]!, $interestGroupId: Int!) {
    updateManyCompanyInterestOrder(interestGroupId: $interestGroupId, input: $input) {
      id
    }
  }
`);

interface FormType {
  interestGroupId: number;
  input: CompanyInterest[];
}

const ReorderCompanyTagInterestsPopup = () => {
  const { setCurrentPopup, payload } = useRequestTagSettingsPopupContext();
  const { enqueueSnackbar } = useSnackbar();

  const [updateCompanyInterests, { loading }] = useMutation<
    { updateManyCompanyInterestOrder: CompanyInterestGroup },
    MutationUpdateManyCompanyInterestOrderArgs
  >(REORDER_COMPANY_INTERESTS_MUTATION);

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<FormType>({
    defaultValues: {
      interestGroupId: payload.interestGroupId,
      input: payload.interests,
    },
  });
  const handlePopupClose = () => {
    setCurrentPopup(undefined);
  };

  const { fields, move } = useFieldArray({
    name: 'input',
    control,
    keyName: 'key',
  });

  const submit = (form: FormType) => {
    updateCompanyInterests({
      variables: {
        ...form,
        input: form.input.map((item, index) => ({ id: item.id, order: index + 1 })),
      },
      refetchQueries: [
        {
          query: COMPANY_INTERESTS_QUERY,
          variables: {
            sortBy: {
              order: SortBy.Asc,
            },
          },
        },
      ],
    })
      .then(() => {
        handlePopupClose();
        enqueueSnackbar('수정내용이 저장되었습니다.', { variant: 'success' });
      })
      .catch((reason: ApolloError) => {
        enqueueSnackbar(reason.message, { variant: 'error' });
      });
  };

  const { sensors, handleDragStart, handleDragCancel, handleDragEnd, activeId } = useSortableArray(move);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid p={5} container spacing={3}>
        <Grid item xs={12}>
          <Typography align="center" variant="h6">
            <b>태그 순성 변경</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DndContext
            collisionDetection={closestCenter}
            onDragCancel={handleDragCancel}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            sensors={sensors}
          >
            <SortableContext items={fields.map((item) => item.name)} strategy={rectSortingStrategy}>
              <DraggableGridChips items={fields} />
            </SortableContext>
            <DragOverlay adjustScale>{activeId ? <Chip color="secondary" label={activeId} /> : null}</DragOverlay>
          </DndContext>
        </Grid>
        <Grid item xs={12}>
          <Stack sx={classes.stack}>
            <Button
              size="large"
              variant="outlined"
              fullWidth
              sx={{ maxWidth: 160 }}
              onClick={handlePopupClose}
              color="secondary"
            >
              취소
            </Button>
            <LoadingButton
              sx={{ maxWidth: 160 }}
              fullWidth
              disabled={!isDirty}
              loading={loading}
              size="large"
              variant="contained"
              color="inherit"
              type="submit"
            >
              저장
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default ReorderCompanyTagInterestsPopup;
