import React from 'react';
import { Picture } from 'gql/graphql';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { useSortParams } from 'hooks/useSortParams';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Navigation, Pagination } from 'swiper';
import { CloseRounded } from '@mui/icons-material';
import { isMobile } from 'react-device-detect';
import ImageItem from 'components/image/ImageItem';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material';

interface ImageCarouselProps {
  pictures: Picture[];
}

const classes = {
  stack: {
    flexWrap: 'wrap',
    gap: 2,
    flexDirection: 'row',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: '100%',
    minHeight: '100%',
    objectFit: 'contain',
  },
  backdrop: {
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
    flexDirection: 'column',
    height: '100%',
    backgroundColor: 'rgb(0,0,0,.9)',
  },
};

const ImageCarousel = ({ pictures }: ImageCarouselProps) => {
  const { searchParams, setSearchParams } = useSortParams();

  const handleClose = async () => {
    // eslint-disable-next-line no-unused-vars
    const { selectedPicture, ...rest } = searchParams;
    await setSearchParams(rest);
  };

  const handleOpen = (id: number) => async () => {
    await setSearchParams({ ...searchParams, selectedPicture: String(id) });
  };

  if (!pictures?.length) return null;

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return `<span class="${className} swiper-pagination-bullet-image"><img src=${`${pictures[index].url}`} alt="uploaded picture"/></span>`;
    },
  };

  const handleKeyUp = async (swiper: unknown, keyCode: string) => {
    // 27 is the Esc key
    // We convert keyCode because actually Swiper sends a number, not a string...
    const code = keyCode as unknown as number;
    if (code === 27) {
      await handleClose();
    }
  };

  return (
    <Box>
      <Backdrop sx={classes.backdrop} open={!!searchParams?.selectedPicture} unmountOnExit>
        <IconButton onClick={handleClose} color="primary" sx={{ position: 'absolute', left: 8, top: 8, zIndex: 2 }}>
          <CloseRounded />
        </IconButton>
        <Box position="relative" width="100vw" height="100vh" p={2}>
          <Swiper
            onKeyPress={handleKeyUp}
            initialSlide={pictures.findIndex((o) => o.id?.toString() === searchParams?.selectedPicture)}
            style={{ lineHeight: 0, height: '100%' }}
            spaceBetween={16}
            navigation={!isMobile}
            pagination={pagination}
            keyboard
            modules={[Navigation, Pagination, Keyboard]}
          >
            {pictures.map((picture) =>
              !picture?.url ? null : (
                <SwiperSlide
                  key={picture.id}
                  style={{
                    borderRadius: 16,
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '90vh',
                  }}
                >
                  <Avatar
                    imgProps={{
                      sx: classes.img,
                    }}
                    sx={{ width: '100%', height: '100%' }}
                    src={picture.url}
                    alt={picture.pic_name ?? picture.url}
                    variant="square"
                  />
                </SwiperSlide>
              ),
            )}
          </Swiper>
        </Box>
      </Backdrop>
      <Stack sx={classes.stack}>
        {pictures.map((picture) =>
          !picture?.url ? null : <ImageItem src={picture.url} onClick={handleOpen(picture.id)} />,
        )}
      </Stack>
    </Box>
  );
};

export default ImageCarousel;
