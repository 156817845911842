import React from 'react';
import { DefaultPageProps } from 'components/interfaces';
import MainLayout from 'components/layouts/MainLayout';
import Container from 'components/container';
import { companiesTableHeadRows } from 'utils/miscellaneous';
import { useQuery } from '@apollo/client';
import { Company, CompanyStatus, ListMetadata, OrderBy, QueryAllCompaniesArgs } from 'gql/graphql';
import CompaniesSearchBar from 'components/companies/CompaniesSearchBar';
import CompaniesTableRow from 'components/companies/CompaniesTableRow';
import { useSortParams } from 'hooks/useSortParams';
import { companyStatusFilterValueMap, CompanyStatusFilterEnum } from 'utils/commonMaps';
import DefaultTable from 'components/table/DefaultTable';
import { QUERY_COMPANIES } from 'components/companies/Queries';

const CompaniesPage = ({ name }: DefaultPageProps) => {
  const { handleFieldChange, searchParams } = useSortParams();
  const { page, sortField, q, sortOrder, status } = searchParams;

  const result = useQuery<{ allCompanies: Company[]; total: ListMetadata }, QueryAllCompaniesArgs>(QUERY_COMPANIES, {
    variables: {
      page: Number(page ?? 0),
      perPage: 10,
      sortOrder: (sortOrder as OrderBy) ?? OrderBy.Desc,
      filter: {
        q: q || undefined,
        status: status
          ? companyStatusFilterValueMap[status as keyof typeof CompanyStatusFilterEnum]
          : [CompanyStatus.InReview, CompanyStatus.Rejected, CompanyStatus.Approved],
      },
      sortField: sortField ?? 'creationDate',
    },
    errorPolicy: 'all',
  });

  const { data, loading, error } = result;

  return (
    <MainLayout title={name}>
      <Container
        title={name}
        searchBar={
          <CompaniesSearchBar
            handleFilterChange={handleFieldChange('status')}
            handleSearch={handleFieldChange('q')}
            searchQuery={q}
          />
        }
      >
        <DefaultTable result={result} rowDefinition={companiesTableHeadRows}>
          {data &&
            !error &&
            !loading &&
            data?.allCompanies.map((company) => <CompaniesTableRow key={company.id} company={company} />)}
        </DefaultTable>
      </Container>
    </MainLayout>
  );
};

export default CompaniesPage;
