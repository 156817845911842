import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomSelect } from 'components/select/CustomSelect';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  AdminReportStatusEnum,
  CommentAdminReport,
  CommentReportReasonEnum,
  CompanyStatus,
  PositiveReportInput,
} from 'gql/graphql';
import MenuItem from '@mui/material/MenuItem';
import PaperWithTitle from 'components/paper/PaperWithTitle';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { adminReportColorMap, commentReportReasonMap, flaggedRequestStatusManagementMap } from 'utils/commonMaps';

const classes = {
  formGroup: {
    border: (theme: Theme) => `1px solid ${theme.palette.divider}`,
    borderRadius: (theme: Theme) => theme.spacing(1),
    '& > :not(:last-of-type)': {
      borderBottom: (theme: Theme) => `1px solid ${theme.palette.divider}`,
    },
  },
  formControl: {
    ml: 0,
    mr: 0,
    p: (theme: Theme) => theme.spacing(1),
  },
  box: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      rowGap: 1,
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  }),
};

interface FormType {
  id: number;
  status: AdminReportStatusEnum;
  reportReasons: CommentReportReasonEnum[];
  reportInput: PositiveReportInput[];
}

interface CommentsReportStatusManagementProps {
  commentReport: CommentAdminReport;
  isEditable: boolean;
}

const CommentsReportStatusManagement = ({ commentReport, isEditable }: CommentsReportStatusManagementProps) => {
  const { control, watch, register } = useFormContext<FormType>();
  const watchStatus = watch('status');

  return (
    <PaperWithTitle variant="outlined" elevation={0}>
      <Stack direction="column" spacing={2}>
        <Box sx={classes.box}>
          <Typography variant="h6">
            <b>댓글 상태 선택</b>
          </Typography>
          <Controller
            render={({ field: { value, onChange } }) => (
              <CustomSelect
                disabled={!isEditable}
                size="small"
                sx={adminReportColorMap[value]}
                IconComponent={KeyboardArrowDownIcon}
                renderValue={(selected) => (
                  <b>{flaggedRequestStatusManagementMap[selected as keyof typeof CompanyStatus]}</b>
                )}
                value={value ?? commentReport?.status}
                onChange={onChange}
              >
                {Object.keys(AdminReportStatusEnum).map((item) => {
                  if (item === 'Inactive' || item === 'InReview') return;
                  return (
                    <MenuItem key={item} value={AdminReportStatusEnum[item as keyof typeof CompanyStatus]}>
                      <b>{flaggedRequestStatusManagementMap[AdminReportStatusEnum[item]]}</b>
                    </MenuItem>
                  );
                })}
              </CustomSelect>
            )}
            name="status"
            control={control}
          />
        </Box>
        <Typography color="warning.light">
          <b>*최신 업데이트된 댓글을 확인하고 댓글 상태를 선택해 주세요!</b>
        </Typography>
        {watchStatus === AdminReportStatusEnum.Approved && !commentReport?.reviewDate && (
          <Box>
            <Typography mb={(theme) => theme.spacing(2)} variant="subtitle2">
              <b>거절 사유</b>
            </Typography>
            <FormGroup sx={classes.formGroup}>
              {Object.keys(CommentReportReasonEnum).map((item) => (
                <FormControlLabel
                  value={CommentReportReasonEnum[item]}
                  {...register('reportReasons')}
                  sx={classes.formControl}
                  key={item}
                  control={<Checkbox color="secondary" />}
                  label={commentReportReasonMap[CommentReportReasonEnum[item]]}
                />
              ))}
            </FormGroup>
          </Box>
        )}
      </Stack>
    </PaperWithTitle>
  );
};

export default CommentsReportStatusManagement;
