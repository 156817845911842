import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {
  CompanyInterest,
  MutationCreateProjectInterestArgs,
  MutationUpdateProjectInterestArgs,
  MutationUpdateProjectInterestGroupArgs,
  ProjectInterest,
  ProjectInterestGroup,
  QueryProjectInterestGroupArgs,
} from 'gql/graphql';
import { useForm, Controller } from 'react-hook-form';
import { Autocomplete, Theme } from '@mui/material';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { graphql } from 'gql';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { useProjectTagSettingsPopupContext } from 'components/project-tag-settings/dialogs/ProjectTagSettingsContext';
import LoadingBox from 'components/loading/LoadingBox';

const classes = {
  stack: (theme: Theme) => ({
    columnGap: 2,
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      rowGap: 2,
    },
  }),
};

const PROJECT_INTEREST_GROUP_QUERY = graphql(/* GraphQL */ `
  query ProjectInterestGroup($id: Int!) {
    ProjectInterestGroup(id: $id) {
      interests {
        interestGroupId
        id
        name
      }
      id
      name
    }
  }
`);

const CREATE_PROJECT_INTEREST_MUTATION = graphql(/* GraphQL */ `
  mutation createProjectInterest($name: String!, $projectInterestGroupId: Int!) {
    createProjectInterest(name: $name, projectInterestGroupId: $projectInterestGroupId) {
      id
    }
  }
`);

const UPDATE_PROJECT_INTEREST_GROUP = graphql(/* GraphQL */ `
  mutation updateProjectInterestGroup($id: Int!, $name: String!) {
    updateProjectInterestGroup(id: $id, name: $name) {
      id
    }
  }
`);

const DELETE_PROJECT_INTEREST = graphql(/* GraphQL */ `
  mutation updateProjectInterest($id: Int!, $name: String!) {
    updateProjectInterest(id: $id, name: $name, projectInterestGroupId: null) {
      id
    }
  }
`);

const ModifyProjectTagSettingsPopup = () => {
  const { payload, setCurrentPopup } = useProjectTagSettingsPopupContext();
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading } = useQuery<{ ProjectInterestGroup: ProjectInterestGroup }, QueryProjectInterestGroupArgs>(
    PROJECT_INTEREST_GROUP_QUERY,
    {
      variables: {
        id: payload.interestGroupId,
      },
    },
  );

  const [createProjectInterest, { loading: loadingCreateInterest }] = useMutation(CREATE_PROJECT_INTEREST_MUTATION);

  const [updateProjectInterestGroup, { loading: loadingUpdateInterestGroup }] =
    useMutation<MutationUpdateProjectInterestGroupArgs>(UPDATE_PROJECT_INTEREST_GROUP);

  const [deleteProjectInterest] = useMutation<
    { deleteCompanyInterest: CompanyInterest },
    MutationUpdateProjectInterestArgs
  >(DELETE_PROJECT_INTEREST);

  const {
    control,
    handleSubmit,
    resetField,
    formState: { isDirty },
  } = useForm<MutationCreateProjectInterestArgs>({
    defaultValues: {
      projectInterestGroupId: payload.interestGroupId,
      name: '',
    },
  });

  const {
    handleSubmit: handleSubmitUpdateProjectInterest,
    control: controlUpdate,
    formState: { isDirty: isUpdateInterestDirty },
    resetField: resetUpdate,
  } = useForm<MutationUpdateProjectInterestGroupArgs>({
    defaultValues: {
      id: payload.interestGroupId,
      name: data?.ProjectInterestGroup?.name,
    },
  });

  useEffect(() => {
    if (data) {
      resetUpdate('name', { defaultValue: data?.ProjectInterestGroup?.name });
    }
  }, [data, resetUpdate]);

  const submitUpdateInterestGroup = (form: MutationUpdateProjectInterestGroupArgs) => {
    updateProjectInterestGroup({
      variables: form,
      refetchQueries: [{ query: PROJECT_INTEREST_GROUP_QUERY, variables: { id: payload.interestGroupId } }],
    })
      .then(() => {
        enqueueSnackbar('그룹명이 변경되었습니다.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',

            horizontal: 'center',
          },
        });
      })
      .catch((reason: ApolloError) => {
        enqueueSnackbar(reason.message, { variant: 'error' });
      });
  };

  const submit = (form: MutationCreateProjectInterestArgs) => {
    createProjectInterest({
      variables: form,
      refetchQueries: [{ query: PROJECT_INTEREST_GROUP_QUERY, variables: { id: payload.interestGroupId } }],
    })
      .then(() => {
        resetField('name');
        enqueueSnackbar('수정내용이 저장되었습니다.', { variant: 'success' });
      })
      .catch((reason: ApolloError) => {
        enqueueSnackbar(reason.message, { variant: 'error' });
      });
  };

  const handleDeleteProjectInterest = (id: number, name: string) => () => {
    deleteProjectInterest({
      variables: {
        id,
        name,
      },
      refetchQueries: [{ query: PROJECT_INTEREST_GROUP_QUERY, variables: { id: payload.interestGroupId } }],
    })
      .then(() => {
        enqueueSnackbar('수정내용이 저장되었습니다.', { variant: 'success' });
      })
      .catch((reason: ApolloError) => {
        enqueueSnackbar(reason.message, { variant: 'error' });
      });
  };

  const handleClosePopup = () => {
    setCurrentPopup(undefined);
  };

  return (
    <Grid p={5} container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h6">
          <b>태그 및 그룹 수정</b>
        </Typography>
      </Grid>
      {loading && <LoadingBox />}
      {data && !loading && (
        <>
          <Grid item xs={12}>
            <Typography mb={1} variant="subtitle2">
              <b>그룹명</b>
            </Typography>
            <form onSubmit={handleSubmitUpdateProjectInterest(submitUpdateInterestGroup)}>
              <Controller
                render={({ field }) => (
                  <Stack sx={classes.stack}>
                    <TextField fullWidth {...field} color="secondary" />
                    <LoadingButton
                      disabled={!isUpdateInterestDirty}
                      loading={loadingUpdateInterestGroup}
                      disableElevation
                      sx={{ minWidth: (theme) => theme.spacing(18) }}
                      size="large"
                      type="submit"
                      variant="contained"
                      color="inherit"
                    >
                      저장
                    </LoadingButton>
                  </Stack>
                )}
                name="name"
                control={controlUpdate}
              />
            </form>
          </Grid>
          <Grid item xs={12}>
            <Typography mb={1} variant="subtitle2">
              <b>태그 입력</b>
            </Typography>
            <Typography mb={2} variant="subtitle2">
              사용자가 프로젝트에서 선택할 기본 태그를 설정해 주세요.
            </Typography>
            <Autocomplete
              multiple
              value={data?.ProjectInterestGroup.interests}
              onChange={(event, value, situation, details) => {
                if (situation === 'removeOption') {
                  const { option }: { option: ProjectInterest } = details;
                  handleDeleteProjectInterest(option.id, option.name)();
                }
              }}
              filterSelectedOptions
              color="primary"
              disableClearable
              options={data.ProjectInterestGroup.interests}
              renderTags={(values: ProjectInterest[], getTagProps) =>
                values.map((option: ProjectInterest, index: number) => (
                  <Chip
                    key={option.id}
                    color="secondary"
                    variant="outlined"
                    label={option.name}
                    {...getTagProps({ index })}
                  />
                ))
              }
              freeSolo
              renderInput={(params) => (
                <TextField sx={{ mb: (theme) => theme.spacing(2) }} color="secondary" {...params} variant="outlined" />
              )}
            />
            <form onSubmit={handleSubmit(submit)}>
              <Stack sx={classes.stack}>
                <Controller
                  render={({ field }) => <TextField {...field} fullWidth color="secondary" />}
                  name="name"
                  control={control}
                />
                <LoadingButton
                  disabled={!isDirty}
                  loading={loadingCreateInterest}
                  disableElevation
                  sx={{ minWidth: (theme) => theme.spacing(18) }}
                  size="large"
                  type="submit"
                  variant="contained"
                  color="inherit"
                >
                  저장
                </LoadingButton>
              </Stack>
            </form>
          </Grid>
        </>
      )}
      <Grid display="flex" justifyContent="center" item xs={12}>
        <Button onClick={handleClosePopup} color="secondary">
          닫기
        </Button>
      </Grid>
    </Grid>
  );
};

export default ModifyProjectTagSettingsPopup;
