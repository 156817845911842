import { graphql } from 'gql';

export const REQUESTS_QUERY = graphql(/* GraphQL */ `
  query allRequests($page: Int, $perPage: Int, $sortField: String, $sortOrder: OrderBy, $filter: RequestFilter) {
    allRequests(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
      id
      title
      address {
        jibunAddress
      }
      status
      proposalTotalCount
      createdByUser {
        id
        name
      }
      creationDate
      modificationDate
    }
    total: _allRequestsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`);
