import React from 'react';
import { DefaultPageProps } from 'components/interfaces';
import MainLayout from 'components/layouts/MainLayout';
import Container from 'components/container';
import { useQuery } from '@apollo/client';
import { useSortParams } from 'hooks/useSortParams';
import {
  AdminReportStatusEnum,
  ListMetadata,
  OrderBy,
  QueryAllAdminReportsArgs,
  ReportTypeEnum,
  RequestAdminReport,
} from 'gql/graphql';
import { flaggedRequestsTableHeadRows } from 'utils/miscellaneous';
import FlaggedRequestsTableRow from 'components/flagged-requests/FlaggedRequestsTableRow';
import { ADMIN_REPORTS_QUERY } from 'components/flagged-requests/Queries';
import FlaggedRequestsSearchBar from 'components/flagged-requests/FlaggedRequestsSearchBar';
import DefaultTable from 'components/table/DefaultTable';

const FlaggedRequestsPage = ({ name }: DefaultPageProps) => {
  const { searchParams, handleFieldChange } = useSortParams();
  const { page, status, sortField, sortOrder, q } = searchParams;
  const result = useQuery<{ allAdminReports: RequestAdminReport[]; total: ListMetadata }, QueryAllAdminReportsArgs>(
    ADMIN_REPORTS_QUERY,
    {
      variables: {
        page: Number(page ?? 0),
        perPage: 10,
        sortOrder: (sortOrder as OrderBy) ?? OrderBy.Desc,
        sortField: sortField ?? 'creationDate',
        filter: {
          type: ReportTypeEnum.Request,
          q: q || undefined,
          status: status ? (status?.split(',') as AdminReportStatusEnum[]) : [AdminReportStatusEnum.InReview],
        },
      },
      errorPolicy: 'all',
    },
  );

  const { data, loading, error } = result;

  return (
    <MainLayout title={name}>
      <Container
        searchBar={
          <FlaggedRequestsSearchBar
            searchQuery={q}
            handleFilterChange={handleFieldChange('status')}
            handleSearch={handleFieldChange('q')}
          />
        }
        title={name}
      >
        <DefaultTable result={result} rowDefinition={flaggedRequestsTableHeadRows}>
          {data &&
            !error &&
            !loading &&
            data?.allAdminReports.map((report) => {
              if (!report) return;
              return <FlaggedRequestsTableRow key={report.id} report={report} />;
            })}
        </DefaultTable>
      </Container>
    </MainLayout>
  );
};

export default FlaggedRequestsPage;
