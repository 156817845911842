import MuiDialogTitle, { DialogTitleProps as MuiDialogTitleProp } from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Typography from '@mui/material/Typography';
import MuiDialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import React, { PropsWithChildren } from 'react';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';

const classes = {
  root: {
    margin: 0,
    padding: (theme: Theme) => theme.spacing(5, 5, 1),
  },
  closeButton: {
    position: 'absolute',
    left: (theme: Theme) => theme.spacing(1),
    top: (theme: Theme) => theme.spacing(1),
    color: (theme: Theme) => theme.palette.grey[500],
  },
  dialogTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  secondaryAction: {
    position: 'absolute',
    top: (theme: Theme) => theme.spacing(1),
    right: (theme: Theme) => theme.spacing(1),
    padding: (theme: Theme) => theme.spacing(1.5),
  },
};
export interface DialogTitleProps extends MuiDialogTitleProp {
  id: string;
  onClose?: () => void;
  option?: 'close' | 'previous';
  secondaryAction?: React.ReactNode;
}

export const DialogTitle = (props: PropsWithChildren<DialogTitleProps>) => {
  const { children, onClose, variant = 'close', secondaryAction, ...other } = props;
  return (
    <MuiDialogTitle sx={classes.root} {...other}>
      {onClose && (
        <IconButton aria-label="close" sx={classes.closeButton} onClick={onClose}>
          {variant === 'close' ? <CloseIcon /> : <ChevronLeftIcon />}
        </IconButton>
      )}
      {children && (
        <Typography variant="h6" sx={classes.dialogTitle}>
          {children}
        </Typography>
      )}
      {secondaryAction && <Box sx={classes.secondaryAction}>{secondaryAction}</Box>}
    </MuiDialogTitle>
  );
};

interface DialogContentStyleProps {
  densePadding?: boolean;
  dividerBottomOnly?: boolean;
  dividerTopOnly?: boolean;
}

export const DialogContent = (props: PropsWithChildren<DialogContentStyleProps & DialogContentProps>): JSX.Element => {
  const { densePadding, dividerBottomOnly, dividerTopOnly, sx, ...rest } = props;
  return (
    <MuiDialogContent
      sx={[
        (theme: Theme) => ({
          padding: densePadding ? theme.spacing(2) : theme.spacing(2, 5, 3),
          borderTop: dividerBottomOnly ? 'none' : undefined,
          borderBottom: dividerTopOnly ? 'none' : undefined,
          maxHeight: '70vh',
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    />
  );
};

interface DialogActionsProps {
  padding?: number;
}

export const DialogActions = ({ children, padding }: PropsWithChildren<DialogActionsProps>) => {
  return (
    <MuiDialogActions
      sx={{ m: 0, p: (theme: Theme) => (padding !== undefined ? theme.spacing(padding) : theme.spacing(0, 5, 5)) }}
    >
      {children}
    </MuiDialogActions>
  );
};
