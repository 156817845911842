import React from 'react';
import { ProposalStatusEnum } from 'gql/graphql';
import StatusComponent, { StatusComponentProps } from 'components/status/StatusComponent';

interface ProposalStatusProps {
  status: ProposalStatusEnum;
  size?: 'large' | 'medium';
}

export const proposalStatusMap: { [key in ProposalStatusEnum]: StatusComponentProps } = {
  SENT: {
    text: '지원 완료',
    status: 'success',
  },
  CANCELLED: {
    text: '취소',
    status: 'warning',
  },
};

const ProposalStatus = ({ status, size = 'medium' }: ProposalStatusProps) => {
  return <StatusComponent {...proposalStatusMap[status]} size={size} />;
};

export default ProposalStatus;
