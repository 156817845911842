import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ChartIcon = (props: SvgIconProps): JSX.Element => {
  const { fill = '#262627', width = 24, height = 24 } = props;
  return (
    <SvgIcon fill={fill} width={width} height={height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M 10,2 A 1.0001,1.0001 0 0 0 9,3 v 18 a 1.0001,1.0001 0 0 0 1,1 h 4 a 1.0001,1.0001 0 0 0 1,-1 V 3 A 1.0001,1.0001 0 0 0 14,2 Z m 1,2 h 2 v 16 h -2 z" />
      <path d="m 18,7 a 1.0001,1.0001 0 0 0 -1,1 v 13 a 1.0001,1.0001 0 0 0 1,1 h 4 a 1.0001,1.0001 0 0 0 1,-1 V 8 A 1.0001,1.0001 0 0 0 22,7 Z m 1,2 h 2 v 11 h -2 z" />
      <path d="m 2,12 a 1.0001,1.0001 0 0 0 -1,1 v 8 a 1.0001,1.0001 0 0 0 1,1 H 6 A 1.0001,1.0001 0 0 0 7,21 V 13 A 1.0001,1.0001 0 0 0 6,12 Z m 1,2 h 2 v 6 H 3 Z" />
    </SvgIcon>
  );
};

export default ChartIcon;
