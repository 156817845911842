import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const BriefcaseIcon = (props: SvgIconProps): JSX.Element => {
  const { fill = '#262627', width = 24, height = 24 } = props;
  return (
    <SvgIcon fill={fill} width={width} height={height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M 4,6 C 2.3549921,6 1,7.3549921 1,9 v 10 c 0,1.645023 1.3550102,3 3,3 h 16 c 1.645005,0 3,-1.354995 3,-3 V 9 C 23,7.3550102 21.645023,6 20,6 Z m 0,2 h 16 c 0.564175,0 1,0.435852 1,1 v 10 c 0,0.564193 -0.435807,1 -1,1 H 4 C 3.435852,20 3,19.564175 3,19 V 9 C 3,8.4358701 3.4358701,8 4,8 Z" />
      <path d="M 10,2 C 8.3549921,2 7,3.3549921 7,5 v 16 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 V 5 C 9,4.4358701 9.4358701,4 10,4 h 4 c 0.564175,0 1,0.435852 1,1 v 16 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 V 5 C 17,3.3550102 15.645023,2 14,2 Z" />
    </SvgIcon>
  );
};

export default BriefcaseIcon;
