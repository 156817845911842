import React from 'react';
import StatusComponent, { ColorMap, StatusComponentProps } from 'components/status/StatusComponent';
import { UserJoinType } from 'gql/graphql';

interface CompanyMemberRoleProps {
  joinType: UserJoinType;
}

export const userJoinTypeMap: { [key in UserJoinType]: StatusComponentProps } = {
  KAKAO: {
    text: '카카오',
    status: 'success',
  },
  APPLE: {
    text: '애플',
    status: 'default',
  },
  MOBILE: {
    text: '일반',
    status: 'warning',
  },
};

export const userJoinTypeColorMap: ColorMap = {
  warning: {
    color: 'black',
    background: '#eeeeee',
  },
  default: {
    color: 'white',
    background: '#424242',
  },
  success: {
    color: '#000000',
    background: '#ffe812',
  },
};

const UserJoinTypeComponent = ({ joinType }: CompanyMemberRoleProps) => {
  return <StatusComponent {...userJoinTypeMap[joinType]} colorMap={userJoinTypeColorMap} />;
};

export default UserJoinTypeComponent;
