import React from 'react';
import MainLayout from 'components/layouts/MainLayout';
import Container from 'components/container';
import { DefaultPageProps } from 'components/interfaces';
import { useQuery } from '@apollo/client';
import { COMMENTS_QUERY } from 'components/comments/Queries';
import {
  AdminReportStatusEnum,
  CommentAdminReport,
  ListMetadata,
  OrderBy,
  QueryAllAdminReportsArgs,
  ReportTypeEnum,
} from 'gql/graphql';
import { useSortParams } from 'hooks/useSortParams';
import CommentReportsSearchBar from 'components/comments/CommentReportsSearchBar';
import DefaultTable from 'components/table/DefaultTable';
import { commentsReportTableHeadRows } from 'utils/miscellaneous';
import CommentReportsTableRow from 'components/comments/CommentReportsTableRow';

const CommentReportsPage = ({ name }: DefaultPageProps) => {
  const { searchParams, handleFieldChange } = useSortParams();
  const { page, status, sortField, sortOrder, q } = searchParams;

  const result = useQuery<{ allAdminReports: CommentAdminReport[]; total: ListMetadata }, QueryAllAdminReportsArgs>(
    COMMENTS_QUERY,
    {
      variables: {
        page: Number(page ?? 0),
        perPage: 10,
        sortOrder: (sortOrder as OrderBy) ?? OrderBy.Desc,
        sortField: sortField ?? 'creationDate',
        filter: {
          type: ReportTypeEnum.Comment,
          q: q || undefined,
          status: status ? (status?.split(',') as AdminReportStatusEnum[]) : [AdminReportStatusEnum.InReview],
        },
      },
    },
  );

  const { data, loading, error } = result;
  return (
    <MainLayout title={name}>
      <Container
        title={name}
        searchBar={<CommentReportsSearchBar searchQuery={q} handleSearch={handleFieldChange('q')} />}
      >
        <DefaultTable result={result} rowDefinition={commentsReportTableHeadRows}>
          {data &&
            !loading &&
            !error &&
            data.allAdminReports.map((commentReport) => (
              <CommentReportsTableRow key={commentReport.id} commentReport={commentReport} />
            ))}
        </DefaultTable>
      </Container>
    </MainLayout>
  );
};

export default CommentReportsPage;
