import React, { PropsWithChildren } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import DefaultTableHead from 'components/table/DefaultTableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import FormHelperText from '@mui/material/FormHelperText';
import LoadingTableRow from 'components/loading/LoadingTableRow';
import TableFooter from '@mui/material/TableFooter';
import TableContainer from '@mui/material/TableContainer';
import { QueryResult } from '@apollo/client';
import { IDefaultTableHeadRows, TABLE_MIN_WIDTH } from 'utils/miscellaneous';
import { useSortParams } from 'hooks/useSortParams';
import { ListMetadata } from 'gql/graphql';
import DefaultTablePagination from 'components/table/DefaultTablePagination';

interface DefaultTableWithQueryProps<T> {
  result: QueryResult<T>;
  rowDefinition: IDefaultTableHeadRows[];
}

const DefaultTable = <T extends { total?: ListMetadata }>({
  result,
  rowDefinition,
  children,
}: PropsWithChildren<DefaultTableWithQueryProps<T>>) => {
  const { handlePageChange, handleTableFieldChange, searchParams } = useSortParams();
  const { page } = searchParams;

  const { data, loading, error } = result;

  return (
    <TableContainer elevation={0} component={Paper}>
      <Table sx={{ minWidth: TABLE_MIN_WIDTH }}>
        <DefaultTableHead handleTableFieldChange={handleTableFieldChange} headRows={rowDefinition} />
        <TableBody>
          {error && (
            <TableRow>
              <TableCell colSpan={rowDefinition.length}>
                <FormHelperText error>{error.message}</FormHelperText>
              </TableCell>
            </TableRow>
          )}
          {loading && <LoadingTableRow cellSpan={rowDefinition.length} />}
          {children}
        </TableBody>
        {data && !error && !loading && (
          <TableFooter>
            <DefaultTablePagination
              page={Number(page ?? 0)}
              onPageChange={handlePageChange}
              count={data?.total?.count}
            />
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default DefaultTable;
