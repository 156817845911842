import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import GridFormRow from 'components/grid/gridFormRow';
import TextField from '@mui/material/TextField';
import { User } from 'gql/graphql';
import PaperWithTitle from 'components/paper/PaperWithTitle';
import UserProfile from 'components/users/UserProfile';

interface UserInformationProps {
  user: User;
}

const UserInformation = ({ user }: UserInformationProps) => {
  const gridFormRows = [
    { name: '가입 경로', value: user.joinType },
    { name: '이메일', value: user.email },
    { name: '휴대폰 번호', value: user.mobile },
  ];

  return (
    <PaperWithTitle variant="outlined" elevation={0}>
      <Grid container spacing={4}>
        <Grid item display="flex" direction="row" xs={12}>
          <UserProfile user={user} />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <b>계정 정보</b>
          </Typography>
        </Grid>
        {gridFormRows.map((item) => (
          <Grid key={item.name} item xs={12}>
            <GridFormRow
              leftCol={
                <Typography variant="subtitle2">
                  <b>{item.name}</b>
                </Typography>
              }
              rightCol={<TextField value={item.value} disabled fullWidth color="primary" />}
            />
          </Grid>
        ))}
      </Grid>
    </PaperWithTitle>
  );
};

export default UserInformation;
