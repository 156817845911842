import { styled } from '@mui/material';
import Select from '@mui/material/Select';

export const CustomSelect = styled(Select)({
  background: '#FFFFFF',
  '& .MuiInputBase-root': {
    borderRadius: 24,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-colorPrimary': {
    borderRadius: 24,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '.MuiSvgIcon-root': {
    color: '#323F4B',
  },
});
