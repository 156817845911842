import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const AvatarIcon = (props: SvgIconProps): JSX.Element => {
  const { fill = '#262627', width = 24, height = 24 } = props;
  return (
    <SvgIcon
      {...props}
      version="1.1"
      fill={fill}
      viewBox="0 0 24 24"
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M 12,0 C 5.3563376,0 0,5.3563376 0,12 0,18.643662 5.2653821,24.155619 12,24 18.734618,23.844381 23.951227,18.540719 24,12 24.048773,5.4592808 18.643662,0 12,0 Z m 0,0.5 c 6.375312,0 11.5,5.1246885 11.5,11.5 0,2.745062 -0.95203,5.256435 -2.542969,7.228516 C 20.676195,18.652025 18.940131,17.5 17.5,17.5 H 6.5 C 5.0669938,17.5 3.324847,18.651502 3.0429688,19.228516 1.4520297,17.256435 0.5,14.745062 0.5,12 0.5,5.6246885 5.6246885,0.5 12,0.5 Z m 0.0039,2.9902344 c -3.1335811,-4.572e-4 -5.674035,2.5391223 -5.6738279,5.671875 8.72e-4,3.1319896 2.5410098,5.6703786 5.6738279,5.6699216 3.132818,4.57e-4 5.672956,-2.537932 5.673828,-5.6699216 2.07e-4,-3.1327528 -2.540247,-5.6723323 -5.673828,-5.671875 z" />
    </SvgIcon>
  );
};

export default AvatarIcon;
