import { createPopupContext, Payload, Return } from 'components/popup/popupProvider';
import React, { useContext } from 'react';
import {
  MutationUpdateRequestAdminReportArgs,
  MutationUpdateCompanyStatusArgs,
  MutationUpdateProjectAdminReportArgs,
  MutationUpdateCommentAdminReportArgs,
} from 'gql/graphql';

export type UpdateDialogsValues = 'update' | 'project_validation';
export type MutationType =
  | 'update_pro_request'
  | 'update_flagged_request'
  | 'update_flagged_project'
  | 'update_comment_report';

export interface UpdateDialogsPayload extends Payload {
  type: MutationType;
  variables?:
    | MutationUpdateCommentAdminReportArgs
    | MutationUpdateProjectAdminReportArgs
    | MutationUpdateRequestAdminReportArgs
    | MutationUpdateCompanyStatusArgs;
  title?: string;
  description?: string;
  buttonName?: string;
  Icon?: React.ReactNode;
}

export const updateDialogsPopupContext = createPopupContext<UpdateDialogsValues, UpdateDialogsPayload>();
export const useUpdateDialogsPopupContext = (): Return<UpdateDialogsValues, UpdateDialogsPayload> =>
  useContext(updateDialogsPopupContext) as unknown as Return<UpdateDialogsValues, UpdateDialogsPayload>;
