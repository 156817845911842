import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LayersIcon = (props: SvgIconProps): JSX.Element => {
  const { fill = '#262627', width = 24, height = 24 } = props;
  return (
    <SvgIcon {...props} width={width} height={height} viewBox="0 0 24 24" fill={fill} version="1.1">
      <path d="m 11.552734,1.1054687 -9.9999996,5 a 1.0001,1.0001 0 0 0 0,1.7890625 l 9.9999996,4.9999998 a 1.0001,1.0001 0 0 0 0.894532,0 l 10,-4.9999998 a 1.0001,1.0001 0 0 0 0,-1.7890625 l -10,-5 a 1.0001,1.0001 0 0 0 -0.894532,0 z M 12,3.1171875 19.763672,7 12,10.882813 4.2363281,7 Z" />
      <path d="m 1.6835937,16.050781 a 1,1 0 0 0 -0.578125,0.501953 1,1 0 0 0 0.4472657,1.341797 l 9.9999996,5 a 1.0001,1.0001 0 0 0 0.894532,0 l 10,-5 a 1,1 0 0 0 0.447265,-1.341797 1,1 0 0 0 -1.341797,-0.447265 L 12,20.882812 2.4472656,16.105469 a 1,1 0 0 0 -0.7636719,-0.05469 z" />
      <path d="m 2.4472656,11.105469 a 1,1 0 0 0 -1.3417969,0.447265 1,1 0 0 0 0.4472657,1.341797 l 9.9999996,5 a 1.0001,1.0001 0 0 0 0.894532,0 l 10,-5 a 1,1 0 0 0 0.447265,-1.341797 1,1 0 0 0 -1.341797,-0.447265 L 12,15.882813 Z" />
    </SvgIcon>
  );
};

export default LayersIcon;
