import React from 'react';
import { DefaultPageProps } from 'components/interfaces';
import MainLayout from 'components/layouts/MainLayout';
import Container from 'components/container';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import {
  AdminReportStatusEnum,
  CompanyMemberStatus,
  PositiveReportInput,
  Maybe,
  ProjectAdminReport,
  ProjectReportReasonEnum,
  ReportStatusEnum,
  Scalars,
} from 'gql/graphql';
import { graphql } from 'gql';
import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import LoadingButton from '@mui/lab/LoadingButton';
import { Theme, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import UpdateDialogsContainer from 'components/main-dialogs/containers/UpdateDialogsContainer';
import Grid from '@mui/material/Grid';
import FlaggedProjectStatusManagement from 'components/flagged-projects/[id]/FlaggedProjectStatusManagement';
import FormHelperText from '@mui/material/FormHelperText';
import { usePrompt } from 'hooks/usePrompt';
import { useUpdateDialogsPopupContext } from 'components/main-dialogs/context/UpdateDialogsPopupContext';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import ProjectInfo from 'components/projects/[id]/ProjectInfo';
import PaperWithTitle from 'components/paper/PaperWithTitle';
import { PopupProvider } from 'components/popup/popupProvider';
import { flaggedRequestPopupContext } from 'components/flagged-requests/dialogs/FlaggedRequestPopupContext';
import Divider from '@mui/material/Divider';
import FlaggedRequestPopupContainer from 'components/flagged-requests/dialogs/FlaggedRequestContainer';
import LoadingBox from 'components/loading/LoadingBox';
import ReportManagementItem from 'components/status/ReportManagementItem';
import { projectReportReasonMap } from 'utils/commonMaps';

export interface CustomProjectReport {
  creationDate: Scalars['DateTime'];
  id: Scalars['Int'];
  isPositiveReport?: Maybe<Scalars['Boolean']>;
  reportReason: ProjectReportReasonEnum;
  status: ReportStatusEnum;
  historyAtReport: {
    id: number;
  };
  user: {
    id: number;
    name: string;
    profileImg: {
      url: string;
      id: number;
      pic_name: string;
    };
    activeCompany: {
      name: string;
    };
    activeCompanyMember: {
      status: CompanyMemberStatus;
    };
  };
}

interface ConfirmDetailsI {
  title: string;
  description: string;
  buttonName: string;
  Icon: React.ReactNode;
}

interface FormType {
  id: number;
  status: AdminReportStatusEnum;
  projectReportReasons: ProjectReportReasonEnum[];
  reportInput: CustomProjectReport[];
}

const PROJECT_ADMIN_REPORT_QUERY = graphql(/* GraphQL */ `
  query ProjectAdminReport($id: Int) {
    AdminReport(id: $id) {
      ... on ProjectAdminReport {
        id
        status
        creationDate
        reviewDate
        reports {
          id
          historyAtReport {
            id
            title
            createdByUser {
              id
              name
              activeCompanyMember {
                status
              }
              activeCompany(extendedCompanyStatus: true, extendedCompanyMemberStatus: true) {
                name
              }
            }
            creationDate
            description
            projectId
          }
          creationDate
          reportReason
          user {
            id
            name
            profileImg {
              url
              id
              pic_name
            }
            activeCompanyMember {
              status
            }
            activeCompany(extendedCompanyStatus: true, extendedCompanyMemberStatus: true) {
              name
            }
          }
          isPositiveReport
        }
        project {
          id
          status
          description
          title
          reportTotalCount
          pictures {
            id
            pic_name
            url
          }
          createdByUser {
            id
            name
            profileImg {
              url
              pic_name
              id
            }
            activeCompanyMember {
              status
            }
            activeCompany(extendedCompanyStatus: true, extendedCompanyMemberStatus: true) {
              name
            }
          }
        }
        modificationDate
        reportReasons {
          adminReportId
          id
          reportReason
        }
      }
    }
  }
`);

const classes = {
  submitButton: (theme: Theme) => ({
    width: '100%',
    maxWidth: theme.spacing(30),
    [theme.breakpoints.down('sm')]: {
      maxWidth: theme.spacing(15),
    },
  }),
};

const returnConfirmDetails = (condition: boolean): ConfirmDetailsI => {
  if (condition) {
    return {
      title: '게시물 유지',
      description:
        '게시물 유지를 선택하시면 해당 프로젝트 게시물은 사용자에게 계속 노출됩니다. 게시물을 유지시키시겠습니까?',
      buttonName: '게시물 유지',
      Icon: <TaskAltRoundedIcon />,
    };
  }
  return {
    title: '게시물 중단',
    description:
      '게시물을 중단하시겠습니까?\n 게시물이 중단되면 프로젝트 게시물을 게시한 사용자에게 게시물 중단 사유와 함께 알림이 전달됩니다.',
    buttonName: '게시물 중단하기',
    Icon: <HighlightOffRoundedIcon />,
  };
};

const FlaggedProjectPage = ({ name }: DefaultPageProps) => {
  const params = useParams();
  const navigate = useNavigate();

  const { setCurrentPopup } = useUpdateDialogsPopupContext();
  const methods = useForm<FormType>({
    mode: 'onChange',
  });

  const {
    reset,
    control,
    getValues,
    formState: { isDirty, isSubmitted },
  } = methods;

  const { data, loading, error } = useQuery<{ AdminReport: ProjectAdminReport }>(PROJECT_ADMIN_REPORT_QUERY, {
    variables: {
      id: Number(params.id),
    },
    onCompleted(res) {
      reset({
        id: Number(params?.id),
        status: res?.AdminReport.status,
        projectReportReasons: res?.AdminReport.reportReasons?.map((reason) => reason?.reportReason) ?? [],
        reportInput: res?.AdminReport.reports.map((projectReport) => ({
          ...projectReport,
          isPositiveReport: projectReport.isPositiveReport,
        })),
      });
    },
    skip: !Number(params.id),
  });

  const { fields, update } = useFieldArray({
    name: 'reportInput',
    keyName: 'key',
    control,
  });

  const handleUpdateField = (idx: number, report: CustomProjectReport) => (isPositiveReport: boolean) => {
    update(idx, { ...report, isPositiveReport });
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const getReportInput = (form: FormType): PositiveReportInput[] => {
    return form.reportInput.map((item) => ({
      id: item.id,
      isPositiveReport: item.isPositiveReport,
    }));
  };

  const handleSetPopup = () => {
    setCurrentPopup('update', {
      maxWidth: 'xs',
      type: 'update_flagged_project',
      variables: { ...getValues(), reportInput: getReportInput(getValues()) },
      ...returnConfirmDetails(getValues('status') === AdminReportStatusEnum.Rejected),
    });
  };

  const isEditable =
    data?.AdminReport?.status !== AdminReportStatusEnum.Rejected &&
    data?.AdminReport?.status !== AdminReportStatusEnum.Approved;

  usePrompt(
    '페이지를 이동하시겠습니까?\n페이지를 이동하면 변경사항이 저장되지 않을 수 있습니다.',
    isDirty && !isSubmitted,
  );

  return (
    <FormProvider {...methods}>
      <form>
        <MainLayout
          title={name}
          bottomController={
            <>
              <Button
                onClick={handleNavigateBack}
                color="secondary"
                startIcon={<ArrowBackIosNewIcon fontSize="small" />}
              >
                뒤로가기
              </Button>
              <LoadingButton
                disabled={!isDirty}
                onClick={handleSetPopup}
                sx={classes.submitButton}
                variant="contained"
                color="inherit"
              >
                저장
              </LoadingButton>
            </>
          }
        >
          <Container title={name}>
            <Grid container spacing={2}>
              {error && (
                <Grid item xs={12}>
                  <FormHelperText error>{error.message}</FormHelperText>
                </Grid>
              )}
              {loading && <LoadingBox />}
              {data && !loading && !error && (
                <>
                  <Grid item xs={12}>
                    <FlaggedProjectStatusManagement projectAdminReport={data?.AdminReport} isEditable={isEditable} />
                  </Grid>
                  <Grid item xs={12}>
                    <ProjectInfo project={data?.AdminReport.project} />
                  </Grid>
                  <Grid item xs={12}>
                    <PaperWithTitle
                      variant="outlined"
                      header={
                        <Typography mb={3} variant="h6">
                          <b>신고 내역</b>
                        </Typography>
                      }
                    >
                      <PopupProvider context={flaggedRequestPopupContext}>
                        {fields.map((projectReport, idx) => (
                          <>
                            <ReportManagementItem
                              key={projectReport?.id}
                              isEditable={isEditable}
                              handleUpdateField={handleUpdateField(idx, projectReport)}
                              report={projectReport}
                              reportReason={projectReportReasonMap[projectReport?.reportReason]}
                            />
                            {idx + 1 !== data.AdminReport.reports.length && (
                              <Divider sx={{ m: (theme) => theme.spacing(3, 0) }} />
                            )}
                          </>
                        ))}
                        <FlaggedRequestPopupContainer />
                      </PopupProvider>
                    </PaperWithTitle>
                  </Grid>
                </>
              )}
            </Grid>
          </Container>
        </MainLayout>
      </form>
      <UpdateDialogsContainer />
    </FormProvider>
  );
};

export default FlaggedProjectPage;
