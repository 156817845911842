import jwtDecode from 'jwt-decode';
import React, { createContext, Dispatch, SetStateAction, useState } from 'react';
import client from 'apolloClient';
import { LoginFormType } from 'types';
import { AccountType, SignInMutation, SignInDocument, RefreshTokenDocument } from 'gql/graphql';
import { graphql } from 'gql';

export const LOGIN_MUTATION = graphql(/* GraphQL */ `
  mutation SignIn($username: String!, $password: String!, $accountType: AccountType) {
    signIn(username: $username, password: $password, accountType: $accountType) {
      message
      accessToken
      refreshToken
      user {
        id
        name
        level
      }
    }
  }
`);

export const REFRESH_MUTATION = graphql(/* GraphQL */ `
  mutation RefreshToken($refreshToken: String!, $accountType: AccountType) {
    refreshToken(refreshToken: $refreshToken, accountType: $accountType) {
      message
      accessToken
    }
  }
`);

interface AuthProviderProps {
  children?: React.ReactNode;
}
interface AuthContextType {
  tabValue: string;
  isLoading: boolean;
  setTabValue: Dispatch<SetStateAction<string>>;
  login: ({ username, password }) => Promise<any>;
  logout: () => Promise<void>;
  checkError: () => Promise<void>;
  checkAuth: () => any;
  getPermissions: () => Promise<string | void>;
}
export const AuthContext = createContext<AuthContextType>({
  tabValue: '',
  isLoading: false,
  setTabValue: () => {},
  login: async () => {},
  logout: async () => {},
  checkError: async () => {},
  checkAuth: () => {},
  getPermissions: async () => {},
});

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [tabValue, setTabValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const login = async ({ username, password }: LoginFormType): Promise<SignInMutation> => {
    try {
      const { data } = await client.mutate({
        mutation: SignInDocument,
        variables: { username, password, accountType: AccountType.Admin },
      });
      if (data.signIn.accessToken) {
        const { accessToken, user, refreshToken } = data.signIn;
        localStorage.setItem('token', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('permissions', user.level);
        return { ...data };
      }
      return await Promise.reject(null);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const logout = async (): Promise<void> => {
    await localStorage.removeItem('token');
    await localStorage.removeItem('refreshToken');
    await localStorage.removeItem('permissions');
    return Promise.resolve();
  };

  const checkError = async (): Promise<void> => {
    setIsLoading(true);
    const token = localStorage.getItem('token');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (token) {
      const decoded = jwtDecode(token) as any;
      if (decoded.exp <= Math.round(Date.now() / 1000)) {
        const refreshToken = localStorage.getItem('refreshToken');
        try {
          const { data } = await client.mutate({
            mutation: RefreshTokenDocument,
            variables: { refreshToken, accountType: AccountType.Admin },
          });
          const { accessToken } = data.refreshToken;
          localStorage.setItem('token', accessToken);
          setIsLoading(false);
          return await Promise.resolve();
        } catch (e) {
          setIsLoading(false);
          logout();
          return await Promise.reject(e);
        }
      } else {
        setIsLoading(false);
        return Promise.resolve();
      }
    }
    setIsLoading(false);
    logout();
    return Promise.reject();
  };

  const checkAuth = () => {
    return !!localStorage.getItem('token');
  };

  const getPermissions = async (): Promise<string> => {
    const level = localStorage.getItem('permissions');
    return level ? Promise.resolve(level) : Promise.reject();
  };

  return (
    <AuthContext.Provider
      value={{ isLoading, login, logout, checkAuth, checkError, getPermissions, tabValue, setTabValue }}
    >
      {children}
    </AuthContext.Provider>
  );
};
