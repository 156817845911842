import React from 'react';
import { CompanyStatus } from 'gql/graphql';
import StatusComponent, { StatusComponentProps } from 'components/status/StatusComponent';

interface ProRequestStatusProps {
  status: CompanyStatus;
}

export const companyStatusMap: { [key in CompanyStatus]: StatusComponentProps } = {
  IN_REVIEW: {
    text: '신청 완료',
    status: 'default',
  },
  REJECTED: {
    text: '반려',
    status: 'warning',
  },
  APPROVED: {
    text: '승인',
    status: 'success',
  },
  ARCHIVED: {
    text: '아카이브',
    status: 'default',
  },
};

const ProRequestStatus = ({ status }: ProRequestStatusProps) => {
  return <StatusComponent {...companyStatusMap[status]} />;
};

export default ProRequestStatus;
