import React from 'react';
import Dialog from '@mui/material/Dialog';
import TabPanel from 'components/tabs/TabPanel';
import ModifyProjectTagSettingsPopup from 'components/project-tag-settings/dialogs/ModifyProjectTagSettingsPopup';
import DeleteProjectTagGroupPopup from 'components/project-tag-settings/dialogs/DeleteProjectTagGroupPopup';
import CreateProjectTagGroupPopup from 'components/project-tag-settings/dialogs/CreateProjectTagGroupPopup';
import { useProjectTagSettingsPopupContext } from 'components/project-tag-settings/dialogs/ProjectTagSettingsContext';
import ReorderProjectTagInterestsPopup from 'components/project-tag-settings/dialogs/ReorderProjectTagInterestsPopup';

interface TagSettingsContainerProps {
  onClose?: () => void;
}

const ProjectTagSettingsContainer = (props: TagSettingsContainerProps): JSX.Element => {
  const { onClose = () => {} } = props;
  const { currentPopup, setCurrentPopup, payload } = useProjectTagSettingsPopupContext();
  const open = currentPopup !== undefined;

  const handleClose = () => {
    setCurrentPopup(undefined);
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="project-tag-settings-popup"
      open={open}
      maxWidth={payload?.maxWidth ?? 'sm'}
      fullWidth
    >
      <TabPanel index="modify_tags" value={currentPopup}>
        <ModifyProjectTagSettingsPopup />
      </TabPanel>
      <TabPanel index="delete_tag_group" value={currentPopup}>
        <DeleteProjectTagGroupPopup />
      </TabPanel>
      <TabPanel index="create_tag_group" value={currentPopup}>
        <CreateProjectTagGroupPopup />
      </TabPanel>
      <TabPanel index="reorder_tag_group" value={currentPopup}>
        <ReorderProjectTagInterestsPopup />
      </TabPanel>
    </Dialog>
  );
};

export default ProjectTagSettingsContainer;
