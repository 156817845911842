import React from 'react';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material';

interface ReportStatusProps {
  status: boolean;
  size?: 'large' | 'medium';
}

const classes = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '100%',
  },
  main: {
    borderRadius: (theme: Theme) => theme.spacing(1),
  },
  medium: {
    fontSize: (theme: Theme) => theme.typography.pxToRem(12),
    p: (theme: Theme) => theme.spacing(0.5, 3),
  },
  large: {
    fontSize: (theme: Theme) => theme.typography.pxToRem(16),
    p: (theme: Theme) => theme.spacing(1, 3),
  },
  reject: {
    color: 'warning.light',
    background: '#FBEAE6',
  },
  wait: {
    color: '#212121',
    background: '#00000014',
  },
};

const ReportStatus = ({ status, size = 'medium' }: ReportStatusProps) => {
  return (
    <Box sx={classes.container}>
      <Box sx={[classes.main, status ? classes.wait : classes.reject, classes[size]]}>
        <b>{status ? '신고 접수' : '신고 부적합'}</b>
      </Box>
    </Box>
  );
};

export default ReportStatus;
