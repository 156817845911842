import React from 'react';
import { useFlaggedRequestPopupContext } from 'components/flagged-requests/dialogs/FlaggedRequestPopupContext';
import { useQuery } from '@apollo/client';
import { graphql } from 'gql';
import { ProjectHistory } from 'gql/graphql';
import Box from '@mui/material/Box';
import ProjectHistoryInfo from 'components/flagged-projects/[id]/ProjectHistoryInfo';
import LoadingBox from 'components/loading/LoadingBox';

const PROJECT_QUERY = graphql(/* GraphQL */ `
  query projectHistory($id: Int!) {
    projectHistory(id: $id) {
      id
      title
      pictures {
        pic_name
        url
        id
      }
      createdByUser {
        id
        profileImg {
          id
          url
        }
        activeCompanyMember {
          status
        }
        activeCompany(extendedCompanyStatus: true, extendedCompanyMemberStatus: true) {
          name
          status
        }
      }
      status
      title
      description
    }
  }
`);
const RequestHistoryPopup = () => {
  const { payload } = useFlaggedRequestPopupContext();
  const { data, loading } = useQuery<{ projectHistory: ProjectHistory }>(PROJECT_QUERY, {
    variables: {
      id: Number(payload.id),
    },
    skip: !Number(payload.id),
  });

  return (
    <Box p={4}>
      {loading && <LoadingBox />}
      {data && <ProjectHistoryInfo projectHistory={data.projectHistory} />}
    </Box>
  );
};

export default RequestHistoryPopup;
