import { graphql } from 'gql';

export const QUERY_PRO_REQUESTS = graphql(/* GraphQL */ `
  query allProRequests($page: Int, $perPage: Int, $sortField: String, $sortOrder: OrderBy, $filter: CompanyFilter) {
    allCompanies(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
      id
      name
      registrationNumber
      status
      companyMembers {
        status
        userId
        company {
          name
        }
        user {
          id
          name
          email
        }
        roles {
          role
        }
      }
      rejectReason
      creationDate
      modificationDate
    }
    total: _allCompaniesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`);
