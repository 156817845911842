import React from 'react';
import { useFlaggedRequestPopupContext } from 'components/flagged-requests/dialogs/FlaggedRequestPopupContext';
import { useQuery } from '@apollo/client';
import { graphql } from 'gql';
import { RequestHistory } from 'gql/graphql';
import Box from '@mui/material/Box';
import RequestHistoryInfo from 'components/flagged-requests/[id]/RequestHistoryInfo';
import LoadingBox from 'components/loading/LoadingBox';

const REQUEST_QUERY = graphql(/* GraphQL */ `
  query requestHistory($id: Int!) {
    RequestHistory(id: $id) {
      id
      address {
        address1
        latitude
        longitude
      }
      possibleDateDiscussion
      assignedUser {
        id
        profileImg {
          id
          url
        }
        activeCompanyMember {
          status
        }
        activeCompany(extendedCompanyStatus: true, extendedCompanyMemberStatus: true) {
          name
          status
        }
      }
      status
      title
      description
      creationDate
      startDate
      endDate
    }
  }
`);

const RequestHistoryPopup = () => {
  const { payload } = useFlaggedRequestPopupContext();
  const { data, loading } = useQuery<{ RequestHistory: RequestHistory }>(REQUEST_QUERY, {
    variables: {
      id: Number(payload.id),
    },
    skip: !Number(payload.id),
  });

  return (
    <Box p={4}>
      {loading && <LoadingBox />}
      {data && <RequestHistoryInfo requestHistory={data.RequestHistory} />}
    </Box>
  );
};

export default RequestHistoryPopup;
