import React from 'react';
import { DefaultPageProps } from 'components/interfaces';
import MainLayout from 'components/layouts/MainLayout';
import Container from 'components/container';
import PaperWithTitle from 'components/paper/PaperWithTitle';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import CollapsibleTagGroup from 'components/request-tag-settings/CollapsibleTagGroup';
import {
  CompanyInterest,
  CompanyInterestGroup,
  MutationUpdateManyCompanyInterestGroupOrderArgs,
  SortBy,
} from 'gql/graphql';
import NestedGridWithChips from 'components/companies/[id]/NestedGridWithChips';
import RequestTagSettingsContainer from 'components/request-tag-settings/dialogs/RequestTagSettingsContainer';
import Box from '@mui/material/Box';
import { useRequestTagSettingsPopupContext } from 'components/request-tag-settings/dialogs/RequestTagSettingsContext';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { COMPANY_INTERESTS_QUERY } from 'components/request-tag-settings/Queries';
import { Theme } from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSortParams } from 'hooks/useSortParams';
import { graphql } from 'gql';
import { useSnackbar } from 'notistack';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import LoadingButton from '@mui/lab/LoadingButton';
import { closestCenter, DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { useSortableArray } from 'components/sortable/hooks/useSortableArray';
import LoadingCollapsibleTagGroup from 'components/loading/LoadingCollapsibleTagGroup';

const UPDATE_COMPANY_INTERESTS_ORDER_MUTATION = graphql(/* GraphQL */ `
  mutation updateManyCompanyInterestGroupOrder($input: [InterestOrderUpdateInput!]!) {
    updateManyCompanyInterestGroupOrder(input: $input) {
      id
    }
  }
`);

const classes = {
  paper: {
    '& > :not(:last-of-type)': {
      borderBottom: '1px solid #E0E0E0',
    },
    p: 0,
  },
  title: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '40px',
    color: 'text.secondary',
  },
  box: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    justifyContent: 'space-between',
    width: '100%',
    rowGap: 1,
  }),
  submitButton: (theme: Theme) => ({
    width: '100%',
    maxWidth: theme.spacing(30),
    [theme.breakpoints.down('sm')]: {
      maxWidth: theme.spacing(15),
    },
  }),
};

interface FormType {
  input: CompanyInterestGroup[];
}

const RequestTagSettingsPage = ({ name }: DefaultPageProps) => {
  const { searchParams, setSearchParams } = useSortParams();
  const { isEditing } = searchParams;

  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormType>();

  const [updateOrderMutation, { loading: mutationLoading }] = useMutation<
    { updateManyCompanyInterestGroupOrder: CompanyInterestGroup },
    MutationUpdateManyCompanyInterestGroupOrderArgs
  >(UPDATE_COMPANY_INTERESTS_ORDER_MUTATION);

  const { data, loading } = useQuery<{ companyInterestGroups: CompanyInterestGroup[] }>(COMPANY_INTERESTS_QUERY, {
    variables: {
      sortBy: {
        order: SortBy.Asc,
      },
    },
    onCompleted(res) {
      reset({
        input: res?.companyInterestGroups,
      });
    },
  });

  const { enqueueSnackbar } = useSnackbar();
  const { setCurrentPopup } = useRequestTagSettingsPopupContext();
  const handleEditPopup = (id: number) => () => {
    setCurrentPopup('modify_tags', {
      interestGroupId: id,
    });
  };

  const handleSetGroupEditing = (isEdit: boolean) => () => {
    if (isEdit) {
      setSearchParams({ isEditing: 'true' });
    } else {
      setSearchParams({});
      reset({
        input: data?.companyInterestGroups,
      });
    }
  };

  const { fields, move } = useFieldArray({
    control,
    name: 'input',
    keyName: 'key',
  });

  const handleDeletePopup = (id: number) => () => {
    setCurrentPopup('delete_tag_group', {
      maxWidth: 'xs',
      tagGroupId: id,
    });
  };

  const submit = (form: FormType) => {
    updateOrderMutation({
      variables: {
        input: form.input.map((group, index) => ({ id: group.id, order: index + 1 })),
      },
      refetchQueries: [
        {
          query: COMPANY_INTERESTS_QUERY,
          variables: {
            sortBy: {
              order: SortBy.Asc,
            },
          },
        },
      ],
    })
      .then(() => {
        handleSetGroupEditing(false)();
        enqueueSnackbar('수정내용이 저장되었습니다.', { variant: 'success' });
      })
      .catch((e: ApolloError) => {
        enqueueSnackbar(e.message, { variant: 'error' });
      });
  };

  const handleCreatePopup = () => {
    setCurrentPopup('create_tag_group');
  };

  const handleInterestsEditing = (interestGroupId: number, interests: CompanyInterest[]) => () => {
    setCurrentPopup('reorder_tag_group', {
      interestGroupId,
      interests,
    });
  };

  const { sensors, handleDragStart, handleDragCancel, handleDragEnd } = useSortableArray(move);
  return (
    <>
      <form onSubmit={handleSubmit(submit)}>
        <MainLayout
          bottomController={
            isEditing && (
              <>
                <Button
                  onClick={handleSetGroupEditing(false)}
                  color="secondary"
                  startIcon={<ArrowBackIosNewIcon fontSize="small" />}
                >
                  뒤로가기
                </Button>
                <LoadingButton
                  disabled={!isDirty}
                  loading={mutationLoading}
                  type="submit"
                  sx={classes.submitButton}
                  variant="contained"
                  color="inherit"
                >
                  저장
                </LoadingButton>
              </>
            )
          }
          title={name}
        >
          <Container
            title={
              <Box sx={classes.box}>
                <Typography sx={classes.title}>{name}</Typography>
                <div>
                  <Button onClick={handleCreatePopup} color="inherit" startIcon={<AddIcon />} variant="text">
                    태그 그룹 생성
                  </Button>
                  <Button onClick={handleSetGroupEditing(true)} color="inherit" variant="text">
                    그룹 순서 변경
                  </Button>
                </div>
              </Box>
            }
          >
            <DndContext
              collisionDetection={closestCenter}
              onDragCancel={handleDragCancel}
              onDragEnd={handleDragEnd}
              onDragStart={handleDragStart}
              sensors={sensors}
            >
              <SortableContext items={fields.map((item) => item.name)}>
                <PaperWithTitle disableGutters sx={classes.paper} variant="outlined">
                  {loading && <LoadingCollapsibleTagGroup amount={3} />}
                  {data &&
                    !loading &&
                    fields.map((companyInterestGroup) => (
                      <CollapsibleTagGroup
                        isEditing={Boolean(isEditing)}
                        handleDelete={handleDeletePopup(companyInterestGroup.id)}
                        handleEdit={handleEditPopup(companyInterestGroup.id)}
                        name={companyInterestGroup.name}
                        key={companyInterestGroup.id}
                      >
                        <NestedGridWithChips
                          handleSetEditing={handleInterestsEditing(
                            companyInterestGroup.id,
                            companyInterestGroup.interests,
                          )}
                          items={companyInterestGroup.interests}
                        />
                      </CollapsibleTagGroup>
                    ))}
                </PaperWithTitle>
              </SortableContext>
            </DndContext>
          </Container>
        </MainLayout>
      </form>
      <RequestTagSettingsContainer />
    </>
  );
};

export default RequestTagSettingsPage;
