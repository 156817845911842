import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { graphql } from 'gql';
import { ApolloError, useMutation } from '@apollo/client';
import { CompanyInterestGroup, MutationCreateCompanyInterestGroupArgs, SortBy } from 'gql/graphql';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { useRequestTagSettingsPopupContext } from 'components/request-tag-settings/dialogs/RequestTagSettingsContext';
import { Theme } from '@mui/material';
import { COMPANY_INTERESTS_QUERY } from 'components/request-tag-settings/Queries';

const classes = {
  stack: (theme: Theme) => ({
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: 2,
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      rowGap: 2,
    },
  }),
};

const CREATE_COMPANY_INTEREST_GROUP_MUTATION = graphql(/* GraphQL */ `
  mutation createCompanyInterestGroup($name: String!) {
    createCompanyInterestGroup(name: $name) {
      id
    }
  }
`);

const CreateRequestTagGroupPopup = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<MutationCreateCompanyInterestGroupArgs>({
    defaultValues: {
      name: '',
    },
  });

  const { enqueueSnackbar } = useSnackbar();
  const { setCurrentPopup } = useRequestTagSettingsPopupContext();

  const handlePopupClose = () => {
    setCurrentPopup(undefined);
  };

  const [createProjectInterestGroup, { loading }] = useMutation<
    { createCompanyInterestGroup: CompanyInterestGroup },
    MutationCreateCompanyInterestGroupArgs
  >(CREATE_COMPANY_INTEREST_GROUP_MUTATION);

  const submit = (form: MutationCreateCompanyInterestGroupArgs) => {
    createProjectInterestGroup({
      variables: form,
      refetchQueries: [
        {
          query: COMPANY_INTERESTS_QUERY,
          variables: {
            sortBy: {
              order: SortBy.Asc,
            },
          },
        },
      ],
    })
      .then(() => {
        handlePopupClose();
        enqueueSnackbar('수정내용이 저장되었습니다.', { variant: 'success' });
      })
      .catch((reason: ApolloError) => {
        enqueueSnackbar(reason.message, { variant: 'error' });
      });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid p={5} container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h6">
            <b>태그 그룹 생성</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography mb={1} variant="subtitle2">
            <b>그룹명</b>
          </Typography>
          <Controller
            rules={{ required: 'group name is required' }}
            render={({ field }) => (
              <TextField
                helperText={errors?.name?.message}
                error={Boolean(errors?.name)}
                fullWidth
                {...field}
                color="secondary"
              />
            )}
            control={control}
            name="name"
          />
        </Grid>
        <Grid item xs={12}>
          <Stack sx={classes.stack}>
            <Button
              size="large"
              variant="outlined"
              fullWidth
              sx={{ maxWidth: 160 }}
              onClick={handlePopupClose}
              color="secondary"
            >
              취소
            </Button>
            <LoadingButton
              sx={{ maxWidth: 160 }}
              fullWidth
              loading={loading}
              size="large"
              variant="contained"
              color="inherit"
              type="submit"
            >
              저장
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateRequestTagGroupPopup;
