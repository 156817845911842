import React from 'react';
import Dialog from '@mui/material/Dialog';
import TabPanel from 'components/tabs/TabPanel';
import { useUpdateDialogsPopupContext } from 'components/main-dialogs/context/UpdateDialogsPopupContext';
import UpdatePopup from 'components/main-dialogs/UpdatePopup';
import ProjectValidationPopup from 'components/main-dialogs/ProjectValidationPopup';

interface UpdateDialogsContainerProps {
  onClose?: () => void;
}

const UpdateDialogsContainer = (props: UpdateDialogsContainerProps): JSX.Element => {
  const { onClose = () => {} } = props;
  const { currentPopup, setCurrentPopup, payload } = useUpdateDialogsPopupContext();
  const open = currentPopup !== undefined;

  const handleClose = () => {
    setCurrentPopup(undefined);
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="update-popup"
      open={open}
      maxWidth={payload?.maxWidth ?? 'sm'}
      fullWidth
    >
      <TabPanel index="update" value={currentPopup}>
        <UpdatePopup />
      </TabPanel>
      <TabPanel index="project_validation" value={currentPopup}>
        <ProjectValidationPopup />
      </TabPanel>
    </Dialog>
  );
};

export default UpdateDialogsContainer;
