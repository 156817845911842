import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const LoadingBox = () => {
  return (
    <Box width="100%" display="flex" justifyContent="center">
      <CircularProgress color="secondary" />
    </Box>
  );
};

export default LoadingBox;
