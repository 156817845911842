import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { SxProps } from '@mui/material';
import { Interest } from 'gql/graphql';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

interface NestedGridProps {
  title?: string;
  items: Interest[];
  handleSetEditing?: () => void;
  onClick?: (id: number) => () => void;
  isSelected?: (id: number) => boolean;
  onDelete?: () => void;
  sxChip?: SxProps;
}

const NestedGridWithChips = ({
  title,
  items,
  onClick,
  handleSetEditing,
  isSelected,
  onDelete,
  sxChip,
}: NestedGridProps) => {
  return (
    <Grid container spacing={2}>
      {title && (
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            <b>{title}</b>
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {items.map((item) => (
            <Grid key={item.id} item>
              <Chip
                sx={sxChip}
                onClick={onClick && onClick(item.id)}
                variant={isSelected && isSelected(item.id) ? 'filled' : 'outlined'}
                color="secondary"
                onDelete={onDelete}
                label={item.name}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      {handleSetEditing && items.length > 0 && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid display="flex" justifyContent="flex-end" item xs={12}>
            <Button onClick={handleSetEditing} color="inherit" variant="text">
              태그 순서 변경
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default NestedGridWithChips;
