import React from 'react';
import { AdminReportStatusEnum } from 'gql/graphql';
import StatusComponent, { StatusComponentProps } from 'components/status/StatusComponent';

interface FlaggedRequestStatusProps {
  status: AdminReportStatusEnum;
}

export const flaggedRequestStatusMap: { [key in AdminReportStatusEnum]: StatusComponentProps } = {
  IN_REVIEW: {
    text: '신고 접수',
    status: 'default',
  },
  REJECTED: {
    text: '게시글 유지 ',
    status: 'success',
  },
  APPROVED: {
    text: '게시글 중단',
    status: 'warning',
  },
  INACTIVE: {
    text: '신고 보류 ',
    status: 'default',
  },
};

const FlaggedRequestStatus = ({ status }: FlaggedRequestStatusProps) => {
  return <StatusComponent {...flaggedRequestStatusMap[status]} />;
};

export default FlaggedRequestStatus;
