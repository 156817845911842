import React, { createContext, PropsWithChildren, useCallback, useState } from 'react';
import { DialogProps } from '@mui/material';

export interface Payload extends Record<string, unknown> {
  skipFromHistory?: boolean;
  maxWidth?: DialogProps['maxWidth'];
}

export interface Return<T, U extends Payload = Payload> {
  currentPopup: T | undefined;
  setCurrentPopup: (value?: T, payload?: U) => void;
  goBack: (payload?: U) => void;
  payload?: U;
}

export const createPopupContext = <T, U extends Payload = Payload>(): React.Context<Return<T, U> | null> =>
  createContext<Return<T, U> | null>(null);

/**
 * Helper for generic context where you can pass any value for navigating between screens.
 * Common usage is
 * ```typescript
 * const myContext = createPopupContext<'screen1' | 'screen2'>();
 * export const useGenericContext = <T,>(): Return<T> => useContext(myContext) as unknown as Return<T>;
 * ```
 */
export const PopupProvider = <T,>(
  props: PropsWithChildren<{ context: React.Context<Return<T> | null> }>,
): JSX.Element => {
  const { children, context: Context } = props;
  const [currentPopup, setCurrentPopup] = useState<T | undefined>(undefined);
  const [history, setHistory] = useState<T[]>([]);
  const [payload, setPayload] = useState<Payload | undefined>(undefined);

  const handleSetCurrent = useCallback((value?: T, args?: Payload) => {
    const { skipFromHistory = false, ...rest } = { ...args };
    setCurrentPopup(value);
    if (!value) {
      setHistory([]);
    } else if (!skipFromHistory) {
      setHistory((prev) => [...prev, value]);
    }
    setPayload(rest);
  }, []);

  const handleGoBack = useCallback(
    (args?: Payload) => {
      if (history.length <= 1) {
        return;
      }
      const currentHistory = history;
      currentHistory.pop();
      setCurrentPopup(currentHistory[currentHistory.length - 1]);
      setHistory([...currentHistory]);
      setPayload(args);
    },
    [history],
  );

  return (
    <Context.Provider
      value={{
        currentPopup: currentPopup,
        setCurrentPopup: handleSetCurrent,
        goBack: handleGoBack,
        payload: payload,
      }}
    >
      {children}
    </Context.Provider>
  );
};
