import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FlagIcon = (props: SvgIconProps): JSX.Element => {
  const { fill = '#262627', width = 24, height = 24 } = props;
  return (
    <SvgIcon fill={fill} width={width} height={height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M 8,1 C 6.3888905,1 5.2569712,1.2710396 4.5039062,1.5722656 3.7508413,1.8734916 3.2929687,2.2929688 3.2929688,2.2929688 A 1.0001,1.0001 0 0 0 3,3 v 12 a 1.0001,1.0001 0 0 0 1.7070312,0.707031 c 0,0 0.042129,-0.08052 0.5390625,-0.279297 C 5.7430275,15.228961 6.6111125,15 8,15 c 1.2777765,0 2.378908,0.427735 3.628906,0.927734 C 12.878905,16.427734 14.277779,17 16,17 c 1.611109,0 2.743029,-0.27104 3.496094,-0.572266 0.753065,-0.301226 1.210937,-0.720703 1.210937,-0.720703 A 1.0001,1.0001 0 0 0 21,15 V 3 A 1.0001,1.0001 0 0 0 19.292969,2.2929688 c 0,0 -0.04213,0.080523 -0.539063,0.2792968 C 18.256972,2.7710391 17.388887,3 16,3 14.722224,3 13.621093,2.5722651 12.371094,2.0722656 11.121095,1.5722661 9.7222205,1 8,1 Z M 8,3 C 9.2777765,3 10.378908,3.4277349 11.628906,3.9277344 12.878905,4.4277339 14.277779,5 16,5 17.382433,5 18.259745,4.7565648 19,4.5097656 v 9.9355474 c -0.07663,0.04489 -0.01944,0.03629 -0.246094,0.126953 C 18.256972,14.771039 17.388887,15 16,15 14.722224,15 13.621093,14.572265 12.371094,14.072266 11.121095,13.572266 9.7222205,13 8,13 6.6175668,13 5.740255,13.243435 5,13.490234 V 3.5546875 C 5.0766328,3.5097968 5.0194405,3.5183957 5.2460937,3.4277344 5.7430275,3.2289609 6.6111125,3 8,3 Z" />
      <path d="m 4,14 a 1,1 0 0 0 -1,1 v 7 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 V 15 A 1,1 0 0 0 4,14 Z" />
    </SvgIcon>
  );
};

export default FlagIcon;
