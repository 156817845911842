import React from 'react';
import { Company, CompanyStatus } from 'gql/graphql';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ProRequestStatus from 'components/pro-requests/ProRequestStatus';

interface CompaniesTableRowProps {
  company: Company;
}

const CompaniesTableRow = ({ company }: CompaniesTableRowProps) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/companies/${company.id}`);
  };

  const { id, name, status, companyAddresses, companyMembers, creationDate, modificationDate } = company;
  const creationDateMoment = moment(creationDate);
  const modificationDateMoment = moment(modificationDate);
  const address = companyAddresses?.length ? companyAddresses[0].address1 : '-';
  const numberOfMembers = companyMembers?.length;
  const map: (string | number | string[])[] = [
    id,
    name,
    status,
    address,
    numberOfMembers,
    [creationDateMoment.format('l'), creationDateMoment.format('LTS')],
    [modificationDateMoment.format('l'), modificationDateMoment.format('LTS')],
  ];

  return (
    <TableRow onClick={handleNavigate}>
      {map.map((item, idx) => {
        if (Object.values(CompanyStatus).includes(item as CompanyStatus)) {
          return (
            <TableCell align="center" key={idx}>
              <ProRequestStatus status={status} />
            </TableCell>
          );
        }
        if (typeof item === 'string' || typeof item === 'number') {
          return (
            <TableCell align="center" key={item}>
              {item}
            </TableCell>
          );
        }
        return (
          <TableCell align="center" key={idx}>
            {item.map((subItem) => (
              <div key={subItem}>{subItem}</div>
            ))}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default CompaniesTableRow;
