import { graphql } from 'gql';

export const QUERY_ME = graphql(/* GraphQL */ `
  query me {
    me {
      email
      mobile
      name
      profileImg {
        url
      }
    }
  }
`);
