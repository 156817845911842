import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const CalendarIcon = (props: SvgIconProps): JSX.Element => {
  const { fill = '#262627', width = 24, height = 24 } = props;
  return (
    <SvgIcon
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M 5,3 C 3.3549921,3 2,4.3549921 2,6 v 14 c 0,1.645023 1.3550102,3 3,3 h 14 c 1.645005,0 3,-1.354995 3,-3 V 6 C 22,4.3550102 20.645023,3 19,3 Z m 0,2 h 14 c 0.564175,0 1,0.435852 1,1 v 14 c 0,0.564193 -0.435807,1 -1,1 H 5 C 4.435852,21 4,20.564175 4,20 V 6 C 4,5.4358701 4.4358701,5 5,5 Z" />
      <path d="m 16,1 a 1,1 0 0 0 -1,1 v 4 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 V 2 A 1,1 0 0 0 16,1 Z" />
      <path d="M 8,1 A 1,1 0 0 0 7,2 V 6 A 1,1 0 0 0 8,7 1,1 0 0 0 9,6 V 2 A 1,1 0 0 0 8,1 Z" />
      <path d="m 3,9 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 18 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z" />
    </SvgIcon>
  );
};

export default CalendarIcon;
