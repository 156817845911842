import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import DefaultTableHead from 'components/table/DefaultTableHead';
import { requestReportsTableHeadRows, TABLE_MIN_WIDTH } from 'utils/miscellaneous';
import TableFooter from '@mui/material/TableFooter';
import TableBody from '@mui/material/TableBody';
import CollapsiblePaper from 'components/paper/CollapsiblePaper';
import { Request } from 'gql/graphql';
import ReportsTableRow from 'components/reports/ReportsTableRow';
import {
  RequestPopupPayload,
  RequestPopupValues,
  useRequestPopupContext,
} from 'components/requests/[id]/dialogs/RequestPopupContext';
import { useSortParams } from 'hooks/useSortParams';
import DefaultTablePagination from 'components/table/DefaultTablePagination';

interface ProposalsListProps {
  request: Request;
  proposalsPage: number;
  handlePageChange: (event: unknown, newPage: number) => void;
}

const RequestReportsCollapsibleList = ({ request, proposalsPage, handlePageChange }: ProposalsListProps) => {
  const { setCurrentPopup } = useRequestPopupContext();
  const { handleTableFieldChange } = useSortParams();
  const handleSetPopup = (type: RequestPopupValues, payload: RequestPopupPayload) => () => {
    setCurrentPopup(type, payload);
  };

  return (
    <CollapsiblePaper header="신고 목록">
      <TableContainer sx={{ marginTop: 4 }} component={Paper} elevation={0}>
        <Table sx={{ minWidth: TABLE_MIN_WIDTH }}>
          <DefaultTableHead handleTableFieldChange={handleTableFieldChange} headRows={requestReportsTableHeadRows} />
          <TableBody>
            {request.reports.map((report) => (
              <ReportsTableRow
                onClick={handleSetPopup('report', { report, maxWidth: 'md' })}
                key={report.id}
                report={report}
              />
            ))}
          </TableBody>
          <TableFooter>
            <DefaultTablePagination
              count={request.reportTotalCount}
              page={Number(proposalsPage) ?? 0}
              rowsPerPage={5}
              onPageChange={handlePageChange}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </CollapsiblePaper>
  );
};

export default RequestReportsCollapsibleList;
