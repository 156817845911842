import { graphql } from 'gql';

export const PROJECT_INTERESTS_QUERY = graphql(/* GraphQL */ `
  query projectInterestGroups($sortBy: BaseInterestOrderByInput) {
    projectInterestGroups(sortBy: $sortBy) {
      id
      name
      order
      interests(sortBy: { order: ASC }) {
        id
        order
        interestGroupId
        name
      }
    }
  }
`);
