import React, { ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import SearchBarWithFilters from 'components/container/SearchBarWithFilters';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import { CustomSelect } from 'components/select/CustomSelect';
import { SelectChangeEvent } from '@mui/material';
import { UserJoinTypeFilterEnum, userJoinTypeFilterMap, UserProFilterEnum, userProFilterMap } from 'utils/commonMaps';

interface UsersSearchBarProps {
  searchQuery?: string;
  handleSearch?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleUserProFilterChange?: (event: SelectChangeEvent<unknown>) => void;
  handleUserJoinTypeFilterChange?: (event: SelectChangeEvent<unknown>) => void;
}

const classes = {
  select: {
    background: 'transparent',
  },
};

const UsersSearchBar = ({
  searchQuery,
  handleSearch,
  handleUserProFilterChange,
  handleUserJoinTypeFilterChange,
}: UsersSearchBarProps) => {
  return (
    <Box sx={{ mt: 4 }}>
      <SearchBarWithFilters
        handleSearch={handleSearch}
        filters={
          <>
            <CustomSelect
              style={classes.select}
              displayEmpty
              color="secondary"
              onChange={handleUserProFilterChange}
              renderValue={() => <b>사용자 유형</b>}
              IconComponent={KeyboardArrowDownIcon}
            >
              {Object.keys(UserProFilterEnum).map((item) => (
                <MenuItem value={UserProFilterEnum[item as keyof typeof UserProFilterEnum]} key={item}>
                  {userProFilterMap[UserProFilterEnum[item as keyof typeof UserProFilterEnum]]}
                </MenuItem>
              ))}
            </CustomSelect>
            <CustomSelect
              style={classes.select}
              displayEmpty
              color="secondary"
              onChange={handleUserJoinTypeFilterChange}
              renderValue={() => <b>사용자 가입경로</b>}
              IconComponent={KeyboardArrowDownIcon}
            >
              {Object.keys(UserJoinTypeFilterEnum).map((item) => (
                <MenuItem value={UserJoinTypeFilterEnum[item as keyof typeof UserJoinTypeFilterEnum]} key={item}>
                  {userJoinTypeFilterMap[UserJoinTypeFilterEnum[item as keyof typeof UserJoinTypeFilterEnum]]}
                </MenuItem>
              ))}
            </CustomSelect>
          </>
        }
        searchQuery={searchQuery}
      />
    </Box>
  );
};

export default UsersSearchBar;
