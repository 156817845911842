import React, { ReactNode, useMemo } from 'react';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Paper, { PaperProps } from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

interface PaperWithTitleProps extends PaperProps {
  header?: string | ReactNode;
  date?: string;
  disableGutters?: boolean;
  marginTop?: number;
  marginBottom?: number;
  marginRight?: number;
  marginLeft?: number;
}

const PaperWithTitle = ({
  header,
  marginTop = 0,
  marginBottom = 0,
  marginLeft = 0,
  marginRight = 0,
  disableGutters,
  date,
  sx,
  children,
  ...rest
}: PaperWithTitleProps) => {
  const headerElement = useMemo(() => {
    if (typeof header === 'string') {
      return (
        <Typography sx={{ mb: (theme: Theme) => theme.spacing(children ? 2 : 0) }} variant="subtitle1">
          {header}
        </Typography>
      );
    }
    return header;
  }, [header]);
  return (
    <Paper
      sx={[
        (theme: Theme) => ({
          m: theme.spacing(marginTop, marginRight, marginBottom, marginLeft),
          p: theme.spacing(disableGutters ? 0 : 3),
          width: '100%',
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {headerElement && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {headerElement}
          {date && (
            <Typography variant="subtitle1" fontWeight={700}>
              {date}
            </Typography>
          )}
        </Box>
      )}
      {children}
    </Paper>
  );
};

export default PaperWithTitle;
