import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AmplifierIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M18.9323 1.69474C18.5887 0.530753 17.2142 0.414289 16.4124 0.996307L1.13268 11.8455C0.0328383 12.6366 -0.196238 13.2186 0.147429 14.2663L0.806715 16.2565L1.52193 18.4568C1.9801 19.6208 2.43827 19.6208 3.72899 19.5169L5.18155 19.4413C5.16253 19.9072 5.23045 20.3726 5.3817 20.8129C5.68507 21.696 6.3001 22.4334 7.10772 22.8822C7.91533 23.3311 8.8581 23.4594 9.75348 23.2424C10.6489 23.0254 11.4332 22.4785 11.9546 21.7076C12.476 20.9367 12.6973 19.9966 12.5759 19.0694L22.3685 18.5732C23.6285 18.5732 24.3158 17.5255 23.8576 16.1287L18.9323 1.69474ZM9.49091 21.3818C9.24197 21.4687 9.09908 21.5075 8.83636 21.4909C8.57365 21.4743 8.37667 21.424 8.07273 21.2727C7.83634 21.155 7.70124 21.0371 7.52727 20.8364C7.35331 20.6356 7.28499 20.5441 7.2 20.2909C7.10293 20.0075 7.05402 19.6068 7.09091 19.3091L10.6909 19.2C10.7962 19.6874 10.7131 20.1831 10.4727 20.6182C10.2323 21.0532 9.95518 21.2195 9.49091 21.3818ZM21.8182 16.5818L3.27273 17.6727L1.96364 13.6364L17.2364 2.94545L21.8182 16.5818Z" />
    </SvgIcon>
  );
};

export default AmplifierIcon;
