import React from 'react';
import { ProjectVote } from 'gql/graphql';
import moment from 'moment/moment';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

interface ProjectVoteTableRowProps {
  vote: ProjectVote;
  onClick?: () => void;
}

const ProjectVoteTableRow = ({ vote, onClick }: ProjectVoteTableRowProps) => {
  const { id, user, creationDate } = vote;
  const creationDateMoment = moment(creationDate);

  const map: (string | string[])[] = [
    String(id),
    user?.name ?? '-',
    [creationDateMoment.format('l'), creationDateMoment.format('LTS')],
  ];

  return (
    <TableRow onClick={onClick ? onClick : null}>
      {map.map((item, idx) => {
        if (typeof item === 'string') {
          return (
            <TableCell align="center" key={item}>
              {item}
            </TableCell>
          );
        }
        return (
          <TableCell align="center" key={idx}>
            {item.map((subItem) => (
              <div key={subItem}>{subItem}</div>
            ))}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default ProjectVoteTableRow;
