import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const DeleteUserIcon = (props: SvgIconProps): JSX.Element => {
  const { fill = '#262627', width = 24, height = 24 } = props;
  return (
    <SvgIcon {...props} width={width} height={height} viewBox="0 0 24 24" fill={fill} version="1.1">
      <path d="m 5,14 c -2.7495889,0 -5,2.250453 -5,5 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 c 0,-1.668649 1.3313133,-3 3,-3 h 7 c 1.668637,0 3,1.331363 3,3 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 c 0,-2.749559 -2.250441,-5 -5,-5 z" />
      <path d="m 8.5,2 c -2.7495768,0 -5,2.2504232 -5,5 0,2.7495768 2.2504232,5 5,5 2.749547,0 5,-2.2504111 5,-5 0,-2.7495889 -2.250453,-5 -5,-5 z m 0,2 c 1.668649,0 3,1.3313133 3,3 0,1.6686867 -1.331351,3 -3,3 -1.6686988,0 -3,-1.3313012 -3,-3 0,-1.6686988 1.3313012,-3 3,-3 z" />
      <path d="m 17.292969,7.2929687 a 1,1 0 0 0 0,1.4140626 l 5,4.9999997 a 1,1 0 0 0 1.414062,0 1,1 0 0 0 0,-1.414062 l -5,-5.0000003 a 1,1 0 0 0 -1.414062,0 z" />
      <path d="m 22.292969,7.2929687 -5,5.0000003 a 1,1 0 0 0 0,1.414062 1,1 0 0 0 1.414062,0 l 5,-4.9999997 a 1,1 0 0 0 0,-1.4140626 1,1 0 0 0 -1.414062,0 z" />
    </SvgIcon>
  );
};

export default DeleteUserIcon;
