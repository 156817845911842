import React from 'react';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AvatarIcon from 'components/icons/AvatarIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import { CompanyMemberStatus, User } from 'gql/graphql';

interface UserInformationProps {
  user: User;
}

const classes = {
  icon: {
    width: (theme) => theme.spacing(8),
    height: (theme) => theme.spacing(8),
  },
  pro: {
    color: 'success.main',
  },
};

const UserProfile = ({ user }: UserInformationProps) => {
  const isPro = user?.activeCompanyMember?.status === CompanyMemberStatus.Approved;
  return (
    <ListItem disablePadding>
      <ListItemAvatar>
        {user?.profileImg?.url ? (
          <Avatar alt="profileImg" src={user?.profileImg.url} sx={classes.icon} />
        ) : (
          <AvatarIcon sx={classes.icon} />
        )}
      </ListItemAvatar>
      <ListItemText
        sx={{ ml: (theme) => theme.spacing(2) }}
        primaryTypographyProps={{ fontSize: '1.125rem' }}
        primary={user?.name}
        secondaryTypographyProps={{ color: 'success.main' }}
        secondary={isPro && `PRO・@${user?.activeCompany?.name}`}
      />
      ID: {user?.id}
    </ListItem>
  );
};

export default UserProfile;
