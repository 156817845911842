import React from 'react';
import { CompanyMemberStatus, CompanyStatus, UserJoinType, UserListQueryQuery } from 'gql/graphql';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import moment from 'moment/moment';
import CompanyMemberRole from 'components/users/CompanyMemberRole';
import { useNavigate } from 'react-router-dom';
import { UnEnumerate } from 'types';
import UserJoinTypeComponent from './UserJoinType';

type UserRow = UnEnumerate<UserListQueryQuery['items']>;

interface UsersTableRowProps {
  user: UserRow;
}

const UsersTableRow = ({ user }: UsersTableRowProps) => {
  const { id, name, email, companyMember, company, joinDate, joinType, lastLoginDate } = user;
  const joinDateMoment = moment(joinDate);
  const lastLoginDateMoment = moment(lastLoginDate);

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/users/${id}`);
  };

  const map: (string | string[])[] = [
    String(id),
    name ?? '-',
    email ?? '-',
    joinType,
    companyMember?.status === CompanyMemberStatus.Approved && company?.status === CompanyStatus.Approved
      ? CompanyMemberStatus.Approved
      : CompanyMemberStatus.Rejected,
    company?.name,
    [joinDateMoment.format('l'), joinDateMoment.format('LTS')],
    [lastLoginDateMoment.format('l'), lastLoginDateMoment.format('LTS')],
  ];

  return (
    <TableRow onClick={handleNavigate}>
      {map?.map((item, idx) => {
        if (Object.values(UserJoinType).includes(item as UserJoinType)) {
          return (
            <TableCell align="center" key={idx}>
              <UserJoinTypeComponent joinType={item as UserJoinType} />
            </TableCell>
          );
        }
        if (Object.values(CompanyMemberStatus).includes(item as CompanyMemberStatus)) {
          return (
            <TableCell align="center" key={idx}>
              <CompanyMemberRole isPro={item === CompanyMemberStatus.Approved} />
            </TableCell>
          );
        }
        if (typeof item === 'string') {
          return (
            <TableCell align="center" key={idx}>
              {item}
            </TableCell>
          );
        }
        return (
          <TableCell align="center" key={idx}>
            {item?.map((subItem, i) => (
              <div key={i}>{subItem}</div>
            ))}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default UsersTableRow;
