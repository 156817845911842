import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import moment from 'moment/moment';
import Divider from '@mui/material/Divider';
import ReportStatus from 'components/reports/ReportStatus';
import UserProfile from 'components/users/UserProfile';
import { useProjectPopupContext } from 'components/projects/[id]/dialogs/ProjectPopupContext';
import { projectReportReasonMap } from 'utils/commonMaps';

const classes = {
  caption: {
    color: '#616161',
  },
};

const ProjectReportPopup = () => {
  const { payload } = useProjectPopupContext();
  const { id, user, creationDate, isPositiveReport, reportReason } = payload.report;
  const applicationDateMoment = moment(creationDate).format('YYYY.MM.DD LTS');

  return (
    <Grid p={4} container spacing={4}>
      <Grid display="flex" alignItems="center" justifyContent="space-between" item xs={12}>
        <Typography variant="h6">
          <b>신고 ID: {id}</b>
        </Typography>
        <ReportStatus size="large" status={isPositiveReport} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" sx={classes.caption}>
          신고 일자: {applicationDateMoment}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography mb={(theme) => theme.spacing(2)}>
          <b>신고자</b>
        </Typography>
        <UserProfile user={user} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography mb={(theme) => theme.spacing(2)}>
          <b>신고 내용</b>
        </Typography>
        <Typography>{projectReportReasonMap[reportReason]}</Typography>
      </Grid>
    </Grid>
  );
};

export default ProjectReportPopup;
