import React, { useContext, useEffect } from 'react';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'context/authContext';
import { ApolloError } from '@apollo/client';

interface PasswordEmailProps {
  currentPath: string;
  query: URLSearchParams;
}

interface FormType {
  username: string;
  password: string;
}

const PasswordUsername = (props: PasswordEmailProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormType>();
  const { query } = props;
  const navigate = useNavigate();
  const { promiseInProgress } = usePromiseTracker({ area: 'login' });
  const { login } = useContext(AuthContext);

  useEffect(() => {
    if (!query.get('username')) {
      navigate('/login/signin');
    }
  }, [navigate, query]);

  const submit = (form: FormType) => {
    trackPromise(
      login({ username: query.get('username'), password: form.password })
        .then(() => {
          navigate('/');
        })
        .catch((reason: ApolloError) => {
          setError('password', { message: reason.message });
        }),
      'login',
    );
  };

  const handleEmailClick = () => {
    return navigate({ pathname: '/login/signin', ...query });
  };

  const handleFindPasswordButtonClick = () => {
    return navigate({ pathname: '/login/signin/challenge', ...query });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid container spacing={4} justifyContent="flex-end" sx={{ marginBottom: (theme) => theme.spacing(23) }}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h1">
            <b>로그인</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                onClick={handleEmailClick}
                inputProps={{
                  sx: {
                    textAlign: 'center',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    backgroundColor: (theme) => theme.palette.background.default,
                    color: (theme) => theme.palette.text.primary,
                  },
                }}
                value={query.get('username')}
                disabled
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="medium"
                fullWidth
                variant="outlined"
                label="비밀번호"
                color="secondary"
                {...register('password', { required: '비밀번호를 입력하세요.' })}
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
                type="password"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: (theme) => theme.spacing(0.5) }}>
              <Typography>비밀번호를 잊으셨나요?</Typography>
              <Link
                component="button"
                type="button"
                href="/#"
                color="secondary"
                onClick={handleFindPasswordButtonClick}
              >
                <Typography style={{ fontWeight: 'bold' }}>비밀번호 다시 설정</Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={4} xs={12}>
          <LoadingButton loading={promiseInProgress} variant="contained" color="inherit" fullWidth type="submit">
            로그인
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default PasswordUsername;
