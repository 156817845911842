import { createPopupContext, Payload, Return } from 'components/popup/popupProvider';
import { useContext } from 'react';
import { Proposal, RequestReport } from 'gql/graphql';

export type RequestPopupValues = 'proposal' | 'report';

export interface RequestPopupPayload extends Payload {
  proposal?: Proposal;
  report?: RequestReport;
}

export const requestPopupContext = createPopupContext<RequestPopupValues, RequestPopupPayload>();
export const useRequestPopupContext = (): Return<RequestPopupValues, RequestPopupPayload> =>
  useContext(requestPopupContext) as unknown as Return<RequestPopupValues, RequestPopupPayload>;
