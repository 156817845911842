import React from 'react';
import { DefaultPageProps } from 'components/interfaces';
import MainLayout from 'components/layouts/MainLayout';
import Container from 'components/container';
import Grid from '@mui/material/Grid';
import PaperWithTitle from 'components/paper/PaperWithTitle';
import ProposalDetails from 'components/proposals/[id]/ProposalDetails';
import { useQuery } from '@apollo/client';
import { graphql } from 'gql';
import { useParams } from 'react-router-dom';
import { Proposal, QueryGetProposalArgs } from 'gql/graphql';
import RequestInfo from 'components/requests/[id]/RequestInfo';
import FormHelperText from '@mui/material/FormHelperText';
import LoadingBox from 'components/loading/LoadingBox';

const PROPOSAL_QUERY = graphql(/* GraphQL */ `
  query getProposal($id: Int) {
    getProposal(id: $id) {
      id
      status
      creationDate
      distance
      description
      request {
        id
        address {
          address1
          latitude
          longitude
        }
        assignedUser {
          id
          activeCompanyMember {
            status
          }
          activeCompany(extendedCompanyStatus: true, extendedCompanyMemberStatus: true) {
            name
          }
          name
          profileImg {
            url
            id
            pic_name
          }
        }
        status
        pictures {
          url
          pic_name
        }
        title
        description
        proposalTotalCount
        viewCount
        startDate
        endDate
        possibleDateDiscussion
      }
      pictures {
        url
        pic_name
      }
      assignedUser {
        id
        name
        profileImg {
          id
          url
          pic_name
        }
        activeCompany(extendedCompanyStatus: true, extendedCompanyMemberStatus: true) {
          name
        }
        activeCompanyMember {
          status
        }
      }
    }
  }
`);

const ProposalPage = ({ name }: DefaultPageProps) => {
  const params = useParams();
  const { data, loading, error } = useQuery<{ getProposal: Proposal }, QueryGetProposalArgs>(PROPOSAL_QUERY, {
    variables: {
      id: Number(params.id),
    },
    skip: !Number(params.id),
  });

  return (
    <MainLayout title={name}>
      <Container title={name}>
        <Grid container spacing={2}>
          {error && (
            <Grid item xs={12}>
              <FormHelperText error>{error.message}</FormHelperText>
            </Grid>
          )}
          {loading && <LoadingBox />}
          {data && !error && !loading && (
            <>
              <Grid item xs={12}>
                <PaperWithTitle variant="outlined">
                  <ProposalDetails proposal={data.getProposal} />
                </PaperWithTitle>
              </Grid>
              <Grid item xs={12}>
                <RequestInfo request={data.getProposal.request} />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </MainLayout>
  );
};

export default ProposalPage;
