import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DeleteIcon from 'components/icons/DeleteIcon';
import { useRequestTagSettingsPopupContext } from 'components/request-tag-settings/dialogs/RequestTagSettingsContext';
import { ApolloError, useMutation } from '@apollo/client';
import { graphql } from 'gql';
import { CompanyInterestGroup, MutationDeleteCompanyInterestGroupArgs, SortBy } from 'gql/graphql';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import { COMPANY_INTERESTS_QUERY } from 'components/request-tag-settings/Queries';

const DELETE_MUTATION = graphql(/* GraphQL */ `
  mutation deleteCompanyInterestGroup($id: Int!) {
    deleteCompanyInterestGroup(id: $id) {
      id
    }
  }
`);

const classes = {
  background: {
    width: 64,
    height: 64,
    background: '#F7F6F2',
    borderRadius: '50%',
  },
};

const DeleteRequestTagGroupPopup = () => {
  const { setCurrentPopup, payload } = useRequestTagSettingsPopupContext();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteGroup, { loading }] = useMutation<
    { deleteCompanyInterestGroup: CompanyInterestGroup },
    MutationDeleteCompanyInterestGroupArgs
  >(DELETE_MUTATION);

  const { handleSubmit } = useForm<MutationDeleteCompanyInterestGroupArgs>({
    defaultValues: {
      id: payload.tagGroupId,
    },
  });
  const handlePopupClose = () => {
    setCurrentPopup(undefined);
  };

  const submit = (form: MutationDeleteCompanyInterestGroupArgs) => {
    deleteGroup({
      variables: form,
      refetchQueries: [
        {
          query: COMPANY_INTERESTS_QUERY,
          variables: {
            sortBy: {
              order: SortBy.Asc,
            },
          },
        },
      ],
    })
      .then(() => {
        handlePopupClose();
        enqueueSnackbar('수정내용이 저장되었습니다.', { variant: 'success' });
      })
      .catch((reason: ApolloError) => {
        enqueueSnackbar(reason.message, { variant: 'error' });
      });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid p={5} container spacing={3}>
        <Grid display="flex" justifyContent="center" item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="center" sx={classes.background}>
            <DeleteIcon sx={{ color: '#323F4B', height: 32, width: 32 }} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="h6">
            <b>태그 그룹 삭제</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center">태그 그룹을 삭제하시겠습니까?</Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="column" spacing={1}>
            <LoadingButton loading={loading} type="submit" variant="outlined" color="error">
              삭제
            </LoadingButton>
            <Button onClick={handlePopupClose} color="secondary">
              취소
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default DeleteRequestTagGroupPopup;
