import React from 'react';
import { DefaultPageProps } from 'components/interfaces';
import MainLayout from 'components/layouts/MainLayout';
import Container from 'components/container';
import { useSortParams } from 'hooks/useSortParams';
import { useQuery } from '@apollo/client';
import { PROJECT_ADMIN_REPORTS_QUERY } from 'components/flagged-projects/Queries';
import {
  AdminReportStatusEnum,
  ListMetadata,
  OrderBy,
  ProjectAdminReport,
  QueryAllAdminReportsArgs,
  ReportTypeEnum,
} from 'gql/graphql';
import DefaultTable from 'components/table/DefaultTable';
import { flaggedProjectsTableHeadRows } from 'utils/miscellaneous';
import FlaggedProjectsTableRow from 'components/flagged-projects/FlaggedProjectsTableRow';
import FlaggedProjectsSearchBar from 'components/flagged-projects/FlaggedRequestsSearchBar';

const FlaggedProjectsPage = ({ name }: DefaultPageProps) => {
  const { searchParams, handleFieldChange } = useSortParams();
  const { page, status, sortField, sortOrder, q } = searchParams;

  const result = useQuery<{ allAdminReports: ProjectAdminReport[]; total: ListMetadata }, QueryAllAdminReportsArgs>(
    PROJECT_ADMIN_REPORTS_QUERY,
    {
      variables: {
        page: Number(page ?? 0),
        perPage: 10,
        sortOrder: (sortOrder as OrderBy) ?? OrderBy.Desc,
        sortField: sortField ?? 'creationDate',
        filter: {
          type: ReportTypeEnum.Project,
          q: q || undefined,
          status: status ? (status?.split(',') as AdminReportStatusEnum[]) : undefined,
        },
      },
      errorPolicy: 'all',
    },
  );

  const { data, loading, error } = result;

  return (
    <MainLayout title={name}>
      <Container
        title={name}
        searchBar={<FlaggedProjectsSearchBar handleSearch={handleFieldChange('q')} searchQuery={q} />}
      >
        <DefaultTable result={result} rowDefinition={flaggedProjectsTableHeadRows}>
          {data &&
            !loading &&
            !error &&
            data?.allAdminReports.map((report) => <FlaggedProjectsTableRow key={report.id} report={report} />)}
        </DefaultTable>
      </Container>
    </MainLayout>
  );
};

export default FlaggedProjectsPage;
