import { LongDateFormatSpec } from 'moment';

export const longDateFormat: LongDateFormatSpec = {
  LTS: 'A hh:mm:ss',
  L: 'YYYY-MM-DD',
  l: 'YYYY.MM.DD.',
  LT: 'hh:mm',
  LL: 'DD MMMM YYYY',
  LLL: 'DD MMMM YYYY LT',
  LLLL: 'dddd, DD MMMM YYYY LT',
};
