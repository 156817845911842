import { InputBaseProps, Theme } from '@mui/material';
import React, { ChangeEvent } from 'react';
import SearchBar from 'components/container/SearchBar';
import Grid from '@mui/material/Grid';

interface SearchBarWithFiltersProps {
  inputBaseProps?: InputBaseProps;
  handleSearch?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSearchClick?: () => void;
  searchQuery?: string;
  name?: string;
  filters?: React.ReactNode;
}

const classes = {
  searchBar: (theme: Theme) => ({
    width: 480,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  }),
};

const SearchBarWithFilters = ({ searchQuery, handleSearch, handleSearchClick, filters }: SearchBarWithFiltersProps) => {
  return (
    <Grid container display="flex" flexDirection="row" alignItems="center" spacing={1}>
      <Grid item>
        <SearchBar
          searchQuery={searchQuery}
          onSearch={handleSearch}
          handleSearchClick={handleSearchClick}
          sx={classes.searchBar}
          placeholder="검색하기"
        />
      </Grid>
      <Grid item>{filters}</Grid>
    </Grid>
  );
};

export default SearchBarWithFilters;
