import React, { ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import SearchBarWithFilters from 'components/container/SearchBarWithFilters';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent, Typography } from '@mui/material';
import { CustomSelect } from 'components/select/CustomSelect';
import Checkbox from '@mui/material/Checkbox';
import { AdminReportStatusEnum } from 'gql/graphql';
import { useSortParams } from 'hooks/useSortParams';
import { flaggedRequestFilterMap } from 'utils/commonMaps';

interface FlaggedRequestsSearchBarProps {
  searchQuery?: string;
  handleSearch?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleFilterChange?: (event: SelectChangeEvent<unknown>) => void;
}

const classes = {
  select: {
    background: 'transparent',
  },
};

const returnArray = (values: string | undefined) => {
  if (!values) {
    return [];
  }
  return values.split(',');
};

const FlaggedRequestsSearchBar = ({ searchQuery, handleSearch, handleFilterChange }: FlaggedRequestsSearchBarProps) => {
  const { searchParams } = useSortParams();
  const { status } = searchParams;

  return (
    <Box sx={{ mt: 4 }}>
      <SearchBarWithFilters
        handleSearch={handleSearch}
        filters={
          <CustomSelect
            style={classes.select}
            displayEmpty
            multiple
            value={returnArray(status)}
            color="secondary"
            onChange={handleFilterChange}
            renderValue={() => <b>상태</b>}
            IconComponent={KeyboardArrowDownIcon}
          >
            <MenuItem
              disabled={returnArray(status).length === 4}
              value={Object.values(AdminReportStatusEnum).filter((x) => !returnArray(status).includes(x))}
            >
              <Typography flexGrow={1} textAlign="center">
                <b>모두보기</b>
              </Typography>
            </MenuItem>
            {Object.keys(AdminReportStatusEnum).map((item) => (
              <MenuItem value={AdminReportStatusEnum[item as keyof typeof AdminReportStatusEnum]} key={item}>
                <Checkbox
                  color="secondary"
                  value=""
                  checked={returnArray(status).indexOf(AdminReportStatusEnum[item]) > -1}
                />
                <Typography>
                  {flaggedRequestFilterMap[AdminReportStatusEnum[item as keyof typeof AdminReportStatusEnum]]}
                </Typography>
              </MenuItem>
            ))}
          </CustomSelect>
        }
        searchQuery={searchQuery}
      />
    </Box>
  );
};

export default FlaggedRequestsSearchBar;
