import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ProjectHistory } from 'gql/graphql';
import moment from 'moment';
import Divider from '@mui/material/Divider';
import DOMPurify from 'isomorphic-dompurify';
import UserProfile from 'components/users/UserProfile';
import Stack from '@mui/material/Stack';
import ImageItem from 'components/image/ImageItem';

interface RequestHistoryProps {
  projectTitle?: string;
  projectHistory: ProjectHistory;
}

const classes = {
  caption: {
    color: '#616161',
    fontSize: '0.75rem',
  },
  map: {
    borderRadius: 16,
    width: '100%',
    height: '285px',
  },
};

const ProjectHistoryInfo = ({ projectTitle = '신고 당시 프로젝트 게시물', projectHistory }: RequestHistoryProps) => {
  const { id, createdByUser, pictures, title, creationDate, description } = projectHistory;

  const creationDateMoment = moment(creationDate).format('LTS');

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">
          <b>{projectTitle}</b>
        </Typography>
        <Typography sx={classes.caption}>신고 일자: {creationDateMoment}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <b>프로젝트 게시물 ID: {id}</b>
        </Typography>
      </Grid>
      {pictures.length > 0 && (
        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            {pictures.map((picture) => (
              <ImageItem size="large" key={picture.id} src={picture.url} alt={picture.pic_name} />
            ))}
          </Stack>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="h6">
          <b>{title}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <UserProfile user={createdByUser} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
      </Grid>
    </Grid>
  );
};

export default ProjectHistoryInfo;
