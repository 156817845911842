import React from 'react';
import { DefaultPageProps } from 'components/interfaces';
import MainLayout from 'components/layouts/MainLayout';
import Container from 'components/container';
import { usersTableHeadRows } from 'utils/miscellaneous';
import { useQuery } from '@apollo/client';
import { OrderBy } from 'gql/graphql';
import UsersTableRow from 'components/users/UsersTableRow';
import UsersSearchBar from 'components/users/UsersSearchBar';
import { useSortParams } from 'hooks/useSortParams';
import {
  UserJoinTypeFilterEnum,
  userJoinTypeFilterValueMap,
  UserProFilterEnum,
  userProFilterValueMap,
} from 'utils/commonMaps';
import DefaultTable from 'components/table/DefaultTable';
import { USER_LIST_QUERY } from 'components/users/Queries';

const UsersPage = ({ name }: DefaultPageProps) => {
  const { handleFieldChange, searchParams } = useSortParams();
  const { page, q, sortField, role, joinType, sortOrder } = searchParams;

  const result = useQuery(USER_LIST_QUERY, {
    variables: {
      page: Number(page ?? 0),
      perPage: 10,
      sortOrder: (sortOrder as OrderBy) ?? OrderBy.Desc,
      filter: {
        q: q || undefined,
        ...userProFilterValueMap[(role as keyof typeof UserProFilterEnum) || UserProFilterEnum.All],
        ...userJoinTypeFilterValueMap[(joinType as keyof typeof UserJoinTypeFilterEnum) || UserJoinTypeFilterEnum.ALL],
      },
      sortField: sortField ?? 'joinDate',
    },
    errorPolicy: 'all',
  });

  const { data, loading, error } = result;

  return (
    <MainLayout title={name}>
      <Container
        title={name}
        searchBar={
          <UsersSearchBar
            handleUserProFilterChange={handleFieldChange('role')}
            handleUserJoinTypeFilterChange={handleFieldChange('joinType')}
            handleSearch={handleFieldChange('q')}
            searchQuery={q}
          />
        }
      >
        <DefaultTable result={result} rowDefinition={usersTableHeadRows}>
          {data &&
            !error &&
            !loading &&
            data?.items?.map((item) => {
              if (!item) return;
              return <UsersTableRow key={item.id} user={item} />;
            })}
        </DefaultTable>
      </Container>
    </MainLayout>
  );
};

export default UsersPage;
