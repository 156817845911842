import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FieldValues, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { graphql } from 'gql';
import { PinCodeType } from 'gql/graphql';
interface PasswordResetProps {
  currentPath: string;
  query: URLSearchParams;
}

const ASK_PIN_CODE_RESET_PASSWORD = graphql(/* GraphQL */ `
  mutation AskPinCodeResetPassword($mobile: String!, $type: PinCodeType!) {
    askPinCode(mobile: $mobile, type: $type, accountType: ADMIN) {
      expirationTime
      email
    }
  }
`);

const RESET_PASSWORD = graphql(/* GraphQL */ `
  mutation ResetPassword($mobile: String!, $password: String!, $confirmPassword: String!) {
    resetPassword(mobile: $mobile, password: $password, confirmPassword: $confirmPassword) {
      message
    }
  }
`);

const PasswordReset = (props: PasswordResetProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { query } = props;
  const navigate = useNavigate();
  const [sendPinCode, { loading }] = useMutation(ASK_PIN_CODE_RESET_PASSWORD);
  const [resetPassword, { loading: loadingPassword }] = useMutation(RESET_PASSWORD);
  const submitPincode = (form: FieldValues) => {
    sendPinCode({ variables: { mobile: form.mobile, type: PinCodeType.ResetPassword } })
      .then(() => navigate({ pathname: '/login/pincode' }))
      .catch((reason) => setError('mobile', { type: 'manual', message: reason.message }));
  };

  const submitPassword = (form: FieldValues) => {
    resetPassword({
      variables: { mobile: query.get('mobile'), confirmPassword: form.confirmPassword, password: form.password },
    })
      .then(() => navigate({ pathname: '/login/signin', ...query }))
      .catch((reason) => setError('password', { type: 'manual', message: reason.message }));
  };

  if (query.get('reset')) {
    return (
      <form onSubmit={handleSubmit(submitPassword)}>
        <Grid container spacing={4} justifyContent="flex-end" sx={{ marginBottom: (theme) => theme.spacing(14) }}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h1">
              <b>새 비밀번호 설정</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>새로운 비밀번호를 설정하세요.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  size="medium"
                  fullWidth
                  variant="outlined"
                  disabled
                  value={query.get('email')}
                  inputProps={{
                    sx: {
                      textAlign: 'center',
                      fontWeight: 'bold',
                      backgroundColor: (theme) => theme.palette.background.default,
                      color: (theme) => theme.palette.text.primary,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="medium"
                  fullWidth
                  variant="outlined"
                  label="새 비밀번호"
                  color="secondary"
                  {...register('password', {
                    required: '비밀번호를 입력해 주세요.',
                    pattern: {
                      value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                      message: '영문, 숫자, 특수문자를 포함한 8자 이상',
                    },
                  })}
                  error={Boolean(errors.password)}
                  helperText={errors.password?.message}
                  type="password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="medium"
                  fullWidth
                  variant="outlined"
                  label="새 비밀번호 확인"
                  color="secondary"
                  {...register('confirmPassword', {
                    required: '비밀번호를 입력해 주세요.',
                    validate: (value) => (value === getValues('password') ? true : '비밀번호가 일치하지 않습니다.'),
                  })}
                  error={Boolean(errors.confirmPassword)}
                  helperText={errors.confirmPassword?.message}
                  type="password"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button disabled={loadingPassword} fullWidth variant="contained" color="secondary" type="submit">
              새 비밀번호 설정
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }

  return (
    <form onSubmit={handleSubmit(submitPincode)}>
      <Grid container spacing={4} justifyContent="flex-end" sx={{ marginBottom: (theme) => theme.spacing(18) }}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h1">
            <b>비밀번호 다시 설정</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>비밀번호를 다시 설정하기 위해 가입시 사용한 휴대폰 번호를 입력해 주세요.</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="medium"
                fullWidth
                variant="outlined"
                label="휴대폰 번호"
                color="secondary"
                {...register('mobile', {
                  required: '휴대폰 번호를 입력해 주세요.',
                  pattern: { message: '휴대폰 번호를 다시 확인하고 입력해주세요.', value: /^[0-9]{11}$/g },
                })}
                error={Boolean(errors.mobile)}
                helperText={errors.mobile?.message}
                type="tel"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Button disabled={loading} variant="contained" color="secondary" fullWidth type="submit">
            계속
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PasswordReset;
