import { graphql } from 'gql';

export const PROJECTS_QUERY = graphql(/* GraphQL */ `
  query allProjects($filter: ProjectFilter, $page: Int, $perPage: Int, $sortField: String, $sortOrder: OrderBy) {
    allProjects(filter: $filter, page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder) {
      id
      voteCount
      createdByUser {
        name
        id
      }
      status
      modificationDate
      creationDate
      description
    }
    total: _allProjectsMeta(
      filter: $filter
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      count
    }
  }
`);
