import React from 'react';
import { SnackbarProvider } from 'notistack';
import { styled } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
  '&.SnackbarContent-root': {
    borderRadius: 8,
  },
  '&.SnackbarItem-variantSuccess': {
    background: theme.palette.success.light,
    color: theme.palette.success.main,
  },
  '&.SnackbarItem-variantError': {
    background: theme.palette.error.light,
  },
  '&.SnackbarItem-variantWarning': {
    background: theme.palette.warning.light,
  },
}));

const CustomSnackbarProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <StyledSnackbarProvider
      iconVariant={{ success: <CheckCircleOutlineOutlinedIcon sx={{ mr: 1 }} /> }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      {children}
    </StyledSnackbarProvider>
  );
};

export default CustomSnackbarProvider;
