import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const UserActiveIcon = (props: SvgIconProps): JSX.Element => {
  const { fill = '#262627', width = 24, height = 24 } = props;
  return (
    <SvgIcon fill={fill} width={width} height={height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m 5,14 c -2.7495889,0 -5,2.250453 -5,5 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 c 0,-1.668649 1.3313133,-3 3,-3 h 7 c 1.668637,0 3,1.331363 3,3 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 c 0,-2.749559 -2.250441,-5 -5,-5 z" />
      <path d="m 8.5,2 c -2.7495768,0 -5,2.2504232 -5,5 0,2.7495768 2.2504232,5 5,5 2.749547,0 5,-2.2504111 5,-5 0,-2.7495889 -2.250453,-5 -5,-5 z m 0,2 c 1.668649,0 3,1.3313133 3,3 0,1.6686867 -1.331351,3 -3,3 -1.6686988,0 -3,-1.3313012 -3,-3 0,-1.6686988 1.3313012,-3 3,-3 z" />
      <path d="M 22.292969,8.2929688 19,11.585938 17.707031,10.292969 a 1,1 0 0 0 -1.414062,0 1,1 0 0 0 0,1.414062 l 2,2 a 1.0001,1.0001 0 0 0 1.414062,0 l 4,-3.9999997 a 1,1 0 0 0 0,-1.4140625 1,1 0 0 0 -1.414062,0 z" />
    </SvgIcon>
  );
};

export default UserActiveIcon;
