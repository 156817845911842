import React from 'react';
import { SvgIconProps } from '@mui/material';

const LogoIcon = (props: SvgIconProps): JSX.Element => {
  // We do not use MUI svg because it's not an icon.
  return (
    <svg width={40} height={24} viewBox="0 0 40 24" fill="#ff5555" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 2.23293e-7,5.1048 0,23.8298 H 40 V 5.1048 C 37.2141,5.03087 34.9693,2.78594 34.8955,-1.29353e-5 l -9.9329,-1.185e-7 C 24.8876,2.83307 22.5674,5.10668 19.7162,5.10668 c -2.8511,0 -5.1713,-2.27361 -5.2463,-5.1066931789 l -9.08167,-1.083e-7 C 5.31323,2.83307 2.99307,5.10668 0.141878,5.10668 0.094436,5.10668 0.0471406,5.10605 2.23293e-7,5.1048 Z M 15.0591,12.5946 c 0,-2.91486 -2.3629,-5.2778 -5.27777,-5.2778 -2.91483,0 -5.27777,2.36294 -5.27777,5.2778 0,2.9148 2.36294,5.2778 5.27777,5.2778 2.91487,0 5.27777,-2.363 5.27777,-5.2778 z M 30.2127,7.37591 c 2.8985,0 5.2483,2.34971 5.2483,5.24819 0,2.8985 -2.3498,5.2483 -5.2483,5.2483 -2.8985,0 -5.2482,-2.3498 -5.2482,-5.2483 0,-2.89848 2.3497,-5.24819 5.2482,-5.24819 z"
      />
    </svg>
  );
};

export default LogoIcon;
