import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from 'components/table/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import { OrderBy, SortDirection } from 'gql/graphql';
import { useSortParams } from 'hooks/useSortParams';
import { IDefaultTableHeadRows } from 'utils/miscellaneous';
import { Theme } from '@mui/material';

interface TableHeadProps {
  headRows: IDefaultTableHeadRows[];
  handleTableFieldChange: (fields: string[], values: string[]) => void;
}

const classes = {
  tableRowHead: {
    height: '64px',
    background: (theme: Theme) => theme.palette.secondary.light,
  },
};

const sortMap: { [key in SortDirection]: string } = {
  ASC: 'asc',
  DESC: 'desc',
};

const DefaultTableHead = ({ headRows, handleTableFieldChange }: TableHeadProps) => {
  const { searchParams } = useSortParams();

  const handleFieldChange = (field: string) => () => {
    const fields = ['sortOrder', 'sortField'];
    const values = [searchParams?.sortOrder === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc, field];
    handleTableFieldChange(fields, values);
  };

  const sortDirection = (field: string) => {
    if (searchParams?.sortField === field && searchParams?.sortOrder) {
      return sortMap[searchParams?.sortOrder];
    }
    return 'desc';
  };

  return (
    <TableHead>
      <TableRow sx={classes.tableRowHead}>
        {headRows.map((item) => (
          <TableCell align="center" key={item.field}>
            <b>{item.name}</b>
            {item?.sortable && (
              <TableSortLabel
                onClick={handleFieldChange(item.field)}
                direction={sortDirection(item.field)}
                active={searchParams?.sortField === item.field}
              />
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default DefaultTableHead;
