import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EditSquareIcon = (props: SvgIconProps): JSX.Element => {
  const { fill = '#262627', width = 24, height = 24 } = props;
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      version="1.1"
      id="svg58"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M 5,2 C 3.3549921,2 2,3.3549921 2,5 v 14 c 0,1.645023 1.3550102,3 3,3 h 14 c 1.645005,0 3,-1.354995 3,-3 v -5.339844 a 1,1 0 0 0 -1,-1 1,1 0 0 0 -1,1 V 19 c 0,0.564193 -0.435807,1 -1,1 H 5 C 4.435852,20 4,19.564175 4,19 V 5 C 4,4.4358701 4.4358701,4 5,4 h 5.339844 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z" />
      <path d="M 17.292969,1.2929687 7.2929687,11.292969 A 1.0001,1.0001 0 0 0 7,12 v 4 a 1.0001,1.0001 0 0 0 1,1 h 4 a 1.0001,1.0001 0 0 0 0.707031,-0.292969 l 10,-9.9999998 a 1.0001,1.0001 0 0 0 0,-1.4140625 l -4,-4 a 1.0001,1.0001 0 0 0 -1.414062,0 z M 18,3.4140625 20.585937,6 11.585938,15 H 9 v -2.585937 z" />
    </SvgIcon>
  );
};

export default EditSquareIcon;
