import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useNavigate, createSearchParams } from 'react-router-dom';

interface LoginEmailProps {
  currentPath: string;
  query: URLSearchParams;
}

const LoginUsername = (props: LoginEmailProps): JSX.Element => {
  const { currentPath, query } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: (query.get('username') as string) ?? '',
    },
    mode: 'onChange',
  });
  const navigate = useNavigate();
  const submit = async (form: FieldValues) => {
    navigate({ pathname: `${currentPath}/password`, search: `${createSearchParams({ ...form })}` });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid container spacing={4} justifyContent="flex-end">
        <Grid item xs={12}>
          <Typography variant="h5" component="h1">
            <b>관리자 로그인</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="username"
            rules={{ required: '이메일 또는 휴대폰을 입력하세요' }}
            render={({ field }) => (
              <TextField
                size="medium"
                fullWidth
                variant="outlined"
                label="이메일 또는 휴대폰 번호"
                color="secondary"
                error={Boolean(errors.username)}
                helperText={errors.username?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Button variant="contained" fullWidth type="submit">
            계속
          </Button>
        </Grid>
        <Grid display="flex" justifyContent="center" item xs={12}>
          <Typography variant="subtitle2">© 2022 Qwerky Inc. All rights reserved</Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginUsername;
