import React, { PropsWithChildren } from 'react';
import Navbar from 'components/navbar';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
import { BoxProps } from '@mui/material';

interface MainLayoutProps {
  withoutDrawer?: boolean;
  bottomController?: React.ReactNode;
  bottomControllerProps?: BoxProps;
  title?: string;
  description?: string;
}

const MainLayout = ({
  children,
  withoutDrawer = false,
  bottomController,
  bottomControllerProps,
  title,
  description,
}: PropsWithChildren<MainLayoutProps>) => {
  return (
    <Box display="flex" flexDirection="column">
      <Helmet>
        <title>{title ?? ''}</title>
        <meta property="og:title" content={title} key="title" />
        {description && (
          <>
            <meta name="description" content={description} />
            <meta property="og:description" content={description} key="description" />
          </>
        )}
      </Helmet>
      <Navbar
        withoutDrawer={withoutDrawer}
        bottomControllerProps={bottomControllerProps}
        bottomController={bottomController}
      >
        {children}
      </Navbar>
    </Box>
  );
};

export default MainLayout;
