import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { RequestHistory } from 'gql/graphql';
import CalendarIcon from 'components/icons/CalendarIcon';
import moment from 'moment';
import Divider from '@mui/material/Divider';
import DOMPurify from 'isomorphic-dompurify';
import { Map, MapMarker } from 'react-kakao-maps-sdk';
import UserProfile from 'components/users/UserProfile';

interface RequestHistoryProps {
  requestTitle?: string;
  requestHistory: RequestHistory;
}

const classes = {
  caption: {
    color: '#616161',
    fontSize: '0.75rem',
  },
  map: {
    borderRadius: 16,
    width: '100%',
    height: '285px',
  },
};

const RequestHistoryInfo = ({ requestTitle = '신고 당시 급구 요청서', requestHistory }: RequestHistoryProps) => {
  const { id, title, creationDate, startDate, assignedUser, endDate, description, address, possibleDateDiscussion } =
    requestHistory;

  const startDateMoment = moment(startDate).format('L');
  const endDateMoment = moment(endDate).format('L');
  const creationDateMoment = moment(creationDate).format('LTS');

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">
          <b>{requestTitle}</b>
        </Typography>
        <Typography sx={classes.caption}>신고 일자: {creationDateMoment}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <b>급구 요청 ID: {id}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" mb={2}>
          <b>{title}</b>
        </Typography>
        <Typography variant="subtitle2" alignItems="center" display="flex">
          <CalendarIcon sx={{ mr: (theme) => theme.spacing(1) }} />
          {(startDateMoment || endDateMoment) && (
            <>
              {startDateMoment} ~ {endDateMoment}
            </>
          )}
          {possibleDateDiscussion && '협의 가능'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <UserProfile user={assignedUser} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom>{address?.address2}</Typography>
        <Map center={{ lat: address.latitude, lng: address.longitude }} style={classes.map}>
          <MapMarker
            key="address"
            image={{ src: '/map_pin.png', size: { width: 32, height: 32 } }}
            position={{ lat: address.latitude, lng: address.longitude }}
          />
        </Map>
      </Grid>
    </Grid>
  );
};

export default RequestHistoryInfo;
