import React, { ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import SearchBarWithFilters from 'components/container/SearchBarWithFilters';
import { SelectChangeEvent } from '@mui/material';

interface CommentsSearchBarProps {
  searchQuery?: string;
  handleSearch?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleFilterChange?: (event: SelectChangeEvent<unknown>) => void;
}

const CommentReportsSearchBar = ({ searchQuery, handleSearch }: CommentsSearchBarProps) => {
  return (
    <Box sx={{ mt: 4 }}>
      <SearchBarWithFilters searchQuery={searchQuery} handleSearch={handleSearch} />
    </Box>
  );
};

export default CommentReportsSearchBar;
