import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import AccountIcon from 'components/icons/AccountIcon';
import IconButton from '@mui/material/IconButton';

interface AccountDropDownProps {
  handleLogOut: () => void;
}

const AccountDropDown = ({ handleLogOut }: AccountDropDownProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <AccountIcon sx={{ color: 'text.secondary' }} />
      </IconButton>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose} id="account-drop-down">
        <MenuItem onClick={handleLogOut}>
          <ListItemIcon>
            <LogoutRoundedIcon color="secondary" fontSize="small" />
          </ListItemIcon>
          <b>로그아웃</b>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountDropDown;
