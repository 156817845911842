import React from 'react';
import { DefaultPageProps } from 'components/interfaces';
import MainLayout from 'components/layouts/MainLayout';
import Container from 'components/container';
import { useQuery } from '@apollo/client';
import { ListMetadata, OrderBy, Proposal, ProposalStatusEnum, QueryAllProposalsArgs } from 'gql/graphql';
import { useSortParams } from 'hooks/useSortParams';
import { proposalsTableHeadRows } from 'utils/miscellaneous';
import ProposalsTableRow from 'components/proposals/ProposalsTableRow';
import ProposalsSearchBar from 'components/proposals/ProposalsSearchBar';
import DefaultTable from 'components/table/DefaultTable';
import { PROPOSALS_QUERY } from 'components/proposals/Queries';

const ProposalsPage = ({ name }: DefaultPageProps) => {
  const { handleFieldChange, searchParams } = useSortParams();
  const { page, sortField, sortOrder, q, status } = searchParams;

  const result = useQuery<{ allProposals: Proposal[]; total: ListMetadata }, QueryAllProposalsArgs>(PROPOSALS_QUERY, {
    variables: {
      page: Number(page ?? 0),
      perPage: 10,
      sortOrder: (sortOrder as OrderBy) ?? OrderBy.Desc,
      sortField: sortField ?? 'creationDate',
      filter: {
        q: q || undefined,
        status: status && status !== 'ALL' ? (status as ProposalStatusEnum) : undefined,
      },
    },
    errorPolicy: 'all',
  });

  const { data, loading, error } = result;
  return (
    <MainLayout title={name}>
      <Container
        searchBar={
          <ProposalsSearchBar
            handleFilterChange={handleFieldChange('status')}
            searchQuery={q}
            handleSearch={handleFieldChange('q')}
          />
        }
        title={name}
      >
        <DefaultTable result={result} rowDefinition={proposalsTableHeadRows}>
          {data &&
            !error &&
            !loading &&
            data?.allProposals.map((proposal) => {
              if (proposal === null) return;
              return <ProposalsTableRow key={proposal.id} proposal={proposal} />;
            })}
        </DefaultTable>
      </Container>
    </MainLayout>
  );
};

export default ProposalsPage;
