import React from 'react';
import Dialog from '@mui/material/Dialog';
import TabPanel from 'components/tabs/TabPanel';
import { useRequestPopupContext } from 'components/requests/[id]/dialogs/RequestPopupContext';
import ProposalPopup from 'components/requests/[id]/dialogs/ProposalPopup';
import ReportPopup from 'components/requests/[id]/dialogs/ReportPopup';

interface ProRequestContainerProps {
  onClose?: () => void;
}

const RequestPopupContainer = (props: ProRequestContainerProps): JSX.Element => {
  const { onClose = () => {} } = props;
  const { currentPopup, setCurrentPopup, payload } = useRequestPopupContext();
  const open = currentPopup !== undefined;

  const handleClose = () => {
    setCurrentPopup(undefined);
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="confirm-popup"
      open={open}
      maxWidth={payload?.maxWidth ?? 'sm'}
      fullWidth
    >
      <TabPanel index="report" value={currentPopup}>
        <ReportPopup />
      </TabPanel>
      <TabPanel index="proposal" value={currentPopup}>
        <ProposalPopup />
      </TabPanel>
    </Dialog>
  );
};

export default RequestPopupContainer;
