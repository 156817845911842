import React from 'react';
import PaperWithTitle from 'components/paper/PaperWithTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CompanyAddress } from 'gql/graphql';
import { Map, MapMarker } from 'react-kakao-maps-sdk';
import { Theme } from '@mui/material';

interface CompanyAddressProps {
  address?: CompanyAddress;
}

const classes = {
  paper: (theme: Theme) => ({
    p: 5,
    [theme.breakpoints.down('sm')]: {
      p: 3,
    },
  }),
  map: {
    borderRadius: 16,
    width: '100%',
    height: '285px',
  },
};

const CompanyAddressForm = ({ address }: CompanyAddressProps) => {
  return (
    <PaperWithTitle variant="outlined" elevation={0} header={null} sx={classes.paper}>
      <Grid container spacing={3}>
        <>
          <Grid display="flex" justifyContent="space-between" alignItems="center" item xs={12}>
            <Typography>
              <b>사업장 정보</b>
            </Typography>
          </Grid>
          {!address && (
            <Grid item xs={12}>
              <Typography variant="subtitle2">사업장 주소 및 전화번호를 등록해 주세요.</Typography>
            </Grid>
          )}
        </>
        {address && (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle2">대표 전화번호: {address.mobile}</Typography>
              <Typography variant="subtitle2">주소: {address.address1}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Map center={{ lat: address.latitude, lng: address.longitude }} style={classes.map}>
                <MapMarker
                  key="address"
                  image={{ src: '/map_pin.png', size: { width: 32, height: 32 } }}
                  position={{ lat: address.latitude, lng: address.longitude }}
                />
              </Map>
            </Grid>
          </>
        )}
      </Grid>
    </PaperWithTitle>
  );
};

export default CompanyAddressForm;
