import React, { ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import SearchBarWithFilters from 'components/container/SearchBarWithFilters';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { CustomSelect } from 'components/select/CustomSelect';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { companyStatusFilterMap, CompanyStatusFilterEnum } from 'utils/commonMaps';

interface CompaniesSearchBarProps {
  searchQuery?: string;
  handleSearch?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleFilterChange?: (event: SelectChangeEvent<unknown>) => void;
}

const classes = {
  select: {
    background: 'transparent',
  },
};

const CompaniesSearchBar = ({ searchQuery, handleSearch, handleFilterChange }: CompaniesSearchBarProps) => {
  return (
    <Box sx={{ mt: 4 }}>
      <SearchBarWithFilters
        handleSearch={handleSearch}
        filters={
          <CustomSelect
            style={classes.select}
            displayEmpty
            color="secondary"
            onChange={handleFilterChange}
            renderValue={() => <b>상태</b>}
            IconComponent={KeyboardArrowDownIcon}
          >
            {Object.keys(CompanyStatusFilterEnum).map((item) => (
              <MenuItem value={CompanyStatusFilterEnum[item as keyof typeof CompanyStatusFilterEnum]} key={item}>
                {companyStatusFilterMap[CompanyStatusFilterEnum[item as keyof typeof CompanyStatusFilterEnum]]}
              </MenuItem>
            ))}
          </CustomSelect>
        }
        searchQuery={searchQuery}
      />
    </Box>
  );
};

export default CompaniesSearchBar;
