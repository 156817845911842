import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { generateRandomRange } from 'utils/miscellaneous';

interface CollapsibleTagGroupLoadingProps {
  amount: number;
}

const LoadingCollapsibleTagGroup = ({ amount }: CollapsibleTagGroupLoadingProps) => {
  return (
    <>
      {Array.from(Array(amount).keys()).map((item) => (
        <Box key={item} height="100%" display="flex" alignItems="center" p={2}>
          <Skeleton variant="circular" width={39} height={39} sx={{ mr: 2 }} />
          <Skeleton height={39} width={generateRandomRange(70, 100)} />
        </Box>
      ))}
    </>
  );
};

export default LoadingCollapsibleTagGroup;
