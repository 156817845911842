import React from 'react';
import MainLayout from 'components/layouts/MainLayout';
import Container from 'components/container';
import { useQuery } from '@apollo/client';
import { graphql } from 'gql';
import PaperWithTitle from 'components/paper/PaperWithTitle';
import DOMPurify from 'isomorphic-dompurify';
import { DefaultCategory } from 'gql/graphql';
import LoadingBox from 'components/loading/LoadingBox';

interface DefaultPageProps {
  name?: string;
}

const QUERY = graphql(/* GraphQL */ `
  query GetGuide($category: DefaultCategory) {
    general(category: $category) {
      title
      content
    }
  }
`);
const PrivacyPolicyPage = ({ name }: DefaultPageProps) => {
  const { data, loading } = useQuery(QUERY, { variables: { category: DefaultCategory.Privacy } });

  return (
    <MainLayout title={name}>
      <Container title={name}>
        <PaperWithTitle variant="outlined" elevation={0}>
          {loading && <LoadingBox />}
          {data && !loading && (
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.general[0].content) }} />
          )}
        </PaperWithTitle>
      </Container>
    </MainLayout>
  );
};

export default PrivacyPolicyPage;
