import React from 'react';
import Grid, { GridProps } from '@mui/material/Grid';

interface GridFormRowProps extends GridProps {
  leftCol: React.ReactNode;
  rightCol: React.ReactNode;
}

const GridFormRow = ({ leftCol, rightCol, children, ...props }: GridFormRowProps) => {
  return (
    <Grid {...props} container direction="row">
      <Grid item minWidth={128} display="flex" alignItems="center">
        {leftCol}
      </Grid>
      <Grid item width="100%" maxWidth={400}>
        {rightCol}
      </Grid>
      {children}
    </Grid>
  );
};

export default GridFormRow;
