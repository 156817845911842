import React from 'react';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';

interface TabPanelProps<T> {
  children?: React.ReactNode;
  dir?: string;
  index: T;
  value: T;
  padding?: number;
  unmountOnTabChange?: boolean;
}

function TabPanel<T>(props: TabPanelProps<T>): JSX.Element {
  const { children, value, index, padding, unmountOnTabChange = true, ...other } = props;

  if (unmountOnTabChange) {
    return (
      <Box
        sx={{ p: (theme: Theme) => theme.spacing(padding ?? 0) }}
        role="tabpanel"
        hidden={value !== index}
        {...other}
      >
        {value === index && <div>{children}</div>}
      </Box>
    );
  }

  return (
    <Box sx={{ p: (theme: Theme) => theme.spacing(padding ?? 0) }} role="tabpanel" hidden={value !== index} {...other}>
      <div>{children}</div>
    </Box>
  );
}

TabPanel.defaultProps = {
  children: undefined,
  dir: undefined,
};

export default TabPanel;
