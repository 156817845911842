import Switch from '@mui/material/Switch';
import { styled } from '@mui/material';

export const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: theme.palette.success.main,
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.success.main,
      },
    },
  },
}));
