import React from 'react';
import { Project } from 'gql/graphql';
import PaperWithTitle from 'components/paper/PaperWithTitle';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import DOMPurify from 'isomorphic-dompurify';
import UserProfile from 'components/users/UserProfile';
import { Theme } from '@mui/material';
import ProjectStatus from 'components/projects/ProjectStatus';
import ImageCarousel from 'components/image/ImageCarousel';

interface RequestInfoProps {
  project: Project;
  inPaper?: boolean;
}

const classes = {
  map: {
    borderRadius: 16,
    width: '100%',
    height: '285px',
  },
  caption: {
    color: '#616161',
    mb: (theme) => theme.spacing(2),
  },
  box: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      rowGap: 1,
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  }),
};
const ProjectInfo = ({ project, inPaper = true }: RequestInfoProps) => {
  const { id, status, pictures, title, description, createdByUser } = project;

  const Wrapper = ({ children }: { children: React.ReactNode }): JSX.Element =>
    (inPaper ? <PaperWithTitle variant="outlined">{children}</PaperWithTitle> : children) as JSX.Element;

  return (
    <Wrapper>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box sx={classes.box}>
            <Typography variant="h6">
              <b>최신 업데이트된 프로젝트 게시물</b>
            </Typography>
            <ProjectStatus size="large" status={status} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <b>프로젝트 게시물 ID: {id}</b>
          </Typography>
        </Grid>
        {pictures?.length > 0 && (
          <Grid item xs={12}>
            <ImageCarousel pictures={pictures} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography gutterBottom variant="h6">
            <b>{title}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item display="flex" direction="row" xs={12}>
          <UserProfile user={createdByUser} />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <div
            style={{ wordWrap: 'break-word' }}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
          ></div>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default React.memo(ProjectInfo);
