import { graphql } from 'gql';

export const QUERY_COMPANIES = graphql(/* GraphQL */ `
  query allCompanies($page: Int, $perPage: Int, $sortField: String, $sortOrder: OrderBy, $filter: CompanyFilter) {
    allCompanies(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
      id
      name
      registrationNumber
      status
      rejectReason
      companyAddresses {
        address1
      }
      creationDate
      companyMembers {
        id
      }
      modificationDate
    }
    total: _allCompaniesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`);
