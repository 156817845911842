import React from 'react';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material';

const classes = {
  root: (theme: Theme) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.dark,
    flexDirection: 'row',
    columnGap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      width: '100%',
      rowGap: theme.spacing(0.5),
      flexDirection: 'column',
    },
  }),
  text: {
    color: (theme: Theme) => theme.palette.primary.main,
    fontSize: 14,
  },
  divider: {
    borderColor: (theme: Theme) => theme.palette.primary.main,
  },
};

const Footer = () => {
  return (
    <Box sx={classes.root} display="flex" justifyContent="center" alignItems="center" height="100%" py={2}>
      <Typography sx={classes.text}>© 2022 Qwerky Inc. All rights reserved</Typography>
      <Divider sx={classes.divider} flexItem orientation="vertical" />
      <Link href="/privacy" underline="none">
        개인정보 처리 방침
      </Link>
      <Divider sx={classes.divider} flexItem orientation="vertical" />
      <Link href="/terms" underline="none">
        이용약관
      </Link>
    </Box>
  );
};

export default Footer;
