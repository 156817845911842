import React from 'react';
import { DialogActions, DialogContent, DialogTitle } from 'components/dialogs/Dialogs';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material';
import { useUpdateDialogsPopupContext } from 'components/main-dialogs/context/UpdateDialogsPopupContext';
import { useForm } from 'react-hook-form';
import { useUpdateDialogsMutations } from 'components/main-dialogs/hooks/useUpdateDialogsMutations';
import {
  AdminReportStatusEnum,
  CompanyStatus,
  MutationUpdateCommentAdminReportArgs,
  MutationUpdateCompanyStatusArgs,
  MutationUpdateProjectAdminReportArgs,
  MutationUpdateRequestAdminReportArgs,
  PositiveReportInput,
} from 'gql/graphql';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import { QUERY_PRO_REQUESTS } from 'components/pro-requests/Queries';
import { ADMIN_REPORTS_QUERY } from 'components/flagged-requests/Queries';

const classes = {
  icon: {
    height: (theme: Theme) => theme.spacing(4),
    width: (theme: Theme) => theme.spacing(4),
    color: (theme: Theme) => theme.palette.primary.main,
  },
  iconBackground: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: (theme: Theme) => theme.spacing(8),
    width: (theme: Theme) => theme.spacing(8),
    padding: 0,
    background: '#F7F6F2',
    borderRadius: '50%',
  },
};

const UpdatePopup = () => {
  const { handleSubmit } = useForm<typeof payload.variables>();
  const { setCurrentPopup, payload } = useUpdateDialogsPopupContext();
  const navigate = useNavigate();
  const {
    updateCompanyStatus,
    updateAdminReport,
    updateProjectAdminReport,
    updateProjectAdminReportLoading,
    updateCompanyStatusLoading,
    updateAdminReportLoading,
    updateCommentAdminReport,
    updateCommentAdminReportLoading,
  } = useUpdateDialogsMutations();

  const { enqueueSnackbar } = useSnackbar();

  const handleClosePopup = () => {
    setCurrentPopup(undefined);
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const validateFlaggedRequest = (reportInputs: PositiveReportInput[]) => {
    return reportInputs.every((reportInput) => reportInput.isPositiveReport !== null);
  };

  const submit = () => {
    switch (payload.type) {
      case 'update_comment_report':
        if (!validateFlaggedRequest((payload.variables as MutationUpdateCommentAdminReportArgs).reportInput)) {
          setCurrentPopup('project_validation', {
            maxWidth: 'xs',
            type: 'update_flagged_project',
          });
          return;
        }
        updateCommentAdminReport({
          variables: payload.variables as MutationUpdateCommentAdminReportArgs,
        })
          .then((res) => {
            handleClosePopup();
            handleNavigateBack();
            if (payload.variables.status === AdminReportStatusEnum.Rejected) {
              enqueueSnackbar(`댓글 ID ${res.data?.updateCommentAdminReport?.id} 유지시켰습니다.`, {
                variant: 'success',
              });
              return;
            }
            enqueueSnackbar(`댓글 ID ${res.data?.updateCommentAdminReport?.id} 중단되었습니다.`, {
              variant: 'success',
            });
          })
          .catch((reason: ApolloError) => {
            enqueueSnackbar(`${reason?.message}`, { variant: 'error' });
          });
        return;
      case 'update_flagged_project':
        if (!validateFlaggedRequest((payload.variables as MutationUpdateProjectAdminReportArgs).reportInput)) {
          setCurrentPopup('project_validation', {
            maxWidth: 'xs',
            type: 'update_flagged_project',
          });
          return;
        }
        updateProjectAdminReport({
          variables: payload.variables as MutationUpdateProjectAdminReportArgs,
        })
          .then((res) => {
            handleClosePopup();
            handleNavigateBack();
            if (payload.variables.status === AdminReportStatusEnum.Rejected) {
              enqueueSnackbar(`프로젝트 게시물 ID ${res.data?.updateProjectAdminReport?.id} 게시물이 유지시켰습니다.`, {
                variant: 'success',
              });
              return;
            }
            enqueueSnackbar(`프로젝트 게시물 ID ${res.data?.updateProjectAdminReport?.id} 게시물이 중단되었습니다.`, {
              variant: 'success',
            });
          })
          .catch((reason: ApolloError) => {
            enqueueSnackbar(`${reason?.message}`, { variant: 'error' });
          });
        return;
      case 'update_pro_request':
        updateCompanyStatus({
          variables: payload.variables as MutationUpdateCompanyStatusArgs,
          refetchQueries: [{ query: QUERY_PRO_REQUESTS, variables: { page: 0, perPage: 10 } }],
        })
          .then(() => {
            handleClosePopup();
            handleNavigateBack();
            if (payload.variables.status === CompanyStatus.Rejected) {
              enqueueSnackbar('PRO 회원 신청을 반려시켰습니다.', { variant: 'success' });
              return;
            }
            enqueueSnackbar('PRO 회원 신청 승인이 완료되었습니다.', { variant: 'success' });
          })
          .catch((reason: ApolloError) => {
            enqueueSnackbar(`${reason?.message}`, { variant: 'error' });
          });
        return;
      case 'update_flagged_request':
        updateAdminReport({
          variables: payload.variables as MutationUpdateRequestAdminReportArgs,
          refetchQueries: [{ query: ADMIN_REPORTS_QUERY, variables: { page: 0, perPage: 10 } }],
        })
          .then((res) => {
            handleClosePopup();
            handleNavigateBack();
            if (payload.variables.status === AdminReportStatusEnum.Rejected) {
              enqueueSnackbar(`급구 요청 ID ${res.data?.updateRequestAdminReport?.id} 게시물이 유지시켰습니다.`, {
                variant: 'success',
              });
              return;
            }
            enqueueSnackbar(`급구 요청 ID ${res.data?.updateRequestAdminReport?.id} 게시물을 중단되었습니다.`, {
              variant: 'success',
            });
          })
          .catch((reason: ApolloError) => {
            enqueueSnackbar(`${reason?.message}`, { variant: 'error' });
          });

        return;
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <DialogTitle id="confirm">
        <Grid container direction="column" justifyContent="center" spacing={3}>
          <Grid item display="flex" justifyContent="center">
            <Paper elevation={0} sx={classes.iconBackground}>
              {payload?.Icon}
            </Paper>
          </Grid>
          <Grid item>{payload?.title}</Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography align="center">{payload?.description}</Typography>
      </DialogContent>
      <DialogActions>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <LoadingButton
              variant="contained"
              color="inherit"
              type="submit"
              loading={
                updateProjectAdminReportLoading ||
                updateCompanyStatusLoading ||
                updateAdminReportLoading ||
                updateCommentAdminReportLoading
              }
              fullWidth
            >
              {payload?.buttonName ?? '업데이트'}
            </LoadingButton>
          </Grid>
          <Grid item>
            <Button variant="text" onClick={handleClosePopup} fullWidth color="secondary">
              취소
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </form>
  );
};

export default UpdatePopup;
