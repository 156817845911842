import React from 'react';
import { Request } from 'gql/graphql';
import PaperWithTitle from 'components/paper/PaperWithTitle';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RequestStatus from 'components/requests/RequestStatus';
import CalendarIcon from 'components/icons/CalendarIcon';
import moment from 'moment/moment';
import Divider from '@mui/material/Divider';
import DOMPurify from 'isomorphic-dompurify';
import { Map, MapMarker } from 'react-kakao-maps-sdk';
import { KakaoMapProvider } from 'components/map/MapProvider';
import UserProfile from 'components/users/UserProfile';
import { Theme } from '@mui/material';
import ImageCarousel from 'components/image/ImageCarousel';

interface RequestInfoProps {
  request: Request;
  requestInfoTitle?: string;
  inPaper?: boolean;
}

const classes = {
  map: {
    borderRadius: 16,
    width: '100%',
    height: '285px',
  },
  caption: {
    color: '#616161',
    mb: (theme) => theme.spacing(2),
  },
  box: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      rowGap: 1,
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  }),
};
const RequestInfo = ({ request, requestInfoTitle = '급구', inPaper = true }: RequestInfoProps) => {
  const {
    id,
    address: { address1, latitude, longitude },
    status,
    pictures,
    title,
    description,
    assignedUser,
    proposalTotalCount,
    viewCount,
    startDate,
    endDate,
    possibleDateDiscussion,
  } = request;

  const startDateMoment = moment(startDate).format('L');
  const endDateMoment = moment(endDate).format('L');

  const Wrapper = ({ children }: { children: React.ReactNode }): JSX.Element =>
    (inPaper ? <PaperWithTitle variant="outlined">{children}</PaperWithTitle> : children) as JSX.Element;

  return (
    <Wrapper>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box sx={classes.box}>
            <Typography variant="h6">
              <b>
                {requestInfoTitle} ID: {id}
              </b>
            </Typography>
            <RequestStatus size="large" status={status} />
          </Box>
        </Grid>
        {pictures?.length > 0 && (
          <Grid item xs={12}>
            <ImageCarousel pictures={pictures} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography gutterBottom variant="h6">
            <b>{title}</b>
          </Typography>
          <Typography variant="subtitle2" sx={classes.caption}>
            지원자 {proposalTotalCount} ・ 조회 {viewCount}
          </Typography>
          <Typography variant="subtitle2" alignItems="center" display="flex">
            <CalendarIcon sx={{ mr: (theme) => theme.spacing(1) }} />
            {(startDateMoment || endDateMoment) && (
              <>
                {startDateMoment} ~ {endDateMoment}
              </>
            )}
            {possibleDateDiscussion && <> 협의 가능</>}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item display="flex" direction="row" xs={12}>
          <UserProfile user={assignedUser} />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}></div>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom variant="subtitle2">
            {address1}
          </Typography>
          <KakaoMapProvider>
            {({ isReady }) =>
              isReady && (
                <Map center={{ lat: latitude, lng: longitude }} style={classes.map}>
                  <MapMarker
                    key="address"
                    image={{ src: '/map_pin.png', size: { width: 32, height: 32 } }}
                    position={{ lat: latitude, lng: longitude }}
                  />
                </Map>
              )
            }
          </KakaoMapProvider>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default React.memo(RequestInfo);
