import React, { PropsWithChildren } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Interest } from 'gql/graphql';
import { FieldArrayWithId } from 'react-hook-form';
import SortableChip from 'components/sortable/SortableChip';

const classes = {
  chip: {
    cursor: 'pointer',
  },
  container: {
    border: `1px solid`,
    borderRadius: 2,
    p: 2,
  },
};

interface NestedGridProps {
  title?: string;
  items: FieldArrayWithId<{ items: Interest[] }, 'items', 'key'>[];
}

const DraggableGridChips = ({ title, items, children }: PropsWithChildren<NestedGridProps>) => {
  return (
    <Grid container spacing={2}>
      {title && (
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            <b>{title}</b>
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} sx={classes.container}>
        <Grid container spacing={1} width="100%">
          {items.map((item) => (
            <Grid key={item.id} item>
              <SortableChip id={item.name} label={item.name} />
            </Grid>
          ))}
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DraggableGridChips;
