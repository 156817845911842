import React from 'react';
import TablePagination, { TablePaginationProps } from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const DefaultTablePagination = (props: Partial<TablePaginationProps>) => {
  const {
    count = 0,
    onPageChange = () => {},
    page = 0,
    rowsPerPage = 10,
    rowsPerPageOptions = [],
    style,
    labelRowsPerPage = '항목',
    getItemAriaLabel = (type) => `go to ${type}`,
    labelDisplayedRows = ({ from, to, count: c }) => `${from}-${to} 총 ${c}개`,
    ...rest
  } = props;

  return (
    <TableRow>
      <TablePagination
        {...rest}
        style={{ borderBottom: 'none', ...style }}
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        onPageChange={onPageChange}
        getItemAriaLabel={getItemAriaLabel}
        labelRowsPerPage={labelRowsPerPage}
        labelDisplayedRows={labelDisplayedRows}
      />
    </TableRow>
  );
};

export default DefaultTablePagination;
