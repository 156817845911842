import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from 'context/authContext';

const AuthRoute = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { checkAuth } = useContext(AuthContext);
  const res = checkAuth();
  return res ? <Navigate to="/" /> : (children as JSX.Element);
};

export default AuthRoute;
