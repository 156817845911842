import { createPopupContext, Payload, Return } from 'components/popup/popupProvider';
import { useContext } from 'react';
import { CompanyInterest } from 'gql/graphql';

export type RequestTagSettingsPopupValues =
  | 'modify_tags'
  | 'delete_tag_group'
  | 'create_tag_group'
  | 'reorder_tag_group';

export interface RequestTagSettingsPopupPayload extends Payload {
  interestGroupId?: number;
  tagGroupId?: number;
  interests?: CompanyInterest[];
}

export const requestTagSettingsPopupContext = createPopupContext<
  RequestTagSettingsPopupValues,
  RequestTagSettingsPopupPayload
>();
export const useRequestTagSettingsPopupContext = (): Return<
  RequestTagSettingsPopupValues,
  RequestTagSettingsPopupPayload
> =>
  useContext(requestTagSettingsPopupContext) as unknown as Return<
    RequestTagSettingsPopupValues,
    RequestTagSettingsPopupPayload
  >;
