import React from 'react';
import MainLayout from 'components/layouts/MainLayout';
import Container from 'components/container';
import { DefaultPageProps } from 'components/interfaces';
import { useSortParams } from 'hooks/useSortParams';
import { projectsTableHeadRows } from 'utils/miscellaneous';
import DefaultTable from 'components/table/DefaultTable';
import { useQuery } from '@apollo/client';
import { ListMetadata, OrderBy, Project, ProjectStatusEnum, QueryAllProjectsArgs } from 'gql/graphql';
import ProjectsTableRow from 'components/projects/ProjectsTableRow';
import ProjectsSearchBar from 'components/projects/ProjectsSearchBar';
import { PROJECTS_QUERY } from 'components/projects/Queries';

const ProjectsPage = ({ name }: DefaultPageProps) => {
  const { handleFieldChange, searchParams } = useSortParams();

  const { page, sortField, sortOrder, q, status } = searchParams;

  const result = useQuery<{ allProjects: Project[]; total: ListMetadata }, QueryAllProjectsArgs>(PROJECTS_QUERY, {
    variables: {
      page: Number(page ?? 0),
      perPage: 10,
      sortOrder: (sortOrder as OrderBy) ?? OrderBy.Desc,
      sortField: sortField ?? 'creationDate',
      filter: {
        q: q || undefined,
        status: !status
          ? undefined
          : status === 'ALL'
          ? Object.values(ProjectStatusEnum)
          : [status as ProjectStatusEnum],
      },
    },
    errorPolicy: 'all',
  });

  const { data, error, loading } = result;
  return (
    <MainLayout title={name}>
      <Container
        title={name}
        searchBar={
          <ProjectsSearchBar
            handleFilterChange={handleFieldChange('status')}
            searchQuery={q}
            handleSearch={handleFieldChange('q')}
          />
        }
      >
        <DefaultTable result={result} rowDefinition={projectsTableHeadRows}>
          {data &&
            !error &&
            !loading &&
            data?.allProjects.map((project) => {
              if (project === null) return;
              return <ProjectsTableRow key={project.id} project={project} />;
            })}
        </DefaultTable>
      </Container>
    </MainLayout>
  );
};

export default ProjectsPage;
