import React from 'react';
import { AdminReportStatusEnum, RequestAdminReport } from 'gql/graphql';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import FlaggedRequestStatus from 'components/flagged-requests/FlaggedRequestStatus';

interface FlaggedRequestsTableRowProps {
  report: RequestAdminReport;
}

const FlaggedRequestsTableRow = ({ report }: FlaggedRequestsTableRowProps) => {
  const navigate = useNavigate();

  const {
    id,
    status,
    request: {
      createdByUser: { name },
      title,
      reportTotalCount,
    },
    lastReportedDate,
  } = report;

  const lastReportedDateMoment = moment(lastReportedDate);

  const map: (string | string[])[] = [
    String(id),
    name,
    status,
    title,
    String(reportTotalCount),
    [lastReportedDateMoment.format('l'), lastReportedDateMoment.format('LTS')],
  ];

  const handleNavigate = () => {
    navigate(`/flagged-requests/${id}`);
  };

  return (
    <TableRow onClick={handleNavigate}>
      {map.map((item, idx) => {
        if (Object.values(AdminReportStatusEnum).includes(item as AdminReportStatusEnum)) {
          return (
            <TableCell align="center" key={idx}>
              <FlaggedRequestStatus status={status} />
            </TableCell>
          );
        }
        if (typeof item === 'string') {
          return (
            <TableCell align="center" key={idx}>
              {item}
            </TableCell>
          );
        }
        return (
          <TableCell align="center" key={idx}>
            {item.map((subItem) => (
              <div key={subItem}>{subItem}</div>
            ))}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default FlaggedRequestsTableRow;
