import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from 'context/authContext';

const ProtectedRoute = ({ children }): JSX.Element => {
  const { checkAuth } = useContext(AuthContext);
  const res = checkAuth();
  return res ? (children as JSX.Element) : <Navigate to="/login/signin" />;
};

export default ProtectedRoute;
