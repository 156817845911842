import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AccountIcon = (props: SvgIconProps): JSX.Element => {
  const { fill = '#262627', width = 24, height = 24 } = props;

  return (
    <SvgIcon fill={fill} {...props} version="1.1" viewBox="0 0 24 24" height={height} width={width}>
      <path
        d="m 8,14 c -2.7495889,0 -5,2.250453 -5,5 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 c 0,-1.668649 1.3313133,-3 3,-3 h 8 c 1.668637,0 3,1.331363 3,3 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 c 0,-2.749559 -2.250441,-5 -5,-5 z"
        id="path102"
      />
      <path
        d="M 12,2 C 9.2504232,2 7,4.2504232 7,7 c 0,2.7495768 2.2504232,5 5,5 2.749547,0 5,-2.2504111 5,-5 0,-2.7495889 -2.250453,-5 -5,-5 z m 0,2 c 1.668649,0 3,1.3313133 3,3 0,1.6686867 -1.331351,3 -3,3 C 10.331301,10 9,8.6686988 9,7 9,5.3313012 10.331301,4 12,4 Z"
        id="path104"
      />
    </SvgIcon>
  );
};

export default AccountIcon;
