import React from 'react';
import { Proposal, ProposalStatusEnum } from 'gql/graphql';
import moment from 'moment/moment';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ProposalStatus from 'components/proposals/ProposalsStatus';
import { useNavigate } from 'react-router-dom';

interface ProposalsTableRowProps {
  proposal: Proposal;
  onClick?: () => void;
}

const ProposalsTableRow = ({ proposal, onClick }: ProposalsTableRowProps) => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate(`/proposals/${proposal.id}`);
  };

  const { id, assignedUser, description, status, distance, creationDate } = proposal;
  const creationDateMoment = moment(creationDate);

  const map: (string | string[])[] = [
    String(id),
    assignedUser?.name ?? '-',
    description,
    status,
    String(distance ?? '-'),
    [creationDateMoment.format('l'), creationDateMoment.format('LTS')],
  ];

  return (
    <TableRow onClick={onClick ? onClick : handleRedirect}>
      {map.map((item, idx) => {
        if (Object.values(ProposalStatusEnum).includes(item as ProposalStatusEnum)) {
          return (
            <TableCell align="center" key={idx}>
              <ProposalStatus status={status} />
            </TableCell>
          );
        }
        if (typeof item === 'string') {
          return (
            <TableCell align="center" key={item}>
              {item}
            </TableCell>
          );
        }
        return (
          <TableCell align="center" key={idx}>
            {item.map((subItem) => (
              <div key={subItem}>{subItem}</div>
            ))}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default ProposalsTableRow;
