import React from 'react';
import CollapsiblePaper from 'components/paper/CollapsiblePaper';
import { Project } from 'gql/graphql';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import DefaultTableHead from 'components/table/DefaultTableHead';
import { projectVotesTableHeadRows, TABLE_MIN_WIDTH } from 'utils/miscellaneous';
import TableFooter from '@mui/material/TableFooter';
import TableContainer from '@mui/material/TableContainer';
import { useSortParams } from 'hooks/useSortParams';
import ProjectVoteTableRow from 'components/projects/[id]/ProjectVoteTableRow';
import TableBody from '@mui/material/TableBody';
import DefaultTablePagination from 'components/table/DefaultTablePagination';

interface ProjectVotesCollapsibleListProps {
  project: Project;
  handlePageChange: (event: unknown, newPage: number) => void;
}

const ProjectVotesCollapsibleList = ({ project, handlePageChange }: ProjectVotesCollapsibleListProps) => {
  const { handleTableFieldChange, searchParams } = useSortParams();
  const { votesPage } = searchParams;

  return (
    <CollapsiblePaper header="좋아요 목록">
      <TableContainer sx={{ marginTop: 4 }} component={Paper} elevation={0}>
        <Table sx={{ minWidth: TABLE_MIN_WIDTH }}>
          <DefaultTableHead headRows={projectVotesTableHeadRows} handleTableFieldChange={handleTableFieldChange} />
          <TableBody>
            {project.votes.map((vote) => (
              <ProjectVoteTableRow key={vote.id} vote={vote} />
            ))}
          </TableBody>
          <TableFooter>
            <DefaultTablePagination
              onPageChange={handlePageChange}
              count={project?.voteCount}
              page={Number(votesPage ?? 0)}
              rowsPerPage={5}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </CollapsiblePaper>
  );
};

export default ProjectVotesCollapsibleList;
