import { graphql } from 'gql';

export const COMPANY_INTERESTS_QUERY = graphql(/* GraphQL */ `
  query companyInterestGroups($sortBy: BaseInterestOrderByInput) {
    companyInterestGroups(sortBy: $sortBy) {
      id
      name
      order
      interests(sortBy: { order: ASC }) {
        id
        interestGroupId
        name
      }
    }
  }
`);
