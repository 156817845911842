import React, { createContext, useContext, useState } from 'react';
import Script from 'react-load-script';

const defaultValues = {
  isReady: false,
};

const KakaoMapContext = createContext(defaultValues);

export const useKakaoMap = () => useContext(KakaoMapContext);

type ChildrenType = React.ReactNode | ((values: typeof defaultValues) => void) | undefined;

export const KakaoMapProvider = ({ children }: { children: ChildrenType }): JSX.Element => {
  const [isReady, setIsReady] = useState(false);
  return (
    <KakaoMapContext.Provider value={{ isReady }}>
      <Script
        id="kakao-map"
        type="text/javascript"
        url={`//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_ID}&libraries=services,clusterer&autoload=false`}
        onLoad={() => {
          kakao?.maps.load(function () {
            setIsReady(true);
          });
        }}
      ></Script>
      {children && typeof children === 'function' ? children({ isReady }) : children}
    </KakaoMapContext.Provider>
  );
};
