import React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material';
import TabPanel from 'components/tabs/TabPanel';
import LoginUsername from 'components/login/v2/credentials/LoginUsername';
import PasswordUsername from 'components/login/v2/credentials/PasswordUsername';
import PasswordReset from 'components/login/v2/credentials/PasswordReset';
import { useLocation, useSearchParams } from 'react-router-dom';
import MainLayout from 'components/layouts/MainLayout';
import { DefaultPageProps } from 'components/interfaces';
import LogoIconText from 'components/icons/LogoIconText';

const classes = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    background: 'text.secondary',
  },
  paper: {
    alignSelf: 'center',
    minHeight: 484,
    maxWidth: 496,
  },
  logo: {
    marginBottom: (theme: Theme) => theme.spacing(3),
  },
};

const LoginPage = ({ name }: DefaultPageProps) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const currentPath = location.pathname.split('?')[0];
  return (
    <MainLayout title={name} withoutDrawer={true}>
      <Box display="flex" alignItems="center" justifyContent="center" height="100%" width="100%" sx={{ flexGrow: 1 }}>
        <Paper variant="outlined" elevation={0} sx={classes.paper}>
          <Box padding={6} height="100%" minHeight={436}>
            <Box sx={classes.logo}>
              <LogoIconText fill="inherit" />
            </Box>
            <TabPanel index={currentPath} value="/login/signin">
              <LoginUsername currentPath={currentPath} query={searchParams} />
            </TabPanel>
            <TabPanel index={currentPath} value="/login/signin/password">
              <PasswordUsername currentPath={currentPath} query={searchParams} />
            </TabPanel>
            <TabPanel index={currentPath} value="/login/signin/challenge">
              <PasswordReset currentPath={currentPath} query={searchParams} />
            </TabPanel>
          </Box>
        </Paper>
      </Box>
    </MainLayout>
  );
};

export default LoginPage;
