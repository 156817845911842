import { createPopupContext, Payload, Return } from 'components/popup/popupProvider';
import { useContext } from 'react';
import { ProjectReport } from 'gql/graphql';

export type ProjectPopupValues = 'report';

export interface ProjectPopupPayload extends Payload {
  report?: ProjectReport;
}

export const projectPopupContext = createPopupContext<ProjectPopupValues, ProjectPopupPayload>();
export const useProjectPopupContext = (): Return<ProjectPopupValues, ProjectPopupPayload> =>
  useContext(projectPopupContext) as unknown as Return<ProjectPopupValues, ProjectPopupPayload>;
