import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import GridFormRow from 'components/grid/gridFormRow';
import TextField from '@mui/material/TextField';
import PaperWithTitle from 'components/paper/PaperWithTitle';
import { Company } from 'gql/graphql';
import ImageCarousel from 'components/image/ImageCarousel';

interface ProRequestInformationProps {
  company: Company;
}

const classes = {
  ol: {
    margin: '8px 0',
    paddingLeft: '20px',
  },
};

const ProRequestInformation = ({ company }: ProRequestInformationProps) => {
  const { registrationNumber, name, companyDocuments } = company;
  return (
    <PaperWithTitle variant="outlined" elevation={0}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">
            <b>사업자 정보</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <GridFormRow
            leftCol={
              <Typography variant="subtitle2">
                <b>사업자 등록번호</b>
              </Typography>
            }
            rightCol={<TextField value={registrationNumber} disabled fullWidth />}
          />
        </Grid>
        <Grid item xs={12}>
          <GridFormRow
            leftCol={
              <Typography variant="subtitle2">
                <b>상호명</b>
              </Typography>
            }
            rightCol={<TextField value={name} disabled fullWidth />}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            <b>첨부된 사업자 등록증 및 명함 확인</b>
          </Typography>
          <ol style={classes.ol}>
            <li>입력한 상호명과 동일한 사업자 등록증</li>
            <li>사업자 등록증에 기제된 상호명과 동일한 명함</li>
          </ol>
        </Grid>
        <Grid item xs={12}>
          <ImageCarousel pictures={companyDocuments.map((companyDocument) => companyDocument.picture)} />
        </Grid>
      </Grid>
    </PaperWithTitle>
  );
};

export default ProRequestInformation;
