import { createPopupContext, Payload, Return } from 'components/popup/popupProvider';
import { useContext } from 'react';

export type FlaggedRequestValues = 'request_history' | 'project_history';

export interface FlaggedRequestPopupPayload extends Payload {
  id: number;
}

export const flaggedRequestPopupContext = createPopupContext<FlaggedRequestValues, FlaggedRequestPopupPayload>();
export const useFlaggedRequestPopupContext = (): Return<FlaggedRequestValues, FlaggedRequestPopupPayload> =>
  useContext(flaggedRequestPopupContext) as unknown as Return<FlaggedRequestValues, FlaggedRequestPopupPayload>;
