import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';

const link = createUploadLink({
  uri: process.env.REACT_APP_BACKEND_URL,
  credentials: 'include', // 'include' | 'same-origin'
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  // Provide required constructor fields
  cache: new InMemoryCache(),
  link: authLink.concat(link),

  // Provide some optional constructor fields
  name: 'clip-admin',
  version: '1.0',
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export default client;
