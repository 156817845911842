import React from 'react';
import { DialogActions, DialogContent, DialogTitle } from 'components/dialogs/Dialogs';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material';
import { useUpdateDialogsPopupContext } from 'components/main-dialogs/context/UpdateDialogsPopupContext';
import EditSquareIcon from 'components/icons/EditSquareIcon';

const classes = {
  iconBackground: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: (theme: Theme) => theme.spacing(8),
    width: (theme: Theme) => theme.spacing(8),
    padding: 0,
    background: '#F7F6F2',
    borderRadius: '50%',
  },
};

const ProjectValidationPopup = () => {
  const { setCurrentPopup } = useUpdateDialogsPopupContext();

  const handleClosePopup = () => {
    setCurrentPopup(undefined);
  };

  return (
    <>
      <DialogTitle id="confirm">
        <Grid container direction="column" justifyContent="center" spacing={3}>
          <Grid item display="flex" justifyContent="center">
            <Paper elevation={0} sx={classes.iconBackground}>
              <EditSquareIcon />
            </Paper>
          </Grid>
          <Grid item>평가하지 않은 신고</Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography align="center">신고가 적합했는지 평가해 주세요.</Typography>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button onClick={handleClosePopup} variant="text" color="inherit" type="submit">
            확인
          </Button>
        </Box>
      </DialogActions>
    </>
  );
};

export default ProjectValidationPopup;
