import React, { ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import SearchBarWithFilters from 'components/container/SearchBarWithFilters';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material';
import { CustomSelect } from 'components/select/CustomSelect';
import { ProjectsFilterEnum, projectsFilterMap } from 'utils/commonMaps';

interface ProjectsSearchBarProps {
  searchQuery?: string;
  handleSearch?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleFilterChange?: (event: SelectChangeEvent<unknown>) => void;
}

const classes = {
  select: {
    background: 'transparent',
  },
};

const ProjectsSearchBar = ({ searchQuery, handleSearch, handleFilterChange }: ProjectsSearchBarProps) => {
  return (
    <Box sx={{ mt: 4 }}>
      <SearchBarWithFilters
        handleSearch={handleSearch}
        filters={
          <CustomSelect
            style={classes.select}
            displayEmpty
            color="secondary"
            onChange={handleFilterChange}
            renderValue={() => <b>상태</b>}
            IconComponent={KeyboardArrowDownIcon}
          >
            {Object.keys(ProjectsFilterEnum).map((item) => (
              <MenuItem value={ProjectsFilterEnum[item as keyof typeof ProjectsFilterEnum]} key={item}>
                {projectsFilterMap[ProjectsFilterEnum[item as keyof typeof ProjectsFilterEnum]]}
              </MenuItem>
            ))}
          </CustomSelect>
        }
        searchQuery={searchQuery}
      />
    </Box>
  );
};

export default ProjectsSearchBar;
