import React from 'react';
import Dialog from '@mui/material/Dialog';
import TabPanel from 'components/tabs/TabPanel';
import ProjectReportPopup from 'components/projects/[id]/dialogs/ProjectReportPopup';
import { useProjectPopupContext } from 'components/projects/[id]/dialogs/ProjectPopupContext';

interface ProjectPopupContainerProps {
  onClose?: () => void;
}

const ProjectPopupContainer = (props: ProjectPopupContainerProps): JSX.Element => {
  const { onClose = () => {} } = props;
  const { currentPopup, setCurrentPopup, payload } = useProjectPopupContext();
  const open = currentPopup !== undefined;

  const handleClose = () => {
    setCurrentPopup(undefined);
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="project-popup"
      open={open}
      maxWidth={payload?.maxWidth ?? 'sm'}
      fullWidth
    >
      <TabPanel index="report" value={currentPopup}>
        <ProjectReportPopup />
      </TabPanel>
    </Dialog>
  );
};

export default ProjectPopupContainer;
