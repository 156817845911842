import React, { PropsWithChildren, useState } from 'react';
import Box from '@mui/material/Box';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import EditIcon from 'components/icons/EditIcon';
import DeleteIcon from 'components/icons/DeleteIcon';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useSortableItem } from 'components/sortable/hooks/useSortableItem';
import { Theme } from '@mui/material';

interface CollapsibleTagGroupProps {
  isEditing?: boolean;
  name: string;
  handleEdit?: () => void;
  handleDelete?: () => void;
}

const CollapsibleTagGroup = ({
  name,
  children,
  isEditing,
  handleEdit,
  handleDelete,
}: PropsWithChildren<CollapsibleTagGroupProps>) => {
  const [open, setOpen] = useState(false);

  const handleCollapse = () => {
    setOpen((prevState) => !prevState);
  };

  const { style, setNodeRef, ...sortableItemRest } = useSortableItem(name);

  return (
    <div>
      <Box style={style} height="100%" display="flex" alignItems="center" justifyContent="space-between" p={2}>
        <Typography noWrap>
          <IconButton sx={{ mr: 2 }} onClick={handleCollapse}>
            {open ? <ExpandLessRoundedIcon color="secondary" /> : <ExpandMoreRoundedIcon color="secondary" />}
          </IconButton>
          {name}
        </Typography>
        <Box display="flex" alignItems="center">
          {isEditing ? (
            <IconButton {...sortableItemRest.attributes} ref={setNodeRef} {...sortableItemRest.listeners}>
              <DragIndicatorIcon sx={{ color: (theme: Theme) => theme.palette.text.disabled }} />
            </IconButton>
          ) : (
            <>
              {handleEdit && (
                <IconButton onClick={handleEdit}>
                  <EditIcon sx={{ color: (theme: Theme) => theme.palette.text.disabled }} />
                </IconButton>
              )}
              {handleDelete && (
                <IconButton onClick={handleDelete}>
                  <DeleteIcon sx={{ color: (theme: Theme) => theme.palette.text.disabled }} />
                </IconButton>
              )}
            </>
          )}
        </Box>
      </Box>
      <Collapse in={open}>
        <Box p={3} sx={{ borderTop: '1px solid #E0E0E0' }}>
          {children}
        </Box>
      </Collapse>
    </div>
  );
};

export default CollapsibleTagGroup;
