import React from 'react';
import { AuthProvider } from 'context/authContext';
import AppRouter from './components/app-router/AppRouter';
import { CssBaseline, ThemeProvider } from '@mui/material';
import useGlobalTheme from './styles/globalTheme';
import { ColorModeContext } from 'context/colorModeContext';
import { ApolloProvider } from '@apollo/client';
import client from './apolloClient';
import CustomSnackbarProvider from 'styles/SnackbarProvider';
import moment, { updateLocale } from 'moment';
import 'moment/locale/ko';
import { longDateFormat } from 'utils/moment';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/navigation';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';
import 'styles/swiper-style.css';

updateLocale('en', longDateFormat);

moment.locale('ko', {
  invalidDate: '',
  longDateFormat,
});

function App(props) {
  const [colorMode, theme, mode] = useGlobalTheme();
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <ColorModeContext.Provider value={{ ...colorMode, mode }}>
          <ThemeProvider theme={theme}>
            <CustomSnackbarProvider>
              <CssBaseline />
              <AppRouter {...props} />
            </CustomSnackbarProvider>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </AuthProvider>
    </ApolloProvider>
  );
}

export default App;
