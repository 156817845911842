import React from 'react';
import PaperWithTitle from 'components/paper/PaperWithTitle';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AvatarIcon from 'components/icons/AvatarIcon';
import { Company } from 'gql/graphql';
import Avatar from '@mui/material/Avatar';
import { Theme } from '@mui/material';

interface CompanyImageInfoProps {
  company?: Company;
}

const classes = {
  paper: (theme: Theme) => ({
    p: 5,
    [theme.breakpoints.down('sm')]: {
      p: 3,
    },
  }),
  icon: {
    borderRadius: '50%',
    width: 64,
    height: 64,
  },
};

const CompanyInfoImage = ({ company }: CompanyImageInfoProps) => {
  return (
    <PaperWithTitle variant="outlined" elevation={0} sx={classes.paper} header={null}>
      <Grid display="flex" alignItems="center" container spacing={3}>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            {company?.logo?.url ? (
              <Avatar variant="circular" alt="logo" src={company?.logo?.url} sx={classes.icon} />
            ) : (
              <AvatarIcon sx={classes.icon} />
            )}
            <Typography ml={2} fontSize={18}>
              <b>{company?.name}</b>
            </Typography>
          </Box>
        </Grid>
        {company?.description && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">{company?.description}</Typography>
            </Grid>
          </>
        )}
      </Grid>
    </PaperWithTitle>
  );
};

export default CompanyInfoImage;
