import React, { useMemo, useState } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import { PaperProps, Theme } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';

interface CollapsiblePaperProps extends PaperProps {
  header: string | React.ReactNode;
}

const classes = {
  paper: {
    p: (theme: Theme) => theme.spacing(4),
  },
};

const CollapsiblePaper = ({ header, sx, children }: CollapsiblePaperProps) => {
  const [open, setOpen] = useState(true);
  const handleCollapse = () => {
    setOpen((prevState) => !prevState);
  };

  const headerElement = useMemo(() => {
    if (typeof header === 'string') {
      return (
        <Typography variant="h6">
          <b>{header}</b>
        </Typography>
      );
    }
    return header;
  }, [header]);

  return (
    <Paper sx={[classes.paper, ...(Array.isArray(sx) ? sx : [sx])]}>
      {headerElement && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {headerElement}
          <IconButton onClick={handleCollapse}>
            {open ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
          </IconButton>
        </Box>
      )}
      <Collapse in={open} orientation="vertical">
        {children}
      </Collapse>
    </Paper>
  );
};

export default CollapsiblePaper;
