import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { graphql } from 'gql';
import { User } from 'gql/graphql';
import MainLayout from 'components/layouts/MainLayout';
import Container from 'components/container';
import { DefaultPageProps } from 'components/interfaces';
import UserInformation from 'components/users/[id]/UserInformation';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import LoadingBox from 'components/loading/LoadingBox';

const QUERY_USER = graphql(/* GraphQL */ `
  query userById($id: Int!) {
    userById(id: $id) {
      id
      name
      email
      joinType
      mobile
      profileImg {
        url
      }
      activeCompany(extendedCompanyStatus: true, extendedCompanyMemberStatus: true) {
        status
        name
      }
      activeCompanyMember {
        status
      }
    }
  }
`);

const UserPage = ({ name }: DefaultPageProps) => {
  const params = useParams();
  const { data, loading, error } = useQuery<{ userById: User }>(QUERY_USER, {
    variables: {
      id: Number(params.id),
    },
  });

  return (
    <MainLayout title={name}>
      <Container title={name}>
        {error && (
          <Grid item xs={12}>
            <FormHelperText error>{error.message}</FormHelperText>
          </Grid>
        )}
        {loading && <LoadingBox />}
        {data && !error && !loading && <UserInformation user={data?.userById} />}
      </Container>
    </MainLayout>
  );
};

export default UserPage;
