import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FilePlusIcon = (props: SvgIconProps): JSX.Element => {
  const { fill = '#262627', width = 24, height = 24 } = props;
  return (
    <SvgIcon fill={fill} width={width} height={height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M 6,1 C 4.3549921,1 3,2.3549921 3,4 v 16 c 0,1.645023 1.3550102,3 3,3 h 12 c 1.645005,0 3,-1.354995 3,-3 V 8 A 1.0001,1.0001 0 0 0 20.707031,7.2929687 l -6,-6 A 1.0001,1.0001 0 0 0 14,1 Z m 0,2 h 7.585938 L 19,8.4140625 V 20 c 0,0.564193 -0.435807,1 -1,1 H 6 C 5.435852,21 5,20.564175 5,20 V 4 C 5,3.4358701 5.4358701,3 6,3 Z" />
      <path d="m 14,1 a 1,1 0 0 0 -1,1 v 6 a 1.0001,1.0001 0 0 0 1,1 h 6 A 1,1 0 0 0 21,8 1,1 0 0 0 20,7 H 15 V 2 A 1,1 0 0 0 14,1 Z" />
      <path d="m 12,11 a 1,1 0 0 0 -1,1 v 6 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -6 a 1,1 0 0 0 -1,-1 z" />
      <path d="m 9,14 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 6 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z" />
    </SvgIcon>
  );
};

export default FilePlusIcon;
