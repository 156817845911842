import React from 'react';
import UsersIcon from 'components/icons/UsersIcon';
import BriefcaseIcon from 'components/icons/BriefcaseIcon';
import UserActiveIcon from 'components/icons/UserActiveIcon';
import AmplifierIcon from 'components/icons/AmplifierIcon';
import FlagIcon from 'components/icons/FlagIcon';
import FileIcon from 'components/icons/FileIcon';
import SettingsIcon from 'components/icons/SettingsIcon';
import ChartIcon from 'components/icons/ChartIcon';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import FilePlusIcon from 'components/icons/FilePlusIcon';
// eslint-disable-next-line import/no-extraneous-dependencies
import LayersIcon from 'components/icons/LayersIcon';
import { Theme } from '@mui/material';

export const TABLE_MIN_WIDTH = 900;

export interface IDefaultTableHeadRows {
  name: string;
  field: string;
  sortable?: boolean;
}

export const materialsTableHeadRows = [
  '업데이트 일시',
  '상품명',
  '브랜드',
  '벤더 SKU',
  '클립 SKU',
  '샘플 재고',
  '자재 재고',
];

export const companiesTableHeadRows: IDefaultTableHeadRows[] = [
  { name: 'ID', field: 'id', sortable: true },
  { name: '회사명', field: 'name' },
  { name: '상태', field: 'status' },
  { name: '사업장 주소', field: 'address' },
  { name: '멤버수', field: 'numberOfMembers' },
  { name: '등록 일자', field: 'creationDate', sortable: true },
  { name: '마지막 로그인', field: 'modificationDate', sortable: true },
];

export const usersTableHeadRows: IDefaultTableHeadRows[] = [
  { name: 'ID', field: 'id', sortable: true },
  { name: '가입자명', field: 'name', sortable: true },
  { name: '이메일', field: 'email' },
  { name: '가입경로', field: 'joinType' },
  { name: '사용자 유형', field: 'status' },
  { name: '회사명', field: 'companyName' },
  { name: '가입 일자', field: 'joinDate' },
  { name: '마지막 로그인', field: 'lastLoginDate', sortable: true },
];

export const proRequestsTableHeadRows: IDefaultTableHeadRows[] = [
  { name: 'ID', field: 'id', sortable: true },
  { name: '가입자명', field: 'name' },
  { name: '이메일', field: 'email' },
  { name: '상태', field: 'status' },
  { name: '회사명', field: 'companyName' },
  { name: '가입 일자', field: 'creationDate', sortable: true },
  { name: '승인 요청 일자', field: 'modificationDate', sortable: true },
];

export const requestsTableHeadRows: IDefaultTableHeadRows[] = [
  { name: 'ID', field: 'id', sortable: true },
  { name: '요청자', field: 'name' },
  { name: '제목', field: 'title' },
  { name: '지역', field: 'jibunAddress' },
  { name: '상태', field: 'status' },
  { name: '지원자', field: 'proposalTotalCount' },
  { name: '등록 일자', field: 'creationDate', sortable: true },
  { name: '최종 업데이트', field: 'modificationDate', sortable: true },
];

export const proposalsTableHeadRows: IDefaultTableHeadRows[] = [
  { name: 'ID', field: 'id', sortable: true },
  { name: '지원자', field: 'name' },
  { name: '내용', field: 'description' },
  { name: '상태', field: 'status' },
  { name: '거리', field: 'distance' },
  { name: '지원 일자', field: 'creationDate', sortable: true },
];

export const requestProposalsTableHeadRows: IDefaultTableHeadRows[] = [
  { name: 'ID', field: 'id' },
  { name: '지원자', field: 'name' },
  { name: '내용', field: 'description' },
  { name: '상태', field: 'status' },
  { name: '거리', field: 'distance' },
  { name: '지원 일자', field: 'proposalsCreationDate', sortable: true },
];

export const requestReportsTableHeadRows: IDefaultTableHeadRows[] = [
  { name: 'ID', field: 'id' },
  { name: '신고자', field: 'name' },
  { name: '내용', field: 'reason' },
  { name: '신고 상태', field: 'isPositiveReport' },
  { name: '신고 일자', field: 'reportsCreationDate', sortable: true },
  { name: '답변 일자', field: 'modificationDate' },
];

export const flaggedRequestsTableHeadRows: IDefaultTableHeadRows[] = [
  { name: 'ID', field: 'id', sortable: true },
  { name: '급구 요청자', field: 'name' },
  { name: '급구 제목', field: 'title' },
  { name: '상태', field: 'status' },
  { name: '신고 수', field: 'reportTotalCount' },
  { name: '최종 신고 일자', field: 'lastReportedDate', sortable: true },
];

export const flaggedProjectsTableHeadRows: IDefaultTableHeadRows[] = [
  { name: 'ID', field: 'id', sortable: true },
  {
    name: '게시자',
    field: 'publisher',
    sortable: true,
  },
  { name: '프로젝트 제목', field: 'title' },
  { name: '신고 수', field: 'projectReportTotalCount' },
  { name: '최종 신고 일자', field: 'lastReportedDate' },
];

export const projectsTableHeadRows: IDefaultTableHeadRows[] = [
  { name: 'ID', field: 'id', sortable: true },
  { name: '게시자', field: 'publisher' },
  { name: '프로젝트 제목', field: 'title' },
  { name: '지역', field: 'jibunAddress' },
  { name: '상태', field: 'status' },
  { name: '좋아요', field: 'voteCount' },
  { name: '등록 일자', field: 'creationDate', sortable: true },
  { name: '최종 업데이트', field: 'modificationDate', sortable: true },
];

export const projectVotesTableHeadRows: IDefaultTableHeadRows[] = [
  {
    name: 'ID',
    field: 'id',
  },
  {
    name: '사용자',
    field: 'name',
  },
  {
    name: '일자',
    field: 'votesCreationDate',
    sortable: true,
  },
];

export const projectReportsHeadRows: IDefaultTableHeadRows[] = [
  {
    name: 'ID',
    field: 'id',
  },
  {
    name: '신고자',
    field: 'reporter',
  },
  {
    name: '내용',
    field: 'description',
  },
  {
    name: '신고 상태',
    field: 'status',
  },
  {
    name: '신고 일자',
    field: 'reportsCreationDate',
    sortable: true,
  },
  {
    name: '답변 일자',
    field: 'reportsModificationDate',
  },
];

export const commentsReportTableHeadRows: IDefaultTableHeadRows[] = [
  {
    name: 'ID',
    field: 'id',
  },
  {
    name: '신고자',
    field: 'reporter',
  },
  {
    name: '내용',
    field: 'content',
  },
  {
    name: '신고 상태',
    field: 'status',
  },
  {
    name: '신고 일자',
    field: 'creationDate',
    sortable: true,
  },
  {
    name: '답변 일자',
    field: 'modificationDate',
  },
];

export const mainDrawerEntries: { parent: string; children: { route: string; name: string; disabled?: boolean }[] }[] =
  [
    {
      parent: '사용자 및 계정',
      children: [
        { route: '/users', name: '사용자 목록' },
        { route: '/companies', name: '회사 목록' },
        { route: '/pro-requests', name: 'PRO 회원 요청' },
        { route: '/comments', name: '댓글 신고 목록' },
      ],
    },
    {
      parent: '프로젝트 ',
      children: [
        { route: '/projects', name: '프로젝트 목록' },
        { route: '/flagged-projects', name: '신고 목록' },
        { route: '/project-tag-settings', name: '태그 및 그룹 설정' },
      ],
    },
    {
      parent: '급구!',
      children: [
        { route: '/requests', name: '급구 목록' },
        { route: '/flagged-requests', name: '신고 목록' },
        { route: '/proposals', name: '지원 목록' },
        { route: '/request-tag-settings', name: '태그 및 그룹 설정' },
        { route: '/tag-analysis', name: '태그 및 키워드 분석', disabled: true },
      ],
    },
    {
      parent: '자재 라이브러리',
      children: [
        { route: '/materials', name: '자재 목록', disabled: true },
        { route: '/brand', name: '브랜드', disabled: true },
        { route: '/uploads', name: '파일 업로드', disabled: true },
      ],
    },
  ];

export const stopPropagationForm =
  (callback: (event: React.FormEvent<HTMLFormElement>) => void) => (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    callback(event);
  };

const colorCond = (tabValue: string, i: string, theme: Theme) => {
  return tabValue === i ? theme.palette.primary.main : theme.palette.secondary.main;
};

export const drawerIconPairs = (tabValue: string) => {
  return {
    '/users': (
      <UsersIcon
        sx={{
          color: (theme: Theme) => colorCond(tabValue, '/users', theme),
        }}
        key="user-list"
      />
    ),
    '/companies': (
      <BriefcaseIcon
        sx={{
          color: (theme: Theme) => colorCond(tabValue, '/companies', theme),
        }}
        key="company-list"
      />
    ),
    '/pro-requests': (
      <UserActiveIcon
        sx={{
          color: (theme: Theme) => colorCond(tabValue, '/pro-requests', theme),
        }}
        key="pro-requests"
      />
    ),
    '/requests': (
      <AmplifierIcon
        sx={{
          color: (theme: Theme) => colorCond(tabValue, '/requests', theme),
        }}
        key="urgent-list"
      />
    ),
    '/flagged-requests': (
      <FlagIcon
        sx={{
          color: (theme: Theme) => colorCond(tabValue, '/flagged-requests', theme),
        }}
        key="report-list"
      />
    ),
    '/proposals': (
      <FileIcon
        sx={{
          color: (theme: Theme) => colorCond(tabValue, '/proposals', theme),
        }}
        key="support-list"
      />
    ),
    '/request-tag-settings': (
      <SettingsIcon
        sx={{
          color: (theme: Theme) => colorCond(tabValue, '/request-tag-settings', theme),
        }}
        key="request-tag-settings"
      />
    ),
    '/tag-analysis': (
      <ChartIcon
        sx={{
          color: (theme: Theme) => colorCond(tabValue, '/tag-analysis', theme),
        }}
        key="tag-analysis"
      />
    ),
    '/materials': (
      <ListRoundedIcon
        sx={{
          color: (theme: Theme) => colorCond(tabValue, '/materials', theme),
        }}
        key="materials"
      />
    ),
    '/brand': (
      <SellOutlinedIcon
        sx={{
          color: (theme: Theme) => colorCond(tabValue, '/brand', theme),
        }}
        key="brand"
      />
    ),
    '/uploads': (
      <FilePlusIcon
        sx={{
          color: (theme: Theme) => colorCond(tabValue, '/uploads', theme),
        }}
        key="uploads"
      />
    ),
    '/projects': (
      <LayersIcon
        sx={{
          color: (theme: Theme) => colorCond(tabValue, '/projects', theme),
        }}
        key="projects"
      />
    ),
    '/flagged-projects': (
      <FlagIcon
        sx={{
          color: (theme: Theme) => colorCond(tabValue, '/flagged-projects', theme),
        }}
        key="flagged-projects"
      />
    ),
    '/project-tag-settings': (
      <SettingsIcon
        sx={{
          color: (theme: Theme) => colorCond(tabValue, '/project-tag-settings', theme),
        }}
        key="project-tag-settings"
      />
    ),
    '/comments': (
      <ChatOutlinedIcon
        sx={{
          color: (theme: Theme) => colorCond(tabValue, '/comments', theme),
        }}
        key="comments"
      />
    ),
  };
};

export const generateRandomRange = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
