import React from 'react';
import MainLayout from 'components/layouts/MainLayout';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PageNotFoundIcon from 'assets/404_spy.png';

const PageNotFound = () => {
  return (
    <MainLayout title="404">
      <Box display="flex" flexDirection="column" width="100%" height="100%" alignItems="center" justifyContent="center">
        <Avatar
          variant="square"
          src={PageNotFoundIcon}
          alt="404"
          sx={{ maxWidth: 500, maxHeight: 219, width: '100%', height: '100%', objectFit: 'cover' }}
        />
        <Typography align="center" marginTop={2} variant="h2">
          페이지를 찾을 수 없습니다.
        </Typography>
      </Box>
    </MainLayout>
  );
};

export default PageNotFound;
