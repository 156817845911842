import { useContext, useEffect } from 'react';
import { AuthContext } from 'context/authContext';
import { useLocation } from 'react-router-dom';

const AuthVerify = () => {
  const location = useLocation();
  const { checkError } = useContext(AuthContext);
  useEffect(() => {
    checkError();
  }, [location]);
  return null;
};

export default AuthVerify;
