import React from 'react';
import Dialog from '@mui/material/Dialog';
import TabPanel from 'components/tabs/TabPanel';
import ModifyRequestTagSettingsPopup from 'components/request-tag-settings/dialogs/ModifyRequestTagSettingsPopup';
import DeleteRequestTagGroupPopup from 'components/request-tag-settings/dialogs/DeleteRequestTagGroupPopup';
import CreateRequestTagGroupPopup from 'components/request-tag-settings/dialogs/CreateRequestTagGroupPopup';
import { useRequestTagSettingsPopupContext } from 'components/request-tag-settings/dialogs/RequestTagSettingsContext';
import ReorderCompanyTagInterestsPopup from 'components/request-tag-settings/dialogs/ReorderCompanyTagInterestsPopup';

interface TagSettingsContainerProps {
  onClose?: () => void;
}

const RequestTagSettingsContainer = (props: TagSettingsContainerProps): JSX.Element => {
  const { onClose = () => {} } = props;
  const { currentPopup, setCurrentPopup, payload } = useRequestTagSettingsPopupContext();
  const open = currentPopup !== undefined;

  const handleClose = () => {
    setCurrentPopup(undefined);
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="tag-settings-popup"
      open={open}
      maxWidth={payload?.maxWidth ?? 'sm'}
      fullWidth
    >
      <TabPanel index="modify_tags" value={currentPopup}>
        <ModifyRequestTagSettingsPopup />
      </TabPanel>
      <TabPanel index="delete_tag_group" value={currentPopup}>
        <DeleteRequestTagGroupPopup />
      </TabPanel>
      <TabPanel index="create_tag_group" value={currentPopup}>
        <CreateRequestTagGroupPopup />
      </TabPanel>
      <TabPanel index="reorder_tag_group" value={currentPopup}>
        <ReorderCompanyTagInterestsPopup />
      </TabPanel>
    </Dialog>
  );
};

export default RequestTagSettingsContainer;
