import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CommentAdminReport } from 'gql/graphql';
import moment from 'moment';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

interface CommentReportsTableRowProps {
  commentReport: CommentAdminReport;
}

const CommentsReportTableRow = ({ commentReport }: CommentReportsTableRowProps) => {
  const navigate = useNavigate();

  const { id, comment, creationDate, modificationDate } = commentReport;

  const creationDateMoment = moment(creationDate);
  const modificationDateMoment = moment(modificationDate);

  const map: (string | string[])[] = [
    String(id),
    comment?.createdByUser?.name ?? '-',
    comment?.content ?? '-',
    comment?.status ?? '-',
    [creationDateMoment.format('l'), creationDateMoment.format('LTS')],
    [modificationDateMoment.format('l'), modificationDateMoment.format('LTS')],
  ];

  const handleNavigate = () => {
    navigate(`/comments/${id}`);
  };

  return (
    <TableRow onClick={handleNavigate}>
      {map.map((item, idx) => {
        if (Array.isArray(item)) {
          return (
            <TableCell align="center" key={idx}>
              {item.map((subItem) => (
                <div key={subItem}>{subItem}</div>
              ))}
            </TableCell>
          );
        }
        return (
          <TableCell align="center" key={idx}>
            {item}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default CommentsReportTableRow;
