import React from 'react';
import { useRequestPopupContext } from 'components/requests/[id]/dialogs/RequestPopupContext';
import ProposalDetails from 'components/proposals/[id]/ProposalDetails';
import Box from '@mui/material/Box';

const ProposalPopup = () => {
  const { payload } = useRequestPopupContext();

  return (
    <Box p={4}>
      <ProposalDetails proposal={payload.proposal} />
    </Box>
  );
};

export default ProposalPopup;
