import React, { ChangeEvent } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import { BoxProps, Theme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const classes = {
  clearBackground: {
    '&&': {
      backgroundColor: 'transparent',
    },
  },
  large: {
    height: 40,
  },
  search: {
    position: 'relative',
    borderRadius: '25px',
    border: '2px solid black',
    overflow: 'hidden',
    marginLeft: 0,
    flexGrow: {
      xs: 1,
      sm: 'inherit',
    },
  },
  searchIcon: {
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 0,
    top: 0,
  },
  input: {
    '& .MuiInputBase-root': {
      color: 'inherit',
    },
    '& .MuiInputBase-input': {
      borderRadius: '25px',
      padding: (theme: Theme) => theme.spacing(1, 0, 1, 2),
      paddingRight: (theme: Theme) => `calc(2em + ${theme.spacing(4)})`,
      transition: (theme: Theme) => theme.transitions.create('width'),
      width: '100%',
    },
  },
  button: {
    height: '100%',
    borderRadius: 0,
    maxWidth: 64,
  },
};

interface SearchBarProps extends BoxProps {
  inputProps?: InputBaseProps['inputProps'];
  onSearch?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSearchClick?: () => void;
  searchQuery?: string;
  name?: string;
  size?: 'medium' | 'large';
  placeholder?: string;
}

const SearchBar = (props: SearchBarProps) => {
  const {
    placeholder,
    inputProps,
    onSearch = () => {},
    name = 'search',
    size,
    sx,
    searchQuery,
    handleSearchClick,
    ...rest
  } = props;

  return (
    <Box
      sx={[...(Array.isArray(sx) ? sx : [sx]), classes.search, !!inputProps?.value ? classes.clearBackground : null]}
      onSubmit={handleSearchClick}
      {...rest}
    >
      <form>
        <InputBase
          name={name}
          sx={{ ...classes.input, ...(size === 'large' ? classes.large : {}) }}
          inputProps={{ 'aria-label': 'search', ...inputProps }}
          value={searchQuery}
          onChange={onSearch}
          fullWidth
          placeholder={placeholder}
        />
        <Box sx={classes.searchIcon}>
          <Button type="submit" size="medium" sx={classes.button}>
            <SearchIcon color="secondary" />
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default SearchBar;
