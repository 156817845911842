import React from 'react';
import { CommentStatusEnum } from 'gql/graphql';
import StatusComponent, { StatusComponentProps } from 'components/status/StatusComponent';

interface CommentStatusProps {
  status: CommentStatusEnum;
  size?: 'large' | 'medium';
}

export const commentStatusMap: { [key in CommentStatusEnum]: StatusComponentProps } = {
  ARCHIVED: {
    text: '아카이브',
    status: 'default',
  },
  OPEN: {
    text: '진행중',
    status: 'success',
  },
  BLOCKED: {
    text: '중단',
    status: 'warning',
  },
};

const CommentStatus = ({ status, size }: CommentStatusProps) => {
  return <StatusComponent size={size} {...commentStatusMap[status]} />;
};

export default React.memo(CommentStatus);
