import React from 'react';
import { DefaultPageProps } from 'components/interfaces';
import MainLayout from 'components/layouts/MainLayout';
import Container from 'components/container';
import Grid from '@mui/material/Grid';
import RequestInfo from 'components/requests/[id]/RequestInfo';
import { useQuery } from '@apollo/client';
import { graphql } from 'gql';
import { useParams } from 'react-router-dom';
import { ProjectVotesArgs, QueryRequestArgs, Request, SortBy } from 'gql/graphql';
import RequestProposalsCollapsibleList from 'components/requests/[id]/RequestProposalsCollapsibleList';
import RequestReportsCollapsibleList from 'components/requests/[id]/RequestReportsCollapsibleList';
import { PopupProvider } from 'components/popup/popupProvider';
import { requestPopupContext } from 'components/requests/[id]/dialogs/RequestPopupContext';
import RequestPopupContainer from 'components/requests/[id]/dialogs/RequestPopupContainer';
import FormHelperText from '@mui/material/FormHelperText';
import { useSortParams } from 'hooks/useSortParams';
import { RenameByT } from 'utils/commonMaps';
import LoadingBox from 'components/loading/LoadingBox';

const QUERY_REQUEST = graphql(/* GraphQL */ `
  query request(
    $id: Int!
    $proposalsSortBy: RequestProposalOrderByInput
    $reportsSortBy: RequestReportOrderByInput
    $proposalsSkip: Int
    $proposalsTake: Int
    $reportsSkip: Int
    $reportsTake: Int
  ) {
    request(id: $id) {
      id
      status
      pictures {
        id
        url
        pic_name
      }
      title
      description
      reportTotalCount
      assignedUser {
        id
        activeCompanyMember {
          status
        }
        activeCompany(extendedCompanyStatus: true, extendedCompanyMemberStatus: true) {
          name
        }
        name
        profileImg {
          url
          id
          pic_name
        }
      }
      reports(skip: $reportsSkip, take: $reportsTake, sortBy: $reportsSortBy) {
        id
        status
        user {
          id
          name
          activeCompany(extendedCompanyStatus: true, extendedCompanyMemberStatus: true) {
            name
          }
          activeCompanyMember {
            status
          }
          profileImg {
            url
            id
            pic_name
          }
        }
        reportReason
        creationDate
        modificationDate
        isPositiveReport
        creationDate
        modificationDate
      }
      proposalTotalCount
      proposals(skip: $proposalsSkip, take: $proposalsTake, sortBy: $proposalsSortBy) {
        id
        status
        assignedUser {
          id
          name
          profileImg {
            id
            url
            pic_name
          }
          activeCompany(extendedCompanyStatus: true, extendedCompanyMemberStatus: true) {
            name
          }
          activeCompanyMember {
            status
          }
        }
        creationDate
        modificationDate
        distance
        description
      }
      address {
        address1
        address2
        latitude
        longitude
      }
      proposalTotalCount
      viewCount
      startDate
      endDate
      possibleDateDiscussion
    }
  }
`);

type RequestProposalsArgsCustom = RenameByT<
  { skip: 'proposalsSkip'; sortBy: 'proposalsSortBy'; take: 'proposalsTake' },
  ProjectVotesArgs
>;

type RequestReportsArgsCustom = RenameByT<
  { skip: 'reportsSkip'; sortBy: 'reportsSortBy'; take: 'reportsTake' },
  ProjectVotesArgs
>;

const RequestPage = ({ name }: DefaultPageProps) => {
  const params = useParams();
  const { searchParams, setSearchParams } = useSortParams();
  const { proposalsPage, reportsPage, sortOrder, sortField } = searchParams;

  const { data, loading, error } = useQuery<
    { request: Request },
    QueryRequestArgs & RequestProposalsArgsCustom & RequestReportsArgsCustom
  >(QUERY_REQUEST, {
    variables: {
      id: Number(params.id),
      proposalsSkip: Number(proposalsPage ?? 0) * 5,
      proposalsTake: 5,
      ...(sortField === 'proposalsCreationDate' && {
        proposalsSortBy: {
          creationDate: sortOrder as SortBy,
        },
      }),
      ...(sortField === 'reportsCreationDate' && {
        reportsSortBy: {
          creationDate: sortOrder as SortBy,
        },
      }),
      reportsSkip: Number(reportsPage ?? 0) * 5,
      reportsTake: 5,
    },
    skip: !Number(params.id),
  });

  const handlePageChange = (param: string) => (event: unknown, newPage: number) => {
    setSearchParams({ ...searchParams, [param]: String(newPage) });
  };

  return (
    <MainLayout title={name}>
      <Container title={name}>
        <Grid container spacing={2}>
          {error && (
            <Grid item xs={12}>
              <FormHelperText error>{error.message}</FormHelperText>
            </Grid>
          )}
          {loading && <LoadingBox />}
          {data && !error && !loading && (
            <PopupProvider context={requestPopupContext}>
              <Grid item xs={12}>
                <RequestInfo request={data.request} />
              </Grid>
              <Grid item xs={12}>
                <RequestProposalsCollapsibleList
                  request={data.request}
                  proposalsPage={Number(proposalsPage ?? 0)}
                  handlePageChange={handlePageChange('proposalsPage')}
                />
              </Grid>
              <Grid item xs={12}>
                <RequestReportsCollapsibleList
                  request={data.request}
                  proposalsPage={Number(reportsPage ?? 0)}
                  handlePageChange={handlePageChange('reportsPage')}
                />
              </Grid>
              <RequestPopupContainer />
            </PopupProvider>
          )}
        </Grid>
      </Container>
    </MainLayout>
  );
};

export default RequestPage;
