import React from 'react';
import { Comment } from 'gql/graphql';
import PaperWithTitle from 'components/paper/PaperWithTitle';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CommentStatus from 'components/comments/[id]/CommentStatus';
import Divider from '@mui/material/Divider';
import UserProfile from 'components/users/UserProfile';

interface CommentReportInfoProps {
  comment: Comment;
  inPaper?: boolean;
}

const classes = {
  map: {
    borderRadius: 16,
    width: '100%',
    height: '285px',
  },
  caption: {
    color: '#616161',
    mb: (theme) => theme.spacing(2),
  },
  box: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      rowGap: 1,
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  }),
};

const CommentReportInfo = ({ comment, inPaper = true }: CommentReportInfoProps) => {
  const Wrapper = ({ children }: { children: React.ReactNode }): JSX.Element =>
    (inPaper ? <PaperWithTitle variant="outlined">{children}</PaperWithTitle> : children) as JSX.Element;

  return (
    <Wrapper>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box sx={classes.box}>
            <Typography variant="h6">
              <b>최신 업데이트된 댓글</b>
            </Typography>
            <CommentStatus size="large" status={comment?.status} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <b>댓글 ID: {comment.id}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h6">
            <b>{comment.content}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item display="flex" direction="row" xs={12}>
          <UserProfile user={comment.createdByUser} />
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default React.memo(CommentReportInfo);
