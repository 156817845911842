import { createPopupContext, Payload, Return } from 'components/popup/popupProvider';
import { useContext } from 'react';
import { ProjectInterest } from 'gql/graphql';

export type ProjectTagSettingsPopupValues =
  | 'modify_tags'
  | 'delete_tag_group'
  | 'create_tag_group'
  | 'reorder_tag_group';

export interface ProjectTagSettingsPopupPayload extends Payload {
  interestGroupId?: number;
  tagGroupId?: number;
  interests?: ProjectInterest[];
}

export const projectTagSettingsPopupContext = createPopupContext<
  ProjectTagSettingsPopupValues,
  ProjectTagSettingsPopupPayload
>();
export const useProjectTagSettingsPopupContext = (): Return<
  ProjectTagSettingsPopupValues,
  ProjectTagSettingsPopupPayload
> =>
  useContext(projectTagSettingsPopupContext) as unknown as Return<
    ProjectTagSettingsPopupValues,
    ProjectTagSettingsPopupPayload
  >;
