import React from 'react';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

interface ImageItemProps extends AvatarProps {
  onDelete?: () => void;
  size?: string;
  disabled?: boolean;
}

const classes = {
  container: {
    position: 'relative',
    '& .MuiButtonBase-root.MuiIconButton-root': {
      display: 'none',
    },
    '&:hover': {
      '& .MuiButtonBase-root.MuiIconButton-root': {
        display: 'block',
      },
    },
  },
  root: {
    boxSizing: 'border-box',
    border: '0.5px solid #212121',
    borderRadius: 4,
  },
  floatingButtonBox: {
    position: 'absolute',
    top: 8,
    right: 8,
    zIndex: (theme: Theme) => theme.zIndex.mobileStepper,
    padding: 0,
  },
};

const ImageItem = ({ onDelete, size, disabled, ...rest }: ImageItemProps) => {
  return (
    <Box sx={classes.container}>
      {onDelete && (
        <Box sx={classes.floatingButtonBox}>
          <IconButton disabled={disabled} onClick={onDelete} style={{ padding: 0 }} color="secondary" size="small">
            <CancelOutlinedIcon color="secondary" fontSize="small" />
          </IconButton>
        </Box>
      )}
      <Avatar
        variant="rounded"
        {...rest}
        sx={{ ...classes.root, height: size === 'small' ? 64 : 88, width: size === 'small' ? 64 : 88 }}
      />
    </Box>
  );
};

export default ImageItem;
