import React from 'react';
import { TableCellProps, TableCell as MuiTableCell, TableSortLabel } from '@mui/material';

interface MyTableCellProps extends TableCellProps {
  withIcon?: boolean;
  onClick?: () => void;
}

const TableCell = ({ withIcon, children, onClick, sortDirection, ...props }: MyTableCellProps) => {
  const handleClick = () => {
    if (withIcon && onClick) {
      onClick();
    }
  };

  const Wrapper = ({ children: wrapperChildren }: { children: React.ReactNode }): JSX.Element =>
    (withIcon ? (
      <TableSortLabel onClick={handleClick} active direction={sortDirection ? sortDirection : 'desc'}>
        {wrapperChildren}
      </TableSortLabel>
    ) : (
      wrapperChildren
    )) as JSX.Element;

  return (
    <MuiTableCell onClick={handleClick} {...props}>
      <Wrapper>{children}</Wrapper>
    </MuiTableCell>
  );
};

export default TableCell;
