import React, { useContext, useState } from 'react';
import { ColorModeContext } from 'context/colorModeContext';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';

const HiddenButton = () => {
  const { toggleColorMode, mode } = useContext(ColorModeContext);
  const [isVisible, setIsVisible] = useState(false);
  const handleIsVisible = (visible: boolean) => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsVisible(visible);
  };

  return (
    <Box onClick={handleIsVisible(true)} sx={{ minWidth: 24, minHeight: 24 }}>
      {isVisible && (
        <>
          <IconButton color="secondary" size="small" onClick={handleIsVisible(false)}>
            <CancelRoundedIcon fontSize="small" />
          </IconButton>
          <Switch
            icon={<Brightness4Icon fontSize="small" color="info" />}
            checkedIcon={<Brightness7Icon fontSize="small" color="error" />}
            color="default"
            onClick={toggleColorMode}
            checked={mode === 'dark'}
          />
        </>
      )}
    </Box>
  );
};

export default HiddenButton;
