import React from 'react';
import MainLayout from 'components/layouts/MainLayout';
import { DefaultPageProps } from 'components/interfaces';
import Container from 'components/container';
import { graphql } from 'gql';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  AdminReportStatusEnum,
  CommentAdminReport,
  CommentReportReasonEnum,
  CompanyMemberStatus,
  Maybe,
  PositiveReportInput,
  QueryAdminReportArgs,
  ReportStatusEnum,
  Scalars,
} from 'gql/graphql';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import CommentsReportStatusManagement from 'components/comments/[id]/CommentsReportStatusManagement';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { usePrompt } from 'hooks/usePrompt';
import CommentReportInfo from 'components/comments/[id]/CommentReportInfo';
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import LoadingButton from '@mui/lab/LoadingButton';
import { Theme, Typography } from '@mui/material';
import PaperWithTitle from 'components/paper/PaperWithTitle';
import Divider from '@mui/material/Divider';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { useUpdateDialogsPopupContext } from 'components/main-dialogs/context/UpdateDialogsPopupContext';
import UpdateDialogsContainer from 'components/main-dialogs/containers/UpdateDialogsContainer';
import LoadingBox from 'components/loading/LoadingBox';
import ReportManagementItem from 'components/status/ReportManagementItem';
import { projectReportReasonMap } from 'utils/commonMaps';

export interface CustomCommentReport {
  creationDate: Scalars['DateTime'];
  id: Scalars['Int'];
  isPositiveReport?: Maybe<Scalars['Boolean']>;
  reportReason: CommentReportReasonEnum;
  status: ReportStatusEnum;
  historyAtReport: {
    id: number;
  };
  user: {
    id: number;
    name: string;
    profileImg: {
      url: string;
      id: number;
      pic_name: string;
    };
    activeCompany: {
      name: string;
    };
    activeCompanyMember: {
      status: CompanyMemberStatus;
    };
  };
}

interface FormType {
  id: number;
  status: AdminReportStatusEnum;
  reportReasons: CommentReportReasonEnum[];
  reportInput: CustomCommentReport[];
}

const classes = {
  submitButton: (theme: Theme) => ({
    width: '100%',
    maxWidth: theme.spacing(30),
    [theme.breakpoints.down('sm')]: {
      maxWidth: theme.spacing(15),
    },
  }),
};

const COMMENT_ADMIN_REPORT_QUERY = graphql(/* GraphQL */ `
  query CommentAdminReport($id: Int!) {
    AdminReport(id: $id) {
      ... on CommentAdminReport {
        id
        reportReasons {
          reportReason
        }
        status
        reports {
          id
          historyAtReport {
            id
            creationDate
          }
          creationDate
          reportReason
          user {
            id
            name
            profileImg {
              url
              id
              pic_name
            }
            activeCompanyMember {
              status
            }
            activeCompany(extendedCompanyStatus: true, extendedCompanyMemberStatus: true) {
              name
            }
          }
          isPositiveReport
        }
        comment {
          id
          content
          status
          createdByUser {
            id
            name
            profileImg {
              id
              url
              pic_name
            }
          }
        }
        creationDate
        modificationDate
      }
    }
  }
`);

interface ConfirmDetailsI {
  title: string;
  description: string;
  buttonName: string;
  Icon: React.ReactNode;
}

const returnConfirmDetails = (condition: boolean): ConfirmDetailsI => {
  if (condition) {
    return {
      title: '댓글 유지',
      description: '댓글 유지. 댓글 유지를 선택하시면 해당 댓글은 사용자에게 계속 노출됩니다. 댓글을 유지하시겠습니까?',
      buttonName: '댓글 유지',
      Icon: <TaskAltRoundedIcon />,
    };
  }
  return {
    title: '댓글 중단',
    description: '댓글을 중단하시겠습니까?\n 댓글이 중단되면 해당 댓글은 사용자에게 더이상 노출이 안됩니다.',
    buttonName: '댓글 중단하기',
    Icon: <HighlightOffRoundedIcon />,
  };
};

const getReportInput = (form: FormType): PositiveReportInput[] => {
  return form.reportInput.map((item) => ({
    id: item.id,
    isPositiveReport: item.isPositiveReport,
  }));
};

const CommentReportPage = ({ name }: DefaultPageProps) => {
  const params = useParams();
  const navigate = useNavigate();

  const { setCurrentPopup } = useUpdateDialogsPopupContext();

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const methods = useForm<FormType>({
    mode: 'onChange',
  });

  const {
    reset,
    control,
    getValues,
    formState: { isDirty, isSubmitted },
  } = methods;

  const { fields, update } = useFieldArray({
    control,
    name: 'reportInput',
    keyName: 'key',
  });

  const handleSetPopup = () => {
    setCurrentPopup('update', {
      maxWidth: 'xs',
      type: 'update_comment_report',
      variables: { ...getValues(), reportInput: getReportInput(getValues()) },
      ...returnConfirmDetails(getValues('status') === AdminReportStatusEnum.Rejected),
    });
  };

  const handleUpdateField = (idx: number, report: CustomCommentReport) => (isPositiveReport: boolean) => {
    update(idx, { ...report, isPositiveReport });
  };

  const { data, loading, error } = useQuery<{ AdminReport: CommentAdminReport }, QueryAdminReportArgs>(
    COMMENT_ADMIN_REPORT_QUERY,
    {
      variables: {
        id: Number(params.id),
      },
      onCompleted(res) {
        reset({
          id: res.AdminReport.id,
          status: res.AdminReport.status,
          reportReasons: res.AdminReport.reportReasons.map((reason) => reason.reportReason),
          reportInput: res?.AdminReport.reports.map((commentReport) => ({
            ...commentReport,
            isPositiveReport: commentReport.isPositiveReport,
          })),
        });
      },
    },
  );

  const isEditable =
    data?.AdminReport?.status !== AdminReportStatusEnum.Rejected &&
    data?.AdminReport?.status !== AdminReportStatusEnum.Approved;

  usePrompt(
    '페이지를 이동하시겠습니까?\n페이지를 이동하면 변경사항이 저장되지 않을 수 있습니다.',
    isDirty && !isSubmitted,
  );

  return (
    <FormProvider {...methods}>
      <form>
        <MainLayout
          title={name}
          bottomController={
            <>
              <Button
                onClick={handleNavigateBack}
                color="secondary"
                startIcon={<ArrowBackIosNewIcon fontSize="small" />}
              >
                뒤로가기
              </Button>
              <LoadingButton
                disabled={!isDirty}
                onClick={handleSetPopup}
                sx={classes.submitButton}
                variant="contained"
                color="inherit"
              >
                저장
              </LoadingButton>
            </>
          }
        >
          <Container title={name}>
            <Grid container spacing={2}>
              {error && (
                <Grid item xs={12}>
                  <FormHelperText error>{error.message}</FormHelperText>
                </Grid>
              )}
              {loading && <LoadingBox />}
              {data && !loading && !error && (
                <>
                  <Grid item xs={12}>
                    <CommentsReportStatusManagement commentReport={data?.AdminReport} isEditable={isEditable} />
                  </Grid>
                  <Grid item xs={12}>
                    <CommentReportInfo comment={data?.AdminReport.comment} />
                  </Grid>
                  <Grid item xs={12}>
                    <PaperWithTitle
                      variant="outlined"
                      header={
                        <Typography mb={3} variant="h6">
                          <b>신고 내역</b>
                        </Typography>
                      }
                    >
                      {fields.map((commentReport, idx) => (
                        <>
                          <ReportManagementItem
                            key={commentReport?.id}
                            isEditable={isEditable}
                            handleUpdateField={handleUpdateField(idx, commentReport)}
                            report={commentReport}
                            reportReason={projectReportReasonMap[commentReport?.reportReason]}
                          />
                          {idx + 1 !== data.AdminReport.reports.length && (
                            <Divider sx={{ m: (theme) => theme.spacing(3, 0) }} />
                          )}
                        </>
                      ))}
                    </PaperWithTitle>
                  </Grid>
                </>
              )}
            </Grid>
          </Container>
        </MainLayout>
      </form>
      <UpdateDialogsContainer />
    </FormProvider>
  );
};

export default CommentReportPage;
