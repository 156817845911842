import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ProposalStatus from 'components/proposals/ProposalsStatus';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import ImageItem from 'components/image/ImageItem';
import { Proposal } from 'gql/graphql';
import moment from 'moment';
import UserProfile from 'components/users/UserProfile';

interface ProposalDetailsProps {
  proposal: Proposal;
}

const classes = {
  caption: {
    color: '#616161',
  },
};

const ProposalDetails = ({ proposal }: ProposalDetailsProps) => {
  const { id, status, creationDate, distance, description, pictures, assignedUser } = proposal;

  const applicationDateMoment = moment(creationDate).format('YYYY.MM.DD LTS');

  return (
    <Grid container spacing={4}>
      <Grid display="flex" alignItems="center" justifyContent="space-between" item xs={12}>
        <Typography variant="h6">
          <b>지원 ID: {id}</b>
        </Typography>
        <ProposalStatus size="large" status={status} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" sx={classes.caption}>
          지원 일자: {applicationDateMoment}
        </Typography>
        <Typography variant="subtitle2" sx={classes.caption}>
          급구 요청 장소 - 지원자 사업장 거리: {distance ?? '-'} km
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography mb={(theme) => theme.spacing(2)}>
          <b>지원자</b>
        </Typography>
        <UserProfile user={assignedUser} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography mb={(theme) => theme.spacing(2)}>
          <b>지원 내용</b>
        </Typography>
        <Typography sx={{ color: '#212121' }} mb={(theme) => theme.spacing(2)}>
          {description}
        </Typography>
        <Stack direction="row" spacing={2}>
          {pictures?.map((pic) => (
            <ImageItem size="large" key={pic.id} src={pic.url} alt={pic.pic_name} />
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ProposalDetails;
