import {
  AdminReportStatusEnum,
  CommentReportReasonEnum,
  CompanyRejectReason,
  CompanyStatus,
  ProjectReportReasonEnum,
  RequestReportReasonEnum,
  UserFilter,
  UserJoinType,
  UserLevel,
} from 'gql/graphql';
import { SxProps, Theme } from '@mui/material';

export type RenameByT<T, U> = {
  [K in keyof U as K extends keyof T ? (T[K] extends string ? T[K] : never) : K]: K extends keyof U ? U[K] : never;
};

export const flaggedRequestStatusManagementMap: { [key in AdminReportStatusEnum]: string } = {
  APPROVED: '게시글 중단',
  REJECTED: '게시글 유지',
  INACTIVE: '비활성화',
  IN_REVIEW: '검토중',
};

export const requestReportReasonMap: { [key in RequestReportReasonEnum]: string } = {
  PERSONAL_INFORMATION: '타인의 개인정보, 지식재산권, 명예, 신용 등 침해하는 내용 게시',
  THREATENING_CONTENT: '욕설, 폭언, 비방 등 타인에 불쾌하거나 위협이 되는 내용 게시\n',
  OBSCENE_CONTENT: '음란물 또는 청소년 유해 매체물, 범죄행위나 불법 내용 게시',
  CODE_CONTENT: '악성코드나 데이터를 포함하는 내용 게시',
  PROMOTING_FALSE_PRODUCT_SERVICE: '사기성 상품, 서비스, 사업 계획 등을 판촉하는 내용 게시',
  VIOLATION_DETAILS: '기타 관련법령 및 이용약관, 운영정책에 위배되는 내용 게시',
  OUT_OF_SUBJECT: '시공 현장에 필요한 자재, 인력, 장비 등에 대한 요청과 관련 없는 내용 게시',
};

export const projectReportReasonMap: { [key in ProjectReportReasonEnum]: string } = {
  CODE_CONTENT: '악성코드나 데이터를 포함하는 내용 게시',
  OBSCENE_CONTENT: '음란물 또는 청소년 유해 매체물, 범죄행위나 불법 내용 게시',
  PERSONAL_INFORMATION: '타인의 개인정보, 지식재산권, 명예, 신용 등 침해하는 내용 게시',
  PROMOTING_FALSE_PRODUCT_SERVICE: '사기성 상품, 서비스, 사업 계획 등을 판촉하는 내용 게시',
  THREATENING_CONTENT: '욕설, 폭언, 비방 등 타인에 불쾌하거나 위협이 되는 내용 게시',
  VIOLATION_DETAILS: '기타 관련법령 및 이용약관, 운영정책에 위배되는 내용 게시',
};

export const commentReportReasonMap: { [key in CommentReportReasonEnum]: string } = {
  CODE_CONTENT: '악성코드나 데이터를 포함하는 내용 게시',
  OBSCENE_CONTENT: '음란물 또는 청소년 유해 매체물, 범죄행위나 불법 내용 게시',
  PERSONAL_INFORMATION: '타인의 개인정보, 지식재산권, 명예, 신용 등 침해하는 내용 게시',
  PROMOTING_FALSE_PRODUCT_SERVICE: '사기성 상품, 서비스, 사업 계획 등을 판촉하는 내용 게시',
  THREATENING_CONTENT: '욕설, 폭언, 비방 등 타인에 불쾌하거나 위협이 되는 내용 게시',
  VIOLATION_DETAILS: '기타 관련법령 및 이용약관, 운영정책에 위배되는 내용 게시',
};

// User maps //
export enum UserProFilterEnum {
  All = 'ALL',
  PRO = 'PRO',
  NORMAL = 'NORMAL',
  ADMIN = 'ADMIN',
  VENDOR = 'VENDOR',
}

export const userProFilterMap: { [key in UserProFilterEnum]: string } = {
  ALL: '모든 사용자',
  NORMAL: '일반',
  PRO: 'PRO',
  ADMIN: '어드민',
  VENDOR: '밴더',
};

export const userProFilterValueMap: { [key in UserProFilterEnum]: UserFilter } = {
  ALL: {
    isLive: true,
    level: [UserLevel.Expert, UserLevel.Normal],
  },
  NORMAL: {
    isLive: true,
    isPro: false,
    level: [UserLevel.Expert, UserLevel.Normal],
  },
  PRO: {
    isLive: true,
    isPro: true,
    level: [UserLevel.Expert, UserLevel.Normal],
  },
  ADMIN: {
    isLive: true,
    level: [UserLevel.Super],
  },
  VENDOR: {
    isLive: true,
    level: [UserLevel.Vendor],
  },
};

export enum UserJoinTypeFilterEnum {
  APPLE = 'APPLE',
  KAKAO = 'KAKAO',
  MOBILE = 'MOBILE',
  ALL = 'ALL',
}

export const userJoinTypeFilterMap: { [key in UserJoinTypeFilterEnum]: string } = {
  KAKAO: '카카오',
  APPLE: '애플',
  MOBILE: '일반',
  ALL: '모든 가입경로',
};

export const userJoinTypeFilterValueMap: { [key in UserJoinTypeFilterEnum]: UserFilter } = {
  KAKAO: {
    joinType: UserJoinType.Kakao,
  },
  APPLE: {
    joinType: UserJoinType.Apple,
  },
  MOBILE: {
    joinType: UserJoinType.Mobile,
  },
  ALL: {},
};

// Company maps //
export enum CompanyStatusFilterEnum {
  InReview = 'IN_REVIEW',
  Rejected = 'REJECTED',
  Approved = 'APPROVED',
  Archived = 'ARCHIVED',
  All = 'ALL',
}

export const companyStatusMap: { [key in CompanyStatus]: string } = {
  APPROVED: '승인',
  ARCHIVED: '아카이브',
  REJECTED: '반려',
  IN_REVIEW: '신청 완료',
};

export const companyRejectReasonMap: { [key in CompanyRejectReason]: string } = {
  BUSINESS_CARD_ERROR: '명함 이미지 누락',
  BUSINESS_LICENSE_ERROR: '사업자등록증 이미지 누락',
  REGISTRATION_NUMBER_MISMATCH: '사업자등록증과 신청서에 입력한 사업자등록번호 불일치',
  NAME_MISMATCH: '명함, 사업자등록증, 신청서에 입력한 사업자명 불일치',
  BUSINESS_NOT_AEC_INDUSTRY: '건축/인테리어 전문 업종이 아님',
};

export const companyStatusFilterMap: { [key in CompanyStatusFilterEnum]: string } = {
  ALL: '모든 상태',
  IN_REVIEW: '신청 완료',
  REJECTED: '반려',
  APPROVED: '승인',
  ARCHIVED: '아카이브',
};

export const companyStatusFilterValueMap: { [key in CompanyStatusFilterEnum]: CompanyStatus[] | undefined } = {
  ALL: undefined,
  IN_REVIEW: [CompanyStatus.InReview],
  REJECTED: [CompanyStatus.Rejected],
  APPROVED: [CompanyStatus.Approved],
  ARCHIVED: [CompanyStatus.Archived],
};

// Project maps //

export enum ProjectsFilterEnum {
  Archived = 'ARCHIVED',
  Blocked = 'BLOCKED',
  Hidden = 'HIDDEN',
  Open = 'OPEN',
  All = 'ALL',
}

export const projectsFilterMap: { [key in ProjectsFilterEnum]: string } = {
  ALL: '모든 상태',
  BLOCKED: '중단',
  OPEN: '진행중',
  ARCHIVED: '아카이브',
  HIDDEN: '비공개',
};

// Request maps //

export enum RequestsFilterEnum {
  Archived = 'ARCHIVED',
  Blocked = 'BLOCKED',
  Close = 'CLOSE',
  Open = 'OPEN',
  All = 'ALL',
}

export const requestsFilterMap: { [key in RequestsFilterEnum]: string } = {
  ALL: '모든 상태',
  ARCHIVED: '아카이브',
  CLOSE: '마감',
  BLOCKED: '중단',
  OPEN: '진행중',
};

// Flagged request maps //

export const flaggedRequestFilterMap: { [key in AdminReportStatusEnum]: string } = {
  IN_REVIEW: '신고 접수',
  REJECTED: '게시글 유지',
  APPROVED: '게시글 중단',
  INACTIVE: '신고 보류',
};

// Proposal maps //
export enum ProposalsFilterEnum {
  Cancelled = 'CANCELLED',
  Sent = 'SENT',
  All = 'ALL',
}

export const proposalsFilterMap: { [key in ProposalsFilterEnum]: string } = {
  ALL: '모든 상태',
  SENT: '지원 완료',
  CANCELLED: '취소',
};

const classes = {
  reject: {
    color: 'warning.light',
    background: '#FBEAE6',
  },
  approve: {
    color: 'success.main',
    backgroundColor: '#E3FCEF',
  },
  wait: {
    color: (theme: Theme) => theme.palette.text.primary,
    background: '#00000014',
  },
};

export const adminReportColorMap: { [key in AdminReportStatusEnum]: SxProps } = {
  APPROVED: classes.reject,
  INACTIVE: classes.wait,
  REJECTED: classes.approve,
  IN_REVIEW: classes.wait,
};

export const proRequestColorMap: { [key in CompanyStatus]: SxProps } = {
  APPROVED: classes.approve,
  IN_REVIEW: classes.wait,
  REJECTED: classes.reject,
  ARCHIVED: classes.wait,
};
