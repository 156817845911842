import React from 'react';
import MainLayout from 'components/layouts/MainLayout';
import Container from 'components/container';
import { DefaultPageProps } from 'components/interfaces';
import Grid from '@mui/material/Grid';
import CompanyInfoImage from 'components/companies/[id]/CompanyInfoImage';
import CompanyProductsAndServices from 'components/companies/[id]/CompanyProductsAndServices';
import CompanyAddress from 'components/companies/[id]/CompanyAddress';
import { useQuery } from '@apollo/client';
import { graphql } from 'gql';
import { useParams } from 'react-router-dom';
import { Company } from 'gql/graphql';
import FormHelperText from '@mui/material/FormHelperText';
import LoadingBox from 'components/loading/LoadingBox';

export const QUERY_COMPANY = graphql(/* GraphQL */ `
  query CompanyDetail($id: Int) {
    company(id: $id) {
      name
      id
      logo {
        url
      }
      logoId
      description
      companyMembers {
        status
        userId
        roles {
          role
        }
      }
      companyInterests {
        id
        interestGroupId
        name
      }
      companyAddresses {
        id
        latitude
        address1
        address2
        name
        activityArea
        types {
          type
        }
        mobile
        zipCode
        jibunAddress
        longitude
      }
    }
  }
`);

const CompanyPage = ({ name }: DefaultPageProps) => {
  const params = useParams();
  const { data, loading, error } = useQuery<{ company: Company }>(QUERY_COMPANY, {
    variables: {
      id: Number(params.id),
    },
    skip: !Number(params.id),
  });

  return (
    <MainLayout title={name}>
      <Container title={name}>
        <Grid container spacing={2}>
          {error && (
            <Grid item xs={12}>
              <FormHelperText error>{error.message}</FormHelperText>
            </Grid>
          )}
          {loading && <LoadingBox />}
          {data && !error && !loading && (
            <>
              <Grid item xs={12}>
                <CompanyInfoImage company={data?.company} />
              </Grid>
              <Grid item xs={12}>
                <CompanyProductsAndServices company={data?.company} />
              </Grid>
              <Grid item xs={12}>
                <CompanyAddress address={data?.company?.companyAddresses[0]} />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </MainLayout>
  );
};

export default CompanyPage;
