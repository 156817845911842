import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Skeleton from '@mui/material/Skeleton';

interface LoadingTableRowProps {
  cellSpan: number;
  rowSpan?: number;
}

const LoadingTableRow = ({ cellSpan, rowSpan = 10 }: LoadingTableRowProps) => {
  return (
    <>
      {[...Array(rowSpan).keys()].map((row) => (
        <TableRow key={row}>
          {[...Array(cellSpan).keys()].map((cell) => (
            <TableCell key={cell} align="center">
              <Skeleton sx={{ height: 40 }} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default LoadingTableRow;
