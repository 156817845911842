import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {
  CompanyInterest,
  CompanyInterestGroup,
  MutationCreateCompanyInterestArgs,
  MutationUpdateCompanyInterestArgs,
  MutationUpdateCompanyInterestGroupArgs,
  QueryCompanyInterestGroupArgs,
} from 'gql/graphql';
import { useForm, Controller } from 'react-hook-form';
import { Autocomplete, Theme } from '@mui/material';
import { useRequestTagSettingsPopupContext } from 'components/request-tag-settings/dialogs/RequestTagSettingsContext';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { graphql } from 'gql';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import LoadingBox from 'components/loading/LoadingBox';

const classes = {
  stack: (theme: Theme) => ({
    columnGap: 2,
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      rowGap: 2,
    },
  }),
};

const COMPANY_INTEREST_GROUP_QUERY = graphql(/* GraphQL */ `
  query CompanyInterestGroup($id: Int!) {
    CompanyInterestGroup(id: $id) {
      interests {
        interestGroupId
        id
        name
      }
      id
      name
    }
  }
`);

const CREATE_COMPANY_INTEREST_MUTATION = graphql(/* GraphQL */ `
  mutation createCompanyInterest($name: String!, $companyInterestGroupId: Int!) {
    createCompanyInterest(name: $name, companyInterestGroupId: $companyInterestGroupId) {
      id
    }
  }
`);

const UPDATE_COMPANY_INTEREST_GROUP = graphql(/* GraphQL */ `
  mutation updateCompanyInterestGroup($id: Int!, $name: String!) {
    updateCompanyInterestGroup(id: $id, name: $name) {
      id
    }
  }
`);

const DELETE_COMPANY_INTEREST = graphql(/* GraphQL */ `
  mutation updateCompanyInterest($id: Int!, $name: String!) {
    updateCompanyInterest(id: $id, name: $name, companyInterestGroupId: null) {
      id
    }
  }
`);

const ModifyRequestTagSettingsPopup = () => {
  const { payload, setCurrentPopup } = useRequestTagSettingsPopupContext();
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading } = useQuery<{ CompanyInterestGroup: CompanyInterestGroup }, QueryCompanyInterestGroupArgs>(
    COMPANY_INTEREST_GROUP_QUERY,
    {
      variables: {
        id: payload.interestGroupId,
      },
    },
  );

  const [createCompanyInterest, { loading: loadingCreateInterest }] = useMutation(CREATE_COMPANY_INTEREST_MUTATION);

  const [updateCompanyInterestGroup, { loading: loadingUpdateInterestGroup }] =
    useMutation<MutationUpdateCompanyInterestGroupArgs>(UPDATE_COMPANY_INTEREST_GROUP);

  const [deleteCompanyInterest] = useMutation<
    { deleteCompanyInterest: CompanyInterest },
    MutationUpdateCompanyInterestArgs
  >(DELETE_COMPANY_INTEREST);

  const {
    control,
    handleSubmit,
    resetField,
    formState: { isDirty },
  } = useForm<MutationCreateCompanyInterestArgs>({
    defaultValues: {
      companyInterestGroupId: payload.interestGroupId,
      name: '',
    },
  });

  const {
    handleSubmit: handleSubmitUpdateCompanyInterest,
    control: controlUpdate,
    formState: { isDirty: isUpdateInterestDirty },
    resetField: resetUpdate,
  } = useForm<MutationUpdateCompanyInterestGroupArgs>({
    defaultValues: {
      id: payload.interestGroupId,
      name: data?.CompanyInterestGroup.name,
    },
  });

  useEffect(() => {
    if (data) {
      resetUpdate('name', { defaultValue: data?.CompanyInterestGroup.name });
    }
  }, [data, resetUpdate]);

  const submitUpdateInterestGroup = (form: MutationUpdateCompanyInterestGroupArgs) => {
    updateCompanyInterestGroup({
      variables: form,
      refetchQueries: [{ query: COMPANY_INTEREST_GROUP_QUERY, variables: { id: payload.interestGroupId } }],
    })
      .then(() => {
        enqueueSnackbar('그룹명이 변경되었습니다.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',

            horizontal: 'center',
          },
        });
      })
      .catch((reason: ApolloError) => {
        enqueueSnackbar(reason.message, { variant: 'error' });
      });
  };

  const submit = (form: MutationCreateCompanyInterestArgs) => {
    createCompanyInterest({
      variables: form,
      refetchQueries: [{ query: COMPANY_INTEREST_GROUP_QUERY, variables: { id: payload.interestGroupId } }],
    })
      .then(() => {
        resetField('name');
        enqueueSnackbar('수정내용이 저장되었습니다.', { variant: 'success' });
      })
      .catch((reason: ApolloError) => {
        enqueueSnackbar(reason.message, { variant: 'error' });
      });
  };

  const handleDeleteCompanyInterest = (id: number, name: string) => () => {
    deleteCompanyInterest({
      variables: {
        id,
        name,
      },
      refetchQueries: [{ query: COMPANY_INTEREST_GROUP_QUERY, variables: { id: payload.interestGroupId } }],
    })
      .then(() => {
        enqueueSnackbar('수정내용이 저장되었습니다.', { variant: 'success' });
      })
      .catch((reason: ApolloError) => {
        enqueueSnackbar(reason.message, { variant: 'error' });
      });
  };

  const handleClosePopup = () => {
    setCurrentPopup(undefined);
  };

  return (
    <Grid p={5} container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h6">
          <b>태그 및 그룹 수정</b>
        </Typography>
      </Grid>
      {loading && <LoadingBox />}
      {data && !loading && (
        <>
          <Grid item xs={12}>
            <Typography mb={1} variant="subtitle2">
              <b>그룹명</b>
            </Typography>
            <form onSubmit={handleSubmitUpdateCompanyInterest(submitUpdateInterestGroup)}>
              <Controller
                render={({ field }) => (
                  <Stack sx={classes.stack}>
                    <TextField fullWidth {...field} color="secondary" />
                    <LoadingButton
                      type="submit"
                      disabled={!isUpdateInterestDirty}
                      loading={loadingUpdateInterestGroup}
                      disableElevation
                      sx={{ minWidth: (theme) => theme.spacing(18) }}
                      size="large"
                      variant="contained"
                      color="inherit"
                    >
                      저장
                    </LoadingButton>
                  </Stack>
                )}
                name="name"
                control={controlUpdate}
              />
            </form>
          </Grid>
          <Grid item xs={12}>
            <Typography mb={1} variant="subtitle2">
              <b>태그 입력</b>
            </Typography>
            <Typography mb={2} variant="subtitle2">
              사용자가 급구 요청서 설정 태그 및 회사 관심 태그에서 선택할 기본 태그를 설정해 주세요.
            </Typography>
            <Autocomplete
              multiple
              value={data?.CompanyInterestGroup.interests}
              onChange={(event, value, situation, details) => {
                if (situation === 'removeOption') {
                  const { option }: { option: CompanyInterest } = details;
                  handleDeleteCompanyInterest(option.id, option.name)();
                }
              }}
              filterSelectedOptions
              color="primary"
              disableClearable
              options={data.CompanyInterestGroup.interests}
              renderTags={(values: CompanyInterest[], getTagProps) =>
                values.map((option: CompanyInterest, index: number) => (
                  <Chip
                    key={option.id}
                    color="secondary"
                    variant="outlined"
                    label={option.name}
                    {...getTagProps({ index })}
                  />
                ))
              }
              freeSolo
              renderInput={(params) => (
                <TextField sx={{ mb: (theme) => theme.spacing(2) }} color="secondary" {...params} variant="outlined" />
              )}
            />
            <form onSubmit={handleSubmit(submit)}>
              <Stack sx={classes.stack}>
                <Controller
                  render={({ field }) => <TextField {...field} fullWidth color="secondary" />}
                  name="name"
                  control={control}
                />
                <LoadingButton
                  disabled={!isDirty}
                  loading={loadingCreateInterest}
                  disableElevation
                  sx={{ minWidth: (theme) => theme.spacing(18) }}
                  size="large"
                  type="submit"
                  variant="contained"
                  color="inherit"
                >
                  저장
                </LoadingButton>
              </Stack>
            </form>
          </Grid>
        </>
      )}
      <Grid display="flex" justifyContent="center" item xs={12}>
        <Button onClick={handleClosePopup} color="secondary">
          닫기
        </Button>
      </Grid>
    </Grid>
  );
};

export default ModifyRequestTagSettingsPopup;
