import React, { PropsWithChildren, useContext, useState } from 'react';
import { BoxProps, Theme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationIcon from 'components/icons/NotificationIcon';
import MainDrawer from 'components/navbar/MainDrawer';
import Footer from 'components/footer';
import RefreshIcon from '@mui/icons-material/Refresh';
import { AuthContext } from 'context/authContext';
import { useNavigate } from 'react-router-dom';
import AccountDropDown from 'components/navbar/AccountDropDown';
import LogoIconText from 'components/icons/LogoIconText';
import LogoIcon from 'components/icons/LogoIcon';
import { ColorModeContext } from 'context/colorModeContext';
import HiddenButton from 'components/navbar/HiddenButton';

interface NavbarProps {
  withoutDrawer?: boolean;
  bottomController?: React.ReactNode;
  bottomControllerProps?: BoxProps;
}

const classes = {
  toolBar: (theme: Theme) => ({
    padding: '0 16px 0 4.8px',
    [theme.breakpoints.down('sm')]: {
      p: 0,
    },
  }),
  subTitle: {
    fontSize: '0.75rem',
    lineHeight: '8px',
    marginLeft: 4,
    alignSelf: 'flex-end',
    flexGrow: 1,
  },
  wrapperBox: (theme: Theme) => ({
    margin: theme.spacing(0, 4, 4),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 2, 2),
    },
  }),
  bottomNavigation: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    bottom: 0,
    background: theme.palette.background.paper,
    p: theme.spacing(2, 5),
    [theme.breakpoints.down('sm')]: {
      p: theme.spacing(1, 1),
    },
  }),
};

const Navbar = ({
  children,
  withoutDrawer,
  bottomController,
  bottomControllerProps,
}: PropsWithChildren<NavbarProps>) => {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { logout, tabValue, setTabValue, checkError } = useContext(AuthContext);

  const smMatch = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleLogOut = async () => {
    await logout();
    navigate('/login/signin', { replace: true });
  };

  const handleCheckError = async () => {
    await checkError()
      .then(() => {
        navigate(0);
      })
      .catch(() => handleLogOut());
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleDrawer = () => {
    setOpen((prevState: boolean) => !prevState);
  };
  const handleHoverDrawer = (cond: boolean) => () => {
    setOpen(cond);
  };
  const handleTabChange = (route: string) => () => {
    setTabValue(route);
    navigate(route);
  };

  const { mode } = useContext(ColorModeContext);

  return (
    <Box display="flex">
      <AppBar elevation={1} position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar variant="dense" disableGutters sx={classes.toolBar}>
          <IconButton size="large" onClick={handleDrawer}>
            <MenuIcon />
          </IconButton>
          <Box flexGrow={1} height="auto" display="flex" flexDirection="row" justifyContent="flex-end" maxHeight={30}>
            <Link display="flex" alignItems="center" boxSizing="initial" href="/">
              {smMatch ? <LogoIcon /> : <LogoIconText fill={mode === 'dark' && '#ffffff'} />}
            </Link>
            <Typography style={classes.subTitle}>
              <b>Admin</b>
            </Typography>
          </Box>
          <HiddenButton />
          <IconButton href="/notifications" size="small">
            <NotificationIcon sx={{ color: 'text.secondary' }} />
          </IconButton>
          <AccountDropDown handleLogOut={handleLogOut} />
          <IconButton size="small" onClick={handleCheckError}>
            <RefreshIcon sx={{ color: 'text.secondary' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box display="flex" alignItems="center" width="100%" marginTop={11}>
        <Box width="100%">
          {!withoutDrawer && (
            <MainDrawer
              tabValue={tabValue}
              setTabValue={setTabValue}
              handleTabChange={handleTabChange}
              open={open}
              handleHover={handleHoverDrawer}
              onClose={onClose}
            />
          )}
          <Box marginLeft={!withoutDrawer && !smMatch && 8}>
            <Box minHeight="calc(100vh - 173px)" flexGrow={1} sx={classes.wrapperBox}>
              {children}
            </Box>
            {bottomController && (
              <Box {...bottomControllerProps} sx={bottomControllerProps?.sx ?? classes.bottomNavigation}>
                {bottomController}
              </Box>
            )}
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;
