import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { authRoutes, mapRoutes, protectedRoutes } from 'components/app-router';
import { AuthContext } from 'context/authContext';
import { PacmanLoader } from 'react-spinners';
import Box from '@mui/material/Box';
import AuthVerify from 'components/app-router/AuthVerify';
import ProtectedRoute from 'components/routes/ProtectedRoute';
import AuthRoute from 'components/routes/AuthRoute';
import PageNotFound from 'pages/404';

const AppRouter = () => {
  const { isLoading } = useContext(AuthContext);

  if (isLoading) {
    return (
      <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
        <PacmanLoader color="#FF5555" />
      </Box>
    );
  }

  return (
    <Router>
      <AuthVerify />
      <Routes>
        {mapRoutes(protectedRoutes, ProtectedRoute)}
        {mapRoutes(authRoutes, AuthRoute)}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
