import React from 'react';
import CollapsiblePaper from 'components/paper/CollapsiblePaper';
import { Project, ProjectReport } from 'gql/graphql';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import DefaultTableHead from 'components/table/DefaultTableHead';
import { projectReportsHeadRows, TABLE_MIN_WIDTH } from 'utils/miscellaneous';
import { useSortParams } from 'hooks/useSortParams';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import ProjectReportTableRow from 'components/projects/[id]/ProjectReportTableRow';
import { useProjectPopupContext } from 'components/projects/[id]/dialogs/ProjectPopupContext';
import TableBody from '@mui/material/TableBody';
import DefaultTablePagination from 'components/table/DefaultTablePagination';

interface ProjectVotesCollapsibleListProps {
  project: Project;
  handlePageChange: (event: unknown, newPage: number) => void;
}

const ProjectReportsCollapsibleList = ({ project, handlePageChange }: ProjectVotesCollapsibleListProps) => {
  const { handleTableFieldChange, searchParams } = useSortParams();
  const { reportsPage } = searchParams;

  const { setCurrentPopup } = useProjectPopupContext();

  const handlePopup = (report: ProjectReport) => () => {
    setCurrentPopup('report', {
      report: report,
    });
  };

  return (
    <CollapsiblePaper header="신고 목록">
      <TableContainer sx={{ marginTop: 4 }} component={Paper} elevation={0}>
        <Table sx={{ minWidth: TABLE_MIN_WIDTH }}>
          <DefaultTableHead headRows={projectReportsHeadRows} handleTableFieldChange={handleTableFieldChange} />
          <TableBody>
            {project.reports.map((report) => (
              <ProjectReportTableRow onClick={handlePopup(report)} key={report.id} report={report} />
            ))}
          </TableBody>
          <TableFooter>
            <DefaultTablePagination
              onPageChange={handlePageChange}
              count={project?.reportTotalCount}
              page={Number(reportsPage ?? 0)}
              rowsPerPage={5}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </CollapsiblePaper>
  );
};

export default ProjectReportsCollapsibleList;
