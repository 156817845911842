import React from 'react';
import { Theme } from '@mui/material';
import { useFlaggedRequestPopupContext } from 'components/flagged-requests/dialogs/FlaggedRequestPopupContext';
import { CompanyMemberStatus } from 'gql/graphql';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AvatarIcon from 'components/icons/AvatarIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CustomRequestReport } from 'pages/flagged-requests/[id]';
import { CustomProjectReport } from 'pages/flagged-projects/[id]';
import { CustomCommentReport } from 'pages/comments/[id]';

type ReportType = CustomRequestReport | CustomProjectReport | CustomCommentReport;

interface ReportManagementItemProps<T> {
  isEditable: boolean;
  handleUpdateField: (isPositiveReport: boolean) => void;
  report: T;
  reportReason: string;
}

const classes = {
  icon: {
    borderRadius: '50%',
    width: 48,
    height: 48,
  },
  pro: {
    color: 'success.main',
  },
  caption: {
    color: '#616161',
    fontSize: '0.75rem',
  },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  listItem: {
    width: 'fit-content',
  },
  box: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      rowGap: 1,
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  }),
};

const ReportManagementItem = <T extends ReportType>({
  isEditable,
  report,
  handleUpdateField,
  reportReason,
}: ReportManagementItemProps<T>) => {
  const { id, user, creationDate, historyAtReport } = report;

  const { setCurrentPopup } = useFlaggedRequestPopupContext();
  const handlePopup = () => {
    setCurrentPopup('request_history', {
      id: historyAtReport?.id,
    });
  };

  const isPro = user?.activeCompanyMember?.status === CompanyMemberStatus.Approved;

  const creationDateMoment = moment(creationDate).format('YYYY.MM.DD LTS');

  const handleRadioGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleUpdateField(event.target.value === 'true');
  };
  return (
    <Grid container spacing={3}>
      <Grid sx={classes.box} item xs={12}>
        <Typography>
          <b>신고 ID: {id}</b>
        </Typography>
        <FormControl sx={classes.formControl} color="secondary">
          <Typography variant="subtitle2" mr={1}>
            <b>신고가 적합했나요?</b>
          </Typography>
          <RadioGroup value={report.isPositiveReport} row onChange={handleRadioGroup}>
            <FormControlLabel
              value={true}
              disabled={!isEditable}
              labelPlacement="end"
              control={<Radio size="small" color="secondary" />}
              label={<Typography variant="subtitle2">예</Typography>}
            />
            <FormControlLabel
              disabled={!isEditable}
              value={false}
              labelPlacement="end"
              control={<Radio size="small" color="secondary" />}
              label={<Typography variant="subtitle2">아니요</Typography>}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid sx={classes.box} item xs={12}>
        <ListItem disablePadding sx={classes.listItem}>
          <ListItemAvatar>
            {user?.profileImg?.url ? (
              <Avatar alt="profileImg" src={user?.profileImg.url} sx={classes.icon} />
            ) : (
              <AvatarIcon sx={classes.icon} />
            )}
          </ListItemAvatar>
          <ListItemText
            sx={{ ml: (theme) => theme.spacing(1) }}
            primaryTypographyProps={{ fontSize: '1.125rem' }}
            primary={user?.name}
            secondaryTypographyProps={{ ...classes.pro }}
            secondary={isPro && `PRO・@${user?.activeCompany?.name}`}
          />
        </ListItem>
        <Box>
          <Typography textAlign={{ xs: 'left', sm: 'right' }} mb={1} variant="subtitle2">
            {reportReason}
          </Typography>
          <Typography textAlign={{ xs: 'left', sm: 'right' }} sx={classes.caption}>
            신고 일자: {creationDateMoment}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <Button onClick={handlePopup} disabled={!historyAtReport?.id} sx={classes.pro}>
          신고 당시 급구 요청서 보기
        </Button>
      </Grid>
    </Grid>
  );
};

export default ReportManagementItem;
