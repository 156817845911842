import React from 'react';
import { DefaultPageProps } from 'components/interfaces';
import MainLayout from 'components/layouts/MainLayout';
import Container from 'components/container';
import { useQuery } from '@apollo/client';
import { requestsTableHeadRows } from 'utils/miscellaneous';
import { ListMetadata, OrderBy, QueryAllRequestsArgs, Request, RequestStatusEnum } from 'gql/graphql';
import RequestsTableRow from 'components/requests/RequestsTableRow';
import { useSortParams } from 'hooks/useSortParams';
import RequestsSearchBar from 'components/requests/RequestsSearchBar';
import DefaultTable from 'components/table/DefaultTable';
import { REQUESTS_QUERY } from 'components/requests/Queries';

const RequestListPage = ({ name }: DefaultPageProps) => {
  const { handleFieldChange, searchParams } = useSortParams();
  const { page, sortField, sortOrder, q, status } = searchParams;
  const result = useQuery<{ allRequests: Request[]; total: ListMetadata }, QueryAllRequestsArgs>(REQUESTS_QUERY, {
    variables: {
      page: Number(page ?? 0),
      perPage: 10,
      sortOrder: (sortOrder as OrderBy) ?? OrderBy.Desc,
      sortField: sortField ?? 'creationDate',
      filter: {
        q: q || undefined,
        status: status && status !== 'ALL' ? (status as RequestStatusEnum) : undefined,
      },
    },
    errorPolicy: 'all',
  });

  const { data, loading, error } = result;

  return (
    <MainLayout title={name}>
      <Container
        title={name}
        searchBar={
          <RequestsSearchBar
            searchQuery={q}
            handleSearch={handleFieldChange('q')}
            handleFilterChange={handleFieldChange('status')}
          />
        }
      >
        <DefaultTable result={result} rowDefinition={requestsTableHeadRows}>
          {data &&
            !error &&
            !loading &&
            data.allRequests.map((request) => {
              if (request === null) return;
              return <RequestsTableRow key={request.id} request={request} />;
            })}
        </DefaultTable>
      </Container>
    </MainLayout>
  );
};

export default RequestListPage;
