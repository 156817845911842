import React from 'react';
import StatusComponent, { StatusComponentProps } from 'components/status/StatusComponent';
import { RequestStatusEnum } from 'gql/graphql';

interface RequestStatusProps {
  status: RequestStatusEnum;
  size?: 'large' | 'medium';
}

export const requestStatusMap: { [key in RequestStatusEnum]: StatusComponentProps } = {
  OPEN: {
    text: '진행중',
    status: 'success',
  },
  BLOCKED: {
    text: '중단',
    status: 'warning',
  },
  CLOSE: {
    text: '마감',
    status: 'default',
  },
  ARCHIVED: {
    text: '아카이브',
    status: 'default',
  },
};

const RequestStatus = ({ status, size = 'medium' }: RequestStatusProps) => {
  return <StatusComponent size={size} {...requestStatusMap[status]} />;
};

export default RequestStatus;
