import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FileIcon = (props: SvgIconProps): JSX.Element => {
  const { fill = '#262627', width = 24, height = 24 } = props;
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      version="1.1"
      id="svg58"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M 15.292969,2.2929688 2.2929688,15.292969 A 1.0001,1.0001 0 0 0 2,16 v 5 a 1.0001,1.0001 0 0 0 1,1 H 8 A 1.0001,1.0001 0 0 0 8.7070313,21.707031 L 21.707031,8.7070313 a 1.0001,1.0001 0 0 0 0,-1.4140626 l -5,-4.9999999 a 1.0001,1.0001 0 0 0 -1.414062,0 z M 16,4.4140625 19.585937,8 7.5859375,20 H 4 v -3.585938 z" />
    </SvgIcon>
  );
};

export default FileIcon;
