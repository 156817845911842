import React, { PropsWithChildren, ReactNode, useMemo } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material';

interface ContainerProps {
  title: ReactNode | string;
  searchBar?: ReactNode;
}

const classes = {
  title: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '40px',
    color: (theme: Theme) => theme.palette.text.secondary,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: (theme: Theme) => theme.palette.background.default,
  },
};

const Container = ({ title, children, searchBar }: PropsWithChildren<ContainerProps>) => {
  const titleElement = useMemo(() => {
    if (typeof title === 'string') {
      return (
        <Box width="100%">
          <Typography sx={classes.title}>{title}</Typography>
        </Box>
      );
    }
    return title;
  }, [title]);

  return (
    <>
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center" width="100%" marginBottom={3}>
        {titleElement}
        {searchBar}
      </Box>
      <Paper elevation={0} sx={classes.paper}>
        {children}
      </Paper>
    </>
  );
};

export default Container;
