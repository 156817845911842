import { graphql } from 'gql';

export const PROPOSALS_QUERY = graphql(/* GraphQL */ `
  query allProposals($page: Int, $perPage: Int, $sortField: String, $sortOrder: OrderBy, $filter: ProposalFilter) {
    allProposals(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
      id
      assignedUser {
        name
      }
      description
      status
      distance
      creationDate
    }
    total: _allProposalsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`);
