import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CustomSelect } from 'components/select/CustomSelect';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CompanyRejectReason, CompanyStatus } from 'gql/graphql';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Theme } from '@mui/material';
import PaperWithTitle from 'components/paper/PaperWithTitle';
import { useFormContext, Controller } from 'react-hook-form';
import { companyRejectReasonMap, companyStatusMap, proRequestColorMap } from 'utils/commonMaps';

interface FormType {
  companyId: number;
  status: CompanyStatus;
  rejectReason?: CompanyRejectReason;
}

const classes = {
  typography: {
    minWidth: 'fit-content',
    mr: (theme: Theme) => theme.spacing(9),
  },
  box: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      rowGap: 1,
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  }),
};

const ProRequestStatusManagement = ({ status }: { status: CompanyStatus }) => {
  const { control, watch } = useFormContext<FormType>();
  const watchStatus = watch('status');

  return (
    <PaperWithTitle variant="outlined" elevation={0}>
      <Stack direction="column" spacing={4}>
        <Box sx={classes.box}>
          <Typography variant="h6">
            <b>PRO 회원 신청 상태</b>
          </Typography>
          <Controller
            render={({ field: { value, onChange } }) => (
              <CustomSelect
                autoWidth
                size="small"
                sx={proRequestColorMap[watchStatus]}
                IconComponent={KeyboardArrowDownIcon}
                value={value ?? status}
                onChange={onChange}
                name="status"
                renderValue={(selected) => `${companyStatusMap[selected as CompanyStatus]}`}
              >
                {Object.values(CompanyStatus).map((item) => {
                  if (item === CompanyStatus.Archived) {
                    return;
                  }
                  return (
                    <MenuItem key={item} value={item}>
                      <b>{companyStatusMap[item]}</b>
                    </MenuItem>
                  );
                })}
              </CustomSelect>
            )}
            name="status"
            control={control}
          />
        </Box>
        {watchStatus === CompanyStatus.Rejected && (
          <Box sx={classes.box}>
            <Typography sx={classes.typography}>
              <b>거절 사유</b>
            </Typography>
            <Controller
              render={({ field }) => (
                <Select
                  {...field}
                  fullWidth
                  name="rejectReason"
                  displayEmpty
                  renderValue={(s) => {
                    if (!s) return '선택';
                    return companyRejectReasonMap[s];
                  }}
                  color="secondary"
                  IconComponent={KeyboardArrowDownIcon}
                >
                  {Object.keys(CompanyRejectReason).map((item) => (
                    <MenuItem key={item} value={CompanyRejectReason[item as keyof typeof CompanyStatus]}>
                      {companyRejectReasonMap[CompanyRejectReason[item as keyof typeof CompanyStatus]]}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name="rejectReason"
              control={control}
            />
          </Box>
        )}
      </Stack>
    </PaperWithTitle>
  );
};

export default ProRequestStatusManagement;
