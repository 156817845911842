import { DragEndEvent, DragStartEvent, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { useCallback, useState } from 'react';
import type { UseFieldArrayMove } from 'react-hook-form';

export const useSortableArray = (move: UseFieldArrayMove) => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const [activeId, setActiveId] = useState<string | null>(null);
  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(String(event.active.id));
  }, []);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;
    const activeIdx = active?.data?.current?.sortable?.index;
    const overIdx = over?.data?.current?.sortable?.index;

    if (activeIdx !== overIdx) {
      move(activeIdx, overIdx);
      return;
    }
    setActiveId(null);
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  return { activeId, handleDragStart, handleDragEnd, handleDragCancel, sensors };
};
