import React from 'react';
import { Company, CompanyMemberRoleEnum, CompanyStatus } from 'gql/graphql';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import moment from 'moment';
import ProRequestStatus from 'components/pro-requests/ProRequestStatus';
import { useNavigate } from 'react-router-dom';

interface ProRequestsTableRowProps {
  company: Company;
}

const ProRequestsTableRow = ({ company }: ProRequestsTableRowProps) => {
  const navigate = useNavigate();

  const { id, status, companyMembers, name: companyName, creationDate, modificationDate } = company;
  const admin = companyMembers.find((member) =>
    member.roles.some((role) => role.role === CompanyMemberRoleEnum.Admin),
  ) ?? {
    user: { id: 0, name: '-', email: '-' },
  };

  const creationDateMoment = moment(creationDate);
  const modificationDateMoment = moment(modificationDate);

  const map: (string | string[])[] = [
    String(id),
    admin.user.name,
    admin.user.email ?? '-',
    status,
    companyName,
    [creationDateMoment.format('l'), creationDateMoment.format('LTS')],
    [modificationDateMoment.format('l'), modificationDateMoment.format('LTS')],
  ];

  const handleNavigate = () => {
    navigate(`/pro-requests/${company.id}/${admin.user.id}`);
  };

  return (
    <TableRow onClick={handleNavigate}>
      {map.map((item, idx) => {
        if (Object.values(CompanyStatus).includes(item as CompanyStatus)) {
          return (
            <TableCell align="center" key={idx}>
              <ProRequestStatus status={status} />
            </TableCell>
          );
        }
        if (typeof item === 'string') {
          return (
            <TableCell align="center" key={item}>
              {item}
            </TableCell>
          );
        }
        return (
          <TableCell align="center" key={idx}>
            {item.map((subItem) => (
              <div key={subItem}>{subItem}</div>
            ))}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default ProRequestsTableRow;
