import React from 'react';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material';

export type StatusType = 'warning' | 'default' | 'success';

export type ColorMap = Record<StatusType, SxProps>;

export const defaultColorMap: ColorMap = {
  warning: {
    color: 'warning.light',
    background: '#FBEAE6',
  },
  default: {
    color: 'text.secondary',
    background: (theme: Theme) => theme.palette.divider,
  },
  success: {
    color: '#006644',
    background: '#E3FCEF',
  },
};

export interface StatusComponentProps {
  text: string;
  status: StatusType;
  colorMap?: ColorMap;
  size?: 'large' | 'medium';
}

const classes = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  main: {
    borderRadius: (theme: Theme) => theme.spacing(1),
    maxWidth: 'fit-content',
  },
  medium: {
    fontSize: (theme: Theme) => theme.typography.pxToRem(12),
    p: (theme: Theme) => theme.spacing(0.5, 3),
  },
  large: {
    fontSize: (theme: Theme) => theme.typography.pxToRem(16),
    p: (theme: Theme) => theme.spacing(1, 3),
  },
};

const StatusComponent = ({ status, text, size = 'medium', colorMap = defaultColorMap }: StatusComponentProps) => {
  const sxClass = colorMap[status];
  return (
    <Box sx={classes.container}>
      <Box sx={[classes.main, ...(Array.isArray(sxClass) ? sxClass : [sxClass]), classes[size]]}>
        <b>{text}</b>
      </Box>
    </Box>
  );
};

export default StatusComponent;
