import { graphql } from 'gql';

export const USER_LIST_QUERY = graphql(/* GraphQL */ `
  query UserListQuery($page: Int, $perPage: Int, $sortField: String, $sortOrder: OrderBy, $filter: UserFilter) {
    items: allUsers(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
      id
      name
      email
      joinType
      level
      companyMember: activeCompanyMember {
        companyMemberId: id
        roles {
          role
        }
        status
      }
      company: activeCompany {
        companyId: id
        name
        creationDate
        status
        modificationDate
      }
      joinDate
      lastLoginDate
    }
    total: _allUsersMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`);
