import { graphql } from 'gql';

export const ADMIN_REPORTS_QUERY = graphql(/* GraphQL */ `
  query allAdminReports(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: OrderBy
    $filter: AdminReportFilter
  ) {
    allAdminReports(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
      ... on RequestAdminReport {
        id
        status
        request {
          id
          createdByUser {
            name
          }
          reportTotalCount
          title
        }
        lastReportedDate
      }
    }
    total: _allAdminReportsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`);
