import React from 'react';
import StatusComponent, { StatusComponentProps } from 'components/status/StatusComponent';

interface CompanyMemberRoleProps {
  isPro: boolean;
}

export enum UserPro {
  PRO = 'PRO',
  DEFAULT = 'DEFAULT',
}

export const userProStatusMap: { [key in UserPro]: StatusComponentProps } = {
  PRO: {
    text: 'PRO',
    status: 'success',
  },
  DEFAULT: {
    text: '일반',
    status: 'default',
  },
};

const CompanyMemberRole = ({ isPro }: CompanyMemberRoleProps) => {
  return <StatusComponent {...(isPro ? userProStatusMap.PRO : userProStatusMap.DEFAULT)} />;
};

export default CompanyMemberRole;
