import React from 'react';
import MainLayout from 'components/layouts/MainLayout';
import Container from 'components/container';
import { DefaultPageProps } from 'components/interfaces';
import { useParams } from 'react-router-dom';
import { graphql } from 'gql';
import { useQuery } from '@apollo/client';
import { Project, ProjectVotesArgs, QueryProjectArgs, SortBy } from 'gql/graphql';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import ProjectInfo from 'components/projects/[id]/ProjectInfo';
import { RenameByT } from 'utils/commonMaps';
import { useSortParams } from 'hooks/useSortParams';
import ProjectVotesCollapsibleList from 'components/projects/[id]/ProjectVotesCollapsibleList';
import ProjectReportsCollapsibleList from 'components/projects/[id]/ProjectReportsCollapsibleList';
import { PopupProvider } from 'components/popup/popupProvider';
import { projectPopupContext } from 'components/projects/[id]/dialogs/ProjectPopupContext';
import ProjectPopupContainer from 'components/projects/[id]/dialogs/ProjectPopupContainer';
import LoadingBox from 'components/loading/LoadingBox';

const QUERY_PROJECT = graphql(/* GraphQL */ `
  query project(
    $id: Int!
    $reportsSkip: Int
    $reportsSortBy: ProjectReportOrderByInput
    $reportsTake: Int
    $votesSkip: Int
    $votesSortBy: ProjectVoteOrderByInput
    $votesTake: Int
  ) {
    project(id: $id) {
      id
      reports(skip: $reportsSkip, take: $reportsTake, sortBy: $reportsSortBy) {
        id
        reportReason
        project {
          title
          description
        }
        isPositiveReport
        user {
          id
          name
        }
        creationDate
        modificationDate
      }
      voteCount
      reportTotalCount
      votes(skip: $votesSkip, take: $votesTake, sortBy: $votesSortBy) {
        id
        user {
          name
        }
        creationDate
      }
      title
      description
      pictures {
        id
        url
        pic_name
      }
      createdByUser {
        id
        name
        activeCompany(extendedCompanyStatus: true, extendedCompanyMemberStatus: true) {
          name
        }
        activeCompanyMember {
          status
        }
        profileImg {
          id
          url
          pic_name
        }
        profile {
          userId
        }
      }
    }
  }
`);

type ProjectVotesArgsCustom = RenameByT<
  { skip: 'votesSkip'; sortBy: 'votesSortBy'; take: 'votesTake' },
  ProjectVotesArgs
>;

type ProjectReportsArgsCustom = RenameByT<
  { skip: 'reportsSkip'; sortBy: 'reportsSortBy'; take: 'reportsTake' },
  ProjectVotesArgs
>;

const ProjectPage = ({ name }: DefaultPageProps) => {
  const params = useParams();
  const { searchParams, setSearchParams } = useSortParams();

  const { votesPage, reportsPage, sortField, sortOrder } = searchParams;
  const { data, loading, error } = useQuery<
    { project: Project },
    QueryProjectArgs & ProjectVotesArgsCustom & ProjectReportsArgsCustom
  >(QUERY_PROJECT, {
    variables: {
      id: Number(params.id),
      votesSkip: Number(votesPage ?? 0) * 5,
      votesTake: 5,
      ...(sortField === 'votesCreationDate' && {
        votesSortBy: {
          creationDate: sortOrder as SortBy,
        },
      }),
      ...(sortField === 'reportsCreationDate' && {
        reportsSortBy: {
          creationDate: sortOrder as SortBy,
        },
      }),
      reportsTake: 5,
      reportsSkip: Number(reportsPage ?? 0) * 5,
    },
    skip: !Number(params.id),
    errorPolicy: 'all',
  });

  const handlePageChange = (param: string) => (event: unknown, newPage: number) => {
    setSearchParams({ ...searchParams, [param]: String(newPage) });
  };

  return (
    <MainLayout title={name}>
      <Container title={name}>
        <Grid container spacing={2}>
          {error && (
            <Grid item xs={12}>
              <FormHelperText error>{error.message}</FormHelperText>
            </Grid>
          )}
          {loading && <LoadingBox />}
          {data && !error && !loading && (
            <PopupProvider context={projectPopupContext}>
              <Grid item xs={12}>
                <ProjectInfo project={data.project} />
              </Grid>
              <Grid item xs={12}>
                <ProjectVotesCollapsibleList project={data.project} handlePageChange={handlePageChange('votesPage')} />
              </Grid>
              <Grid item xs={12}>
                <ProjectReportsCollapsibleList
                  project={data.project}
                  handlePageChange={handlePageChange('reportsPage')}
                />
              </Grid>
              <ProjectPopupContainer />
            </PopupProvider>
          )}
        </Grid>
      </Container>
    </MainLayout>
  );
};

export default ProjectPage;
