import React from 'react';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface EmptyPlaceholderProps {
  icon?: React.ReactNode;
  primary: React.ReactNode;
  secondary?: React.ReactNode;
}

const classes = {
  primary: {
    marginTop: (theme: Theme) => theme.spacing(4),
  },
};

const EmptyPlaceHolder = (props: EmptyPlaceholderProps): JSX.Element => {
  const { icon, primary, secondary } = props;

  return (
    <Box display="flex" flexDirection="column" alignItems="center" paddingTop={5} paddingBottom={4}>
      {icon}
      <Typography align="center" sx={classes.primary}>
        <b>{primary}</b>
      </Typography>
      {secondary && (
        <Typography align="center" color="textSecondary">
          {secondary}
        </Typography>
      )}
    </Box>
  );
};

export default EmptyPlaceHolder;
