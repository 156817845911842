import React from 'react';
import Chip, { ChipProps } from '@mui/material/Chip';
import { useSortableItem } from 'components/sortable/hooks/useSortableItem';

interface SortableChipProps extends ChipProps {
  id: string;
}

const classes = {
  chip: {
    cursor: 'pointer',
  },
};

const SortableChip = (props: SortableChipProps) => {
  const { id, sx: sxChip, label, style: styleProp, ...rest } = props;
  const { setNodeRef, style, ...sortableItemRest } = useSortableItem(id);

  return (
    <Chip
      style={{ ...style, ...styleProp }}
      {...rest}
      {...sortableItemRest.attributes}
      {...sortableItemRest.listeners}
      ref={setNodeRef}
      sx={[...(Array.isArray(sxChip) ? sxChip : [sxChip]), classes.chip, style, styleProp]}
      variant="outlined"
      color="secondary"
      label={label}
    />
  );
};

export default SortableChip;
