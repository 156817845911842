import React from 'react';
import { Request, RequestStatusEnum } from 'gql/graphql';
import moment from 'moment/moment';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import RequestStatus from 'components/requests/RequestStatus';
import { useNavigate } from 'react-router-dom';

interface RequestsTableRowProps {
  request: Request;
}

const RequestsTableRow = ({ request }: RequestsTableRowProps) => {
  const navigate = useNavigate();

  const {
    id,
    createdByUser: { name },
    title,
    address: { jibunAddress },
    status,
    proposalTotalCount,
    creationDate,
    modificationDate,
  } = request;
  const creationDateMoment = moment(creationDate);
  const modificationDateMoment = moment(modificationDate);

  const handleRedirect = () => {
    navigate(`/requests/${id}`);
  };

  const map: (string | string[])[] = [
    String(id),
    name,
    title,
    jibunAddress,
    status,
    String(proposalTotalCount),
    [creationDateMoment.format('l'), creationDateMoment.format('LTS')],
    [modificationDateMoment.format('l'), modificationDateMoment.format('LTS')],
  ];

  return (
    <TableRow onClick={handleRedirect}>
      {map.map((item, idx) => {
        if (Object.values(RequestStatusEnum).includes(item as RequestStatusEnum)) {
          return (
            <TableCell align="center" key={idx}>
              <RequestStatus status={status} />
            </TableCell>
          );
        }
        if (typeof item === 'string') {
          return (
            <TableCell align="center" key={item}>
              {item}
            </TableCell>
          );
        }
        return (
          <TableCell align="center" key={idx}>
            {item.map((subItem) => (
              <div key={subItem}>{subItem}</div>
            ))}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default RequestsTableRow;
