import React from 'react';
import { Navigate, Route, RouteObject } from 'react-router-dom';
import NotificationsPage from 'pages/notifications';
import Login from 'pages/login';
import PrivacyPolicyPage from 'pages/settings/privacy-policy';
import TermsPage from 'pages/settings/terms';
import CompaniesPage from 'pages/companies';
import ProRequestsPage from 'pages/pro-requests';
import RequestListPage from 'pages/requests';
import FlaggedRequestsPage from 'pages/flagged-requests';
import ProposalsPage from 'pages/proposals';
import RequestTagSettingsPage from 'pages/request-tag-settings';
import CompanyPage from 'pages/companies/[id]';
import UserPage from 'pages/users/[id]';
import UsersPage from 'pages/users';
import ProRequestPage from 'pages/pro-requests/[id]';
import RequestPage from 'pages/requests/[id]';
import FlaggedRequestPage from 'pages/flagged-requests/[id]';
import ProposalPage from 'pages/proposals/[id]';
import { PopupProvider } from 'components/popup/popupProvider';
import { requestTagSettingsPopupContext } from 'components/request-tag-settings/dialogs/RequestTagSettingsContext';
import { projectTagSettingsPopupContext } from 'components/project-tag-settings/dialogs/ProjectTagSettingsContext';
import { updateDialogsPopupContext } from 'components/main-dialogs/context/UpdateDialogsPopupContext';
import FlaggedProjectsPage from 'pages/flagged-projects';
import ProjectTagSettingsPage from 'pages/project-tag-settings';
import FlaggedProjectPage from 'pages/flagged-projects/[id]';
import ProjectsPage from 'pages/projects';
import ProjectPage from 'pages/projects/[id]';
import CommentReportsPage from 'pages/comments';
import CommentReportPage from 'pages/comments/[id]';

export const mapRoutes = (routes: RouteObject[], Wrapper?: React.ComponentType) => {
  return routes.map((route) => {
    if (route?.children && route.children.length > 0) {
      return (
        <Route path={route.path} key={route.path} element={<Wrapper>{route.element}</Wrapper>}>
          {mapRoutes(route.children, Wrapper)}
        </Route>
      );
    } else {
      return <Route path={route.path} key={route.path} element={<Wrapper>{route.element}</Wrapper>} />;
    }
  });
};

export const authRoutes: RouteObject[] = [
  {
    path: '/login/signin',
    element: <Login name="관리자 로그인" />,
    children: [
      { path: ':password', element: <Login name="관리자 로그인" /> },
      { path: ':mobile', element: <Login name="관리자 로그인" /> },
      { path: ':challenge', element: <Login name="관리자 로그인" /> },
    ],
  },
  { path: '/login/signup/register', element: <Login name="관리자 로그인" /> },
  { path: '/login/pincode', element: <Login name="관리자 로그인" /> },
  { path: '/login/signup', element: <Login name="관리자 로그인" /> },
  { path: '/login/authorize', element: <Login name="관리자 로그인" /> },
];

export const protectedRoutes: RouteObject[] = [
  { path: '/', element: <Navigate to="/notifications" /> },
  { path: '/notifications', element: <NotificationsPage name="알림" /> },
  { path: '/users', element: <UsersPage name="사용자 목록" /> },
  { path: '/users/:id', element: <UserPage name="사용자 상세" /> },
  { path: '/companies', element: <CompaniesPage name="회사 목록" /> },
  { path: '/companies/:id', element: <CompanyPage name="회사 상세" /> },
  { path: '/pro-requests', element: <ProRequestsPage name="PRO 회원 요청" /> },
  {
    path: '/pro-requests/:id/:userId',
    element: (
      <PopupProvider context={updateDialogsPopupContext}>
        <ProRequestPage name="PRO 회원 요청 상세" />
      </PopupProvider>
    ),
  },
  { path: '/requests', element: <RequestListPage name="급구 목록" /> },
  { path: '/requests/:id', element: <RequestPage name="급구 요청 상세" /> },
  { path: '/flagged-requests', element: <FlaggedRequestsPage name="급구 신고 목록" /> },
  {
    path: '/flagged-requests/:id',
    element: (
      <PopupProvider context={updateDialogsPopupContext}>
        <FlaggedRequestPage name="급구 신고 상세" />
      </PopupProvider>
    ),
  },
  { path: '/proposals', element: <ProposalsPage name="급구 지원 목록" /> },
  { path: '/proposals/:id', element: <ProposalPage name="급구 지원 상세" /> },
  {
    path: '/request-tag-settings',
    element: (
      <PopupProvider context={requestTagSettingsPopupContext}>
        <RequestTagSettingsPage name="태그 및 그룹 설정" />
      </PopupProvider>
    ),
  },
  { path: '/privacy', element: <PrivacyPolicyPage name="개인정보 처리방침" /> },
  { path: '/terms', element: <TermsPage name="이용약관" /> },
  { path: '/flagged-projects', element: <FlaggedProjectsPage name="프로젝트 게시물 신고 목록" /> },
  {
    path: '/flagged-projects/:id',
    element: (
      <PopupProvider context={updateDialogsPopupContext}>
        <FlaggedProjectPage name="프로젝트 게시물 신고 상세" />
      </PopupProvider>
    ),
  },
  {
    path: '/project-tag-settings',
    element: (
      <PopupProvider context={projectTagSettingsPopupContext}>
        <ProjectTagSettingsPage name="태그 및 그룹 설정" />
      </PopupProvider>
    ),
  },
  {
    path: '/projects',
    element: <ProjectsPage name="프로젝트 목록" />,
  },
  {
    path: '/projects/:id',
    element: <ProjectPage name="프로젝트 게시물 상세" />,
  },
  {
    path: 'comments',
    element: <CommentReportsPage name="댓글 신고 목록" />,
  },
  {
    path: 'comments/:id',
    element: (
      <PopupProvider context={updateDialogsPopupContext}>
        <CommentReportPage name="댓글 신고 상세" />
      </PopupProvider>
    ),
  },
];
