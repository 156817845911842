import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const UsersIcon = (props: SvgIconProps): JSX.Element => {
  const { fill = '#262627', width = 24, height = 24 } = props;
  return (
    <SvgIcon fill={fill} width={width} height={height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m 5,14 c -2.7495889,0 -5,2.250453 -5,5 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 c 0,-1.668649 1.3313133,-3 3,-3 h 8 c 1.668637,0 3,1.331363 3,3 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 c 0,-2.749559 -2.250441,-5 -5,-5 z" />
      <path d="M 9,2 C 6.2504232,2 4,4.2504232 4,7 4,9.7495768 6.2504232,12 9,12 11.749547,12 14,9.7495889 14,7 14,4.2504111 11.749547,2 9,2 Z m 0,2 c 1.668649,0 3,1.3313133 3,3 0,1.6686867 -1.331351,3 -3,3 C 7.3313012,10 6,8.6686988 6,7 6,5.3313012 7.3313012,4 9,4 Z" />
      <path d="m 20.25,14.162109 a 1,1 0 0 0 -1.21875,0.71875 1,1 0 0 0 0.71875,1.216797 C 21.078564,16.440674 21.998946,17.627803 22,19 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 c -0.0017,-2.2736 -1.548568,-4.269509 -3.75,-4.837891 z" />
      <path d="m 16.248047,2.1621094 a 1,1 0 0 0 -1.216797,0.7207031 1,1 0 0 0 0.720703,1.2167969 c 1.332293,0.3411239 2.255859,1.5309572 2.255859,2.90625 0,1.3753052 -0.923539,2.5650967 -2.255859,2.90625 a 1,1 0 0 0 -0.720703,1.2167966 1,1 0 0 0 1.216797,0.720703 c 2.207477,-0.565245 3.759765,-2.5650385 3.759765,-4.8437496 0,-2.2787036 -1.552262,-4.2785349 -3.759765,-4.84375 z" />
    </SvgIcon>
  );
};

export default UsersIcon;
