import React from 'react';
import MainLayout from 'components/layouts/MainLayout';
import Container from 'components/container';
import { DefaultPageProps } from 'components/interfaces';
import Grid from '@mui/material/Grid';
import { DocumentNode, useQuery } from '@apollo/client';
import { graphql } from 'gql';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import LoadingButton from '@mui/lab/LoadingButton';
import { Theme } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { Company, CompanyRejectReason, CompanyStatus, User } from 'gql/graphql';
import ProRequestStatusManagement from 'components/pro-requests/[id]/ProRequestStatusManagement';
import ProRequestInformation from 'components/pro-requests/[id]/ProRequestInformation';
import UserInformation from 'components/users/[id]/UserInformation';
import UpgradeUserIcon from 'components/icons/UpgradeUserIcon';
import DeleteUserIcon from 'components/icons/DeleteUserIcon';
import FormHelperText from '@mui/material/FormHelperText';
import { usePrompt } from 'hooks/usePrompt';
import { useUpdateDialogsPopupContext } from 'components/main-dialogs/context/UpdateDialogsPopupContext';
import UpdateDialogsContainer from 'components/main-dialogs/containers/UpdateDialogsContainer';
import LoadingBox from 'components/loading/LoadingBox';

export const QUERY_COMPANY = graphql(/* GraphQL */ `
  query ProRequestUser($companyId: Int, $userId: Int!, $isUserId: Boolean! = true) {
    userById(id: $userId) @include(if: $isUserId) {
      id
      name
      companyName
      companyUrl
      email
      mobile
      joinType
      level
      activeCompany(extendedCompanyStatus: true, extendedCompanyMemberStatus: true) {
        status
        name
      }
      activeCompanyMember {
        status
      }
      profileImg {
        id
        blurhash
        url
      }
    }
    company(id: $companyId) {
      name
      id
      status
      logo {
        url
      }
      logoId
      registrationNumber
      description
      rejectReason
      companyMembers {
        status
        userId
        roles {
          role
        }
      }
      companyDocuments(types: [REGISTRATION_NUMBER_DOCUMENT]) {
        id
        picture {
          id
          url
          pic_name
        }
      }
      companyInterests {
        id
        interestGroupId
        name
      }
      companyAddresses {
        id
        latitude
        address1
        address2
        name
        activityArea
        types {
          type
        }
        mobile
        zipCode
        jibunAddress
        longitude
      }
    }
  }
`);

interface FormType {
  companyId: number;
  status: CompanyStatus;
  rejectReason?: CompanyRejectReason;
}

const classes = {
  submitButton: (theme: Theme) => ({
    width: '100%',
    maxWidth: theme.spacing(30),
    [theme.breakpoints.down('sm')]: {
      maxWidth: theme.spacing(15),
    },
  }),
};

interface ConfirmDetailsI {
  title: string;
  description: string;
  buttonName: string;
  Icon: React.ReactNode;
}

const returnConfirmDetails = (condition: boolean, name: string): ConfirmDetailsI => {
  if (condition) {
    return {
      title: 'PRO 회원 신청 반려',
      description: `${name}님의 PRO 회원 신청을 반려시키겠습니까?`,
      buttonName: 'PRO 회원 신청 반려',
      Icon: <DeleteUserIcon />,
    };
  }
  return {
    title: 'PRO 회원 승인',
    description: `${name}님의 PRO 회원 신청을 승인하시겠습니까?`,
    buttonName: 'PRO 회원으로 승인',
    Icon: <UpgradeUserIcon />,
  };
};

const ProRequestPage = ({ name }: DefaultPageProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const methods = useForm<FormType>();

  const {
    reset,
    getValues,
    formState: { isDirty, isSubmitted },
  } = methods;

  const { data, loading, error } = useQuery<
    { company: Company; userById: User },
    { userId: number; companyId: number; isUserId: boolean }
  >(QUERY_COMPANY as DocumentNode, {
    variables: {
      companyId: Number(params.id),
      userId: Number(params.userId),
      isUserId: Boolean(Number(params.userId)),
    },
    onCompleted(res) {
      reset({
        companyId: Number(params.id),
        status: res?.company?.status,
        rejectReason: res?.company?.rejectReason || undefined,
      });
    },
    errorPolicy: 'all',
    skip: !Number(params.id),
  });

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const { setCurrentPopup } = useUpdateDialogsPopupContext();

  const handleSetPopup = () => {
    setCurrentPopup('update', {
      maxWidth: 'xs',
      type: 'update_pro_request',
      variables: getValues(),
      ...returnConfirmDetails(getValues('status') === CompanyStatus.Rejected, data?.userById?.name ?? '삭제된 사용자'),
    });
  };

  usePrompt(
    '페이지를 이동하시겠습니까?\n페이지를 이동하면 변경사항이 저장되지 않을 수 있습니다.',
    isDirty && !isSubmitted,
  );

  return (
    <FormProvider {...methods}>
      <form>
        <MainLayout
          title={name}
          bottomController={
            <>
              <Button
                onClick={handleNavigateBack}
                color="secondary"
                startIcon={<ArrowBackIosNewIcon fontSize="small" />}
              >
                뒤로가기
              </Button>
              <LoadingButton
                disabled={!isDirty}
                onClick={handleSetPopup}
                sx={classes.submitButton}
                variant="contained"
                color="inherit"
              >
                보내기
              </LoadingButton>
            </>
          }
        >
          <Container title={name}>
            <Grid container spacing={2}>
              {error && (
                <Grid item xs={12}>
                  <FormHelperText error>{error.message}</FormHelperText>
                </Grid>
              )}
              {loading && <LoadingBox />}
              {data && !error && !loading && (
                <>
                  <Grid item xs={12}>
                    <ProRequestStatusManagement status={data?.company?.status} />
                  </Grid>
                  <Grid item xs={12}>
                    <ProRequestInformation company={data?.company as Company} />
                  </Grid>
                  {data?.userById && (
                    <Grid item xs={12}>
                      <UserInformation user={data.userById as User} />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Container>
        </MainLayout>
      </form>
      <UpdateDialogsContainer />
    </FormProvider>
  );
};

export default ProRequestPage;
