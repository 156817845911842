import React from 'react';
import { ProjectReport } from 'gql/graphql';
import moment from 'moment/moment';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ReportStatus from 'components/reports/ReportStatus';

interface ProjectReportTableProps {
  report: ProjectReport;
  onClick?: () => void;
}

const ProjectReportTableRow = ({ report, onClick }: ProjectReportTableProps) => {
  const { id, user, project, isPositiveReport, creationDate, modificationDate } = report;
  const creationDateMoment = moment(creationDate);
  const modificationDateMoment = moment(modificationDate);

  const map: (string | boolean | string[])[] = [
    String(id),
    user?.name ?? '-',
    project?.description,
    isPositiveReport || isPositiveReport === null,
    [creationDateMoment.format('l'), creationDateMoment.format('LTS')],
    [modificationDateMoment.format('l'), modificationDateMoment.format('LTS')],
  ];

  return (
    <TableRow onClick={onClick ? onClick : null}>
      {map.map((item, idx) => {
        if (typeof item === 'boolean') {
          return (
            <TableCell align="center" key={idx}>
              <ReportStatus status={item} />
            </TableCell>
          );
        }
        if (typeof item === 'string') {
          return (
            <TableCell align="center" key={item}>
              {item}
            </TableCell>
          );
        }
        return (
          <TableCell align="center" key={idx}>
            {item.map((subItem) => (
              <div key={subItem}>{subItem}</div>
            ))}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default ProjectReportTableRow;
