import React from 'react';
import PaperWithTitle from 'components/paper/PaperWithTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Company, CompanyInterest, CompanyInterestGroup } from 'gql/graphql';
import { graphql } from 'gql';
import { useQuery } from '@apollo/client';
import NestedGridWithChips from 'components/companies/[id]/NestedGridWithChips';
import { Theme } from '@mui/material';

const COMPANY_INTERESTS = graphql(/* GraphQL */ `
  query companyInterestGroupsIdName {
    companyInterestGroups {
      id
      name
    }
  }
`);

interface CompanyProductsAndServicesProps {
  company?: Company;
}

const classes = {
  paper: (theme: Theme) => ({
    p: 5,
    [theme.breakpoints.down('sm')]: {
      p: 3,
    },
  }),
  greenChip: {
    color: 'success.main',
    background: '#E4EAE3',
    border: 'none',
  },
};

const OTHER_TAGS_LABEL = '추가 제공 제품 및 서비스';
// accepts company interests array from company query, company interest groups with ids,
// and returns company interest groups, that can be mapped appropriately
const getCompanyInterestGroups = (
  companyInterests?: CompanyInterest[],
  companyInterestGroups?: CompanyInterestGroup[],
): CompanyInterestGroup[] => {
  const res: CompanyInterestGroup[] = [];
  companyInterests?.forEach((companyInterest) => {
    if (!companyInterest?.interestGroupId) {
      if (res.some((i) => i.name === OTHER_TAGS_LABEL)) {
        res.find((i) => i.name === OTHER_TAGS_LABEL)?.interests.push(companyInterest);
      } else {
        const otherInterestGroup: CompanyInterestGroup = {
          id: Number(9999999),
          name: OTHER_TAGS_LABEL,
          interests: [companyInterest],
          order: 0,
        };
        res.push(otherInterestGroup);
      }
    } else if (res.some((item) => item.id === companyInterest.interestGroupId)) {
      res.find((i) => i.id === companyInterest.interestGroupId)?.interests.push(companyInterest);
    } else {
      const interestGroup: CompanyInterestGroup = {
        id: Number(companyInterest.interestGroupId),
        name: companyInterestGroups?.find((i) => i.id === companyInterest.interestGroupId)?.name ?? '',
        interests: [companyInterest],
        order: 0,
      };
      res.push(interestGroup);
    }
  });
  return res;
};

const CompanyProductsAndServices = ({ company }: CompanyProductsAndServicesProps) => {
  const { data, loading: companyInterestsLoading } = useQuery<{ companyInterestGroups: CompanyInterestGroup[] }>(
    COMPANY_INTERESTS,
  );

  return (
    <PaperWithTitle variant="outlined" elevation={0} sx={classes.paper} header={null}>
      <Grid container spacing={3}>
        {!companyInterestsLoading && (
          <>
            <Grid display="flex" justifyContent="space-between" alignItems="center" item xs={12}>
              <Typography>
                <b>제공하는 제품 및 서비스</b>
              </Typography>
            </Grid>
            {company?.companyInterests && company?.companyInterests.length > 0 ? (
              <>
                {getCompanyInterestGroups(company?.companyInterests, data?.companyInterestGroups).map(
                  (companyInterestGroup) => (
                    <Grid key={companyInterestGroup.id} item xs={12}>
                      <NestedGridWithChips
                        sxChip={classes.greenChip}
                        title={companyInterestGroup.name}
                        items={companyInterestGroup.interests}
                      />
                    </Grid>
                  ),
                )}
              </>
            ) : (
              <Grid item xs={12}>
                <Typography variant="subtitle2">귀사에서 제공하는 제품 및 서비스를 등록해 주세요.</Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </PaperWithTitle>
  );
};

export default CompanyProductsAndServices;
