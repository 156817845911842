import React, { useState } from 'react';
import MainLayout from 'components/layouts/MainLayout';
import Container from 'components/container';
import PaperWithTitle from 'components/paper/PaperWithTitle';
import EmptyPlaceHolder from 'components/notifications/EmptyPlaceHolder';
import { DefaultPageProps } from 'components/interfaces';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { CustomSwitch } from 'components/notifications/CustomSwitch';
import { useQuery } from '@apollo/client';
import { QUERY_ME } from 'components/notifications/Queries';
import { Theme } from '@mui/material';
import LoadingBox from 'components/loading/LoadingBox';

const classes = {
  paper: {
    '& > *': {
      borderBottom: (theme: Theme) => `1px solid ${theme.palette.divider}`,
      '&:last-child': {
        border: 'none',
      },
    },
  },
  responsiveBox: (theme: Theme) => ({
    p: theme.spacing(2.5, 3),
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      columnGap: 1,
    },
  }),
};

const NotificationsPage = ({ name }: DefaultPageProps) => {
  const [showOnlyUnread, setShowOnlyUnread] = useState(false);

  const { data, loading } = useQuery(QUERY_ME);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowOnlyUnread(event.target.checked);
  };

  return (
    <MainLayout title={name}>
      <Container title={name}>
        <PaperWithTitle
          variant="outlined"
          disableGutters
          header={
            <Box sx={classes.responsiveBox}>
              {loading && <LoadingBox />}
              {data && !loading && (
                <>
                  <Typography variant="subtitle1">
                    <b>{data.me.name}의 소식</b>
                  </Typography>
                  <FormControlLabel
                    control={<CustomSwitch checked={showOnlyUnread} onChange={handleChange} name="showUnreadOnly" />}
                    label={<b>읽지 않은 항목만 표시</b>}
                    labelPlacement="start"
                  />
                </>
              )}
            </Box>
          }
          sx={classes.paper}
        >
          <Box paddingX={1} paddingY={1.5}>
            <Button color="secondary">모두 읽음으로 표시</Button>
          </Box>
          <EmptyPlaceHolder
            primary="최근 소식이 없습니다."
            secondary="검색을 통해 자재를 찾아보고 클립해보세요!"
            icon={<NotificationsIcon fontSize="large" />}
          />
        </PaperWithTitle>
      </Container>
    </MainLayout>
  );
};

export default NotificationsPage;
