import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ProjectAdminReport } from 'gql/graphql';
import moment from 'moment';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

interface FlaggedProjectsTableRowProps {
  report: ProjectAdminReport;
}

const FlaggedProjectsTableRow = ({ report }: FlaggedProjectsTableRowProps) => {
  const navigate = useNavigate();

  const { id, project, lastReportedDate } = report;

  const lastReportedDateMoment = moment(lastReportedDate);

  const map: (string | string[])[] = [
    String(id),
    project?.createdByUser?.name ?? '-',
    project?.title ?? '-',
    String(project?.reportTotalCount),
    [lastReportedDateMoment.format('l'), lastReportedDateMoment.format('LTS')],
  ];

  const handleNavigate = () => {
    navigate(`/flagged-projects/${id}`);
  };

  return (
    <TableRow onClick={handleNavigate}>
      {map.map((item, idx) => {
        if (Array.isArray(item)) {
          return (
            <TableCell align="center" key={idx}>
              {item.map((subItem) => (
                <div key={subItem}>{subItem}</div>
              ))}
            </TableCell>
          );
        }
        return (
          <TableCell align="center" key={idx}>
            {item}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default FlaggedProjectsTableRow;
